import React from 'react';
import { GrDocumentText } from 'react-icons/gr';
import { ImStatsDots } from 'react-icons/im';
import { RenderTiles } from '../../components/common';
import { useQuery } from '../../hooks/UseQuery';
import { useParams } from 'react-router-dom';
import ChildHeader from '../../components/ui/ChildHeader';
import PartDetailsHeader from '../../components/ui/PartDeatailsHeader';

const TilesPpap = () => {
  const { partId } = useParams();
  const partName = useQuery('part_name');
  const partNumber = useQuery('part_number');
  const ppapTilesObj = [
    {
      sNo: 1,
      link: `/admin/revisionTable/controlplan/${partId}?part_name=${partName}&part_number=${partNumber}`,
      isLink: true,
      clauseNo: 4,
      p: 'CONTROL',
      span: 'PLAN',
      isIcon: true,
      icon: <GrDocumentText size="5rem" color="#c80202" />,
    },
    {
      sNo: 2,
      link: `/admin/spc/${partId}?part_name=${partName}&part_number=${partNumber}`,
      isLink: true,
      clauseNo: 4,
      p: 'SPC',
      span: 'REPORTS',
      isIcon: true,
      icon: <ImStatsDots size="5rem" />,
    },
  ];
  return (
    <>
      <ChildHeader text="DOCUMENT LIST" />
      <PartDetailsHeader
        partName={partName || ''}
        partNumber={partNumber || ''}
      />
      <RenderTiles
        tilesObj={ppapTilesObj}
        height="100vw"
        justify="flex-start"
        columns="0fr"
      />
      ;
    </>
  );
};

export default TilesPpap;
