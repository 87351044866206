import axios from 'axios';
import { useEffect, useState } from 'react';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import { StyledTableHeaderRow } from '../../../../../components/ui/tableFreeze/RowFreeze.styled';

interface IProps {
  moduleData: any;
  isView: boolean;
  setUseEffectRender: any;
}

const head1 = [
  'S.NO.',
  'DATE',
  'SHIFT',
  'SHIFT START TIME',
  'SHIFT  END TIME',
  'SCHEDULED DOWNTIME',
  'UNSCHEDULED DOWNTIME',
  'PROD. ORDER NO.',
  'ITEM NO.',
  'TOOLS',
  'OPERATOR',
  'STROKE/MINUTES',
  'WORKING MINUTES',
  'TOTAL RUN TIME (IN MINUTES)',
  'PRODUCED QUANTITY WITH CHIPS (IN KGS)',
  'OK QTY WITHOUT CHIPS',
  'CHIPS QTY',
];
const head2 = [
  'TOTAL (QTY)',
  'OK QTY WITHOUT CHIPS',
  'CHIPS QTY',
  'REJ.QTY',
  'WIRE',
  'RIVET',
];
const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '100px',
};

const THead = ({ moduleData, isView, setUseEffectRender }: IProps) => {
  const [render, setRender] = useState(false);
  const [machines, setMachines] = useState([]);
  useEffect(() => {
    axios
      .get('/api/machineRoutes/machine/ForAutocomplete')
      .then((res) => {
        setMachines(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <thead>
      <TableCompHead
        colSpan={26}
        rowSpan={4}
        labels={['DOC NO.', 'ISSUE DATE', 'REVISION NO.', 'REVISION DATE']}
        values={['F PRD 15', '01/12/2019', '03', '1/12/22']}
        heading="DAILY PRODUCTION REPORT - RIVET BODY"
      />
      <tr>
        <th style={{ minWidth: '100px' }}>M/C NO.: </th>
        <th colSpan={5}>
          <AutocompleteMuiCustom
            id="Machine"
            label=""
            option_name="machine_no"
            arrayofObj={machines || []}
            value={moduleData?.machine_id || ''}
            onChange={(e, value) => {
              moduleData.machine_id = value;
              setUseEffectRender((prev: any) => !prev);
            }}
          />
        </th>
        <th colSpan={5}>SHIFT SCHEDULED: </th>
        <th colSpan={5} align="left"></th>
        <th colSpan={5}>FORCAST:</th>
        <th colSpan={5}></th>
      </tr>
      <StyledTableHeaderRow>
        {head1.map((heading, index) => (
          <th key={heading + index} rowSpan={2} style={{ ...style1 }}>
            {heading}
          </th>
        ))}
        <th colSpan={4} style={{ ...style1 }}>
          SUSPECTED QTY
        </th>
        <th colSpan={2} style={{ ...style1 }}>
          SCRAP (KGS)
        </th>
        <th rowSpan={2} style={{ ...style1 }}>
          DOWN TIME REASONS (IN MINUTES){' '}
        </th>
        <th rowSpan={2} colSpan={2} style={{ ...style1 }}>
          REMARKS
        </th>
      </StyledTableHeaderRow>
      <StyledTableHeaderRow top="4.5rem">
        {head2.map((heading, index) => (
          <th key={heading + index} style={{ ...style1 }}>
            {heading}
          </th>
        ))}
      </StyledTableHeaderRow>
    </thead>
  );
};

export default THead;
