import React, { useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { usePermission } from '../../utils/helpers/usePermission';
import { AddButton } from '../../components/common/button/CustomButtons';
import ChildHeader from '../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../components/common/FilterBy';
import { SearchBar } from '../../components/common/SearchBar';
import { TableCustom } from '../../components/common';
import useDebounce from '../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
} from '../../components/common/button/CustomIconButton';

import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';
import { IData } from './interface';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import formatDate from '../../components/common/formatDate';
const tableHeaderObj = [
  [
    { text: 'S.No.', rowspan: 1, colspan: 1 },
    { text: 'Process Name', rowspan: 1, colspan: 1 },
    { text: 'Specification', rowspan: 1, colspan: 1 },
    { text: 'Cp', rowspan: 1, colspan: 1 },
    { text: 'Cpk', rowspan: 1, colspan: 1 },
    { text: 'Created At', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [{ value: 'createdAt', name: 'A-Z' }];

const List = () => {
  const permissions = usePermission(14);
  const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [customerId, setCustomerId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
  const search = useQuery('search');
  const filter = useQuery('filter');
  const page = useQuery('page') || 1;
  const { partId } = useParams();
  const partName = useQuery('part_name');
  const partNumber = useQuery('part_number');

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE SUPPLIER',
    'Are you sure you want to delete this?'
  );

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/spcRoute/byPartId/${partId}?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteList = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/spcRoute/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, isModal]);

  return (
    <>
      <ChildHeader text="STATISTICAL PROCESS CONTROL STUDY LIST">
        {permissions.includes('edit') && (
          <AddButton
            onClick={() =>
              navigate(
                `/common/createEditSpc/${partId}?part_name=${partName}&part_number=${partNumber}`
              )
            }
            label="New Spc"
          />
        )}
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <FilterBy options={filterOptions} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: IData, index: number) => (
              <React.Fragment key={item?._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>

                  <TableCell align="center">{item?.process_name}</TableCell>
                  <TableCell align="center">{item?.specification}</TableCell>
                  <TableCell align="center">{item?.cp}</TableCell>
                  <TableCell align="center">{item?.cpk}</TableCell>
                  <TableCell align="center">
                    {formatDate(item?.createdAt || '')}
                  </TableCell>

                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      {permissions.includes('edit') && (
                        <EditIconButton
                          tooltipTitle="EDIT"
                          onClick={() => {
                            navigate(
                              `/common/createEditSpc/${partId}/${item?._id}?part_name=${partName}&part_number=${partNumber}`
                            );
                          }}
                        />
                      )}

                      {permissions.includes('delete') && (
                        <DeleteIconButton
                          tooltipTitle="DELETE"
                          onClick={() => {
                            deleteList(item?._id || '');
                          }}
                        />
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>

      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
