import React, { useEffect, useState } from 'react';
import TableCustom from '../../components/common/customTable/TableCustom';
import {
  Stack,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import { SearchBar } from '../../components/common/SearchBar';
import FilterBy from '../../components/common/FilterBy';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import ChildHeader from '../../components/ui/ChildHeader';
import axios from 'axios';
import AddEditPart, { IPart } from '../addparts/AddEditPart';
import useDebounce from '../../hooks/useDebounce';
import { useAuth } from '../../services/auth/AuthProvider';
import formatDate from '../../components/common/formatDate';
import {
  EditIconButton,
  ViewIconButton,
  DeleteIconButton,
} from '../../components/common/button/CustomIconButton';
import ModalCustom from '../../components/common/Modal';
import { useQuery } from '../../hooks/UseQuery';
import { useNavigate } from 'react-router-dom';
import { CustomPagination } from '../../components/common';
import ConfirmDialogueForInput from '../../components/common/ConfirmDialogueForInput';
const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Item Number', rowspan: 1, colspan: 1 },
    { text: 'Item Description', rowspan: 1, colspan: 1 },
    { text: 'Customer', rowspan: 1, colspan: 1 },
    { text: 'Category', rowspan: 1, colspan: 1 },
    { text: 'Created At', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterCategoryOptions = [
  { value: 'CASTING', name: 'CASTING' },
  { value: 'MACHINING', name: 'MACHINING' },
  { value: 'PRESS SHOP', name: 'PRESS SHOP' },
  { value: 'ROTOR', name: 'ROTOR' },
  { value: 'STACKING', name: 'STACKING' },
];
const ViewAdminPpapDoc = () => {
  const { state } = useAuth();
  const navigate = useNavigate();
  const [data, setData] = useState<IPart[]>([]);
  const [isNewPartModal, setIsNewPartModal] = useState(false);
  const [render, setRender] = useState(false);
  const [dropDownRender, setDropDownRender] = useState(false);
  const [duplicateOpenModal, setDuplicateOpenModal] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [partId, setPartId] = useState<string | null>('');
  const [itemPartNumber, setItemPartNumber] = useState<string | null>(null);
  const [confirmOpenForDelete, setConfirmOpenForDelete] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState<string | null>(null);

  const [filterCustomer, setFilterCustomer] = useState([]);

  const search = useQuery('search');
  const category = useQuery('category');
  const customer = useQuery('customer');
  const page = useQuery('page') || 1;

  useEffect(() => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        if (res.data) {
          const formattedData = res.data.map((customer: any) => ({
            name: customer.customer_name,
            value: customer.customer_name,
          }));
          setFilterCustomer(formattedData);
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const fetchData = async () => {
    const res: any = await axios
      .get(
        `/api/addPartRoute/viewPpapPart/publish?page=${page}&customer=${customer}&category=${category}&search=${search}&perPage=${10}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res.data?.result);
    setPerPage(res.data?.perPage);
    setTotalPages(res.data?.pages);
  };

  useDebounce(fetchData, 1000, [
    page,
    search,
    render,
    customer,
    category,
    duplicateOpenModal,
    isNewPartModal,
  ]);
  const deletePart = async (id: string) => {
    await axios.delete(`/api/addPartRoute/${id}`);
    setRender((prev) => !prev);
  };

  const handleDialogWithInput = () => {
    if (!partId) return;
    if (itemPartNumber === textFieldValue) {
      deletePart(partId);
      setPartId(null);
      setTextFieldValue(null);
      setConfirmOpenForDelete(false);
    }
  };

  return (
    <div>
      <ChildHeader text="PART LIST"></ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search Part" />
        <Stack direction={'row'} gap={2} flex="1">
          <FilterBy
            sx={{ width: 130 }}
            name="customer"
            label="Filter By Customer"
            options={filterCustomer}
          />
          <FilterBy
            sx={{ width: 130 }}
            name="category"
            label="Filter By Category"
            options={filterCategoryOptions}
          />
        </Stack>
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: IPart, index: number) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell align="center" width={10}>
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item?.part_number}</TableCell>
                  <TableCell align="center">{item?.part_name}</TableCell>
                  <TableCell align="center">
                    {item?.customer_id &&
                      typeof item?.customer_id !== 'string' &&
                      item.customer_id.customer_name}
                  </TableCell>
                  <TableCell align="center">
                    {item?.supplier_id &&
                      typeof item?.supplier_id !== 'string' &&
                      item.category}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(item?.createdAt || '')}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setPartId(item?._id!);
                          setIsNewPartModal(true);
                        }}
                      />
                      <ViewIconButton
                        tooltipTitle="VIEW"
                        onClick={() => {
                          navigate(
                            `/admin/ppapTiles/${item?._id}?part_name=${item.part_name}&part_number=${item.part_number}`
                          );
                        }}
                      />
                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          // deletePart(item?._id!);
                          setItemPartNumber(item?.part_number);
                          setPartId(item?._id!);
                          setConfirmOpenForDelete(true);
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ConfirmDialogueForInput
        title="DELETE PART"
        open={confirmOpenForDelete}
        setOpen={setConfirmOpenForDelete}
        onConfirm={handleDialogWithInput}
        isSubmitContent={false}
      >
        <p style={{ margin: 0, fontSize: '1.6rem' }}>
          Are you sure you want to delete this part?
        </p>
        <p style={{ margin: 0, fontSize: '1.6rem' }}>
          Enter the <b>Part Number :</b> "{`${itemPartNumber}`}" to confirm part
          deletion:
        </p>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          autoComplete="false"
          label="Item Number"
          type="text"
          fullWidth
          variant="standard"
          onChange={(e) => setTextFieldValue(e.target.value)}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
        />
      </ConfirmDialogueForInput>
      <ModalCustom
        openModal={isNewPartModal}
        closeModal={() => {
          setPartId(null);
          setIsNewPartModal(false);
        }}
        title="UPDATE PART"
      >
        <AddEditPart
          setIsNewPartModal={setIsNewPartModal}
          id={partId}
          setPartId={setPartId}
        />
      </ModalCustom>
    </div>
  );
};

export default ViewAdminPpapDoc;
