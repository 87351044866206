import { Box } from '@mui/system';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CustomButton,
  SubmitButton,
} from '../../components/common/button/CustomButtons';
import useConfirm from '../../components/common/useConfirm';
import ChildHeader from '../../components/ui/ChildHeader';
import { useQuery } from '../../hooks/UseQuery';
import TController from './components/TController';
import lodash from 'lodash';
import PickPart from '../../components/ui/PickPart';
import ModalCustom from '../../components/common/Modal';
import { processes } from './helpers/initialState';
const Create = () => {
  const navigate = useNavigate();
  const [moduleData, setModuleData] = useState<any>({
    processes: Array.from({ length: 5 }, () => ({ ...processes[0] })),
  });
  const isView = useQuery('isView') === 'true' ? true : false;
  const annual_month = useQuery('annual_date') || '';
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [partIdx, setPartIdx] = useState<number>(0);
  const [useEffectRender, setUseEffectRender] = useState(false);
  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Row',
    'Are you sure you want to Add Row?'
  );
  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );
  const [render, setRender] = useState(false);
  useEffect(() => {
    axios
      .get(`/api/supplierComplaintRoute/${annual_month}`, moduleData)
      .then((res) => {
        if (res.data) {
          setModuleData(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, [useEffectRender]);

  const onSubmit = async () => {
    return new Promise(async (resolve: any, reject: any) => {
      if (moduleData._id) {
        await axios
          .put(`/api/supplierComplaintRoute/${moduleData._id}`, moduleData)
          .then((res) => {
            if (res.data) {
              alert('updated successfully ');
              setUseEffectRender((prev) => !prev);
              resolve();
            }
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      } else {
        if (!annual_month) {
          return alert('Please pick annual year?');
        }
        moduleData.annual_month = annual_month;
        await axios
          .post(`/api/supplierComplaintRoute`, moduleData)
          .then((res) => {
            alert('created successfully');
            setUseEffectRender((prev) => !prev);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      }
    });
  };
  const handelDeleteRows = async (rowIdx: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    data.processes.splice(rowIdx, 1);
    setModuleData(data);
  };

  return (
    <Box>
      <Box>
        <ChildHeader text="Supplier complaint REPORT">
          {!isView && (
            <>
              <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                }}
                onClick={async () => {
                  const ans = await confirmAddRow();
                  if (!ans) return;
                  Array(1)
                    .fill(null)
                    .forEach(() => {
                      moduleData.processes.push(lodash.cloneDeep(processes[0]));
                    });
                  setRender((prev) => !prev);
                }}
              >
                ADD ROWS
              </CustomButton>
              <SubmitButton
                label={moduleData._id ? `UPDATE` : `SUBMIT`}
                onClick={() => onSubmit()}
              />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          handelDeleteRows,
          isView,
          setIsOpenPopup,
          setPartIdx,
          onSubmit,
        }}
      />
      <DialogAddRow />
      <DialogDeleteRow />
      <ModalCustom
        title="Part List"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <PickPart moduleData={moduleData.processes[partIdx]} />
      </ModalCustom>
    </Box>
  );
};

export default Create;
