import { Box, Stack } from '@mui/system';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Avatar, Button, Input, styled } from '@mui/material';
import { apiConstant } from '../helpers/contant';
import { Loader } from '../../../components/common/Loader';

const Profile = ({ id, setIsProfileModal }: any) => {
  const [profilePic, setProfilePic] = useState('/static/images/avatar/1.jpg');
  const [file, setFile] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!id) return;
    axios
      .get(`/api/${apiConstant.getbyId}/${id}`)
      .then((res) => {
        setProfilePic(res.data.profile);
        // setIsModal(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSumbit = async () => {
    if (id) {
      setIsLoading(true);
      let formData = new FormData();
      formData.append('profile', file);
      await axios
        .put(`/api/${apiConstant.update}/${id}`, formData)
        .then((res) => {
          if (res.data) {
            setIsProfileModal(false);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  const handleUpload = (event: any) => {
    const file = event.target.files[0];
    const reader: any = new FileReader();
    reader.onloadend = () => {
      setProfilePic(reader.result);
    };
    reader.readAsDataURL(file);
    setFile(file);
  };

  return (
    <>
      <Loader loader={isLoading} />
      <Stack
        direction="column"
        gap={2}
        sx={{ alignItems: 'center', justifyContent: 'center', width: '400px' }}
      >
        <Avatar
          alt="Profile Picture"
          src={profilePic}
          sx={{ width: '400px', height: 'auto', borderRadius: 0 }}
        />
        <label htmlFor="upload-button">
          <input
            style={{ display: 'none' }}
            accept="image/jpeg, image/png, image/gif, image/bmp, image/webp"
            id="upload-button"
            type="file"
            onChange={handleUpload}
          />
          <Button variant="contained" component="span" onClick={() => {}}>
            Upload
          </Button>
        </label>
      </Stack>
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
    </>
  );
};

export default Profile;
