import { IconButton, MenuItem, Stack, Tooltip } from '@mui/material';
import React from 'react';
import { FaFilePdf } from 'react-icons/fa6';
import {
  ViewIconButton,
  EditIconButton,
  DeleteIconButton,
  CustomIconButton,
} from '../../components/common/button/CustomIconButton';
import CustomUploadIcon from '../../components/common/button/CustomUploadIcon';
import formatDate from '../../components/common/formatDate';
import { StyledTableRow, StyledTableCell } from './helpers/module.styled';
import CustomSelect from '../../components/common/SelectField';
import { CgProfile } from 'react-icons/cg';
import { usePermission } from '../../utils/helpers/usePermission';
const isDisableFormDownload = (item: any) => {
  if (
    item.profile &&
    item.unique_id &&
    item.candidate_name &&
    item.date &&
    item.applied_for_post &&
    item.mobile_no &&
    item.email_id
  )
    return false;

  return true;
};

const ListRows = ({
  item,
  index,
  handleDownloadDocs,
  setModuleId,
  setIsModal,
  handleDelete,
  getColorForStatus,
  handleStatusUpdate,
  uploadImageFile,
  viewFile,
  setIsProfileModal,
}: any) => {
  const permissions = usePermission(31);
  return (
    <React.Fragment key={item._id}>
      <StyledTableRow index={index}>
        <StyledTableCell align="center">{item.unique_id}</StyledTableCell>
        <StyledTableCell align="center">
          {formatDate(item.date)}
        </StyledTableCell>
        <StyledTableCell align="center">
          {formatDate(item?.plan_date_interview)}
        </StyledTableCell>
        <StyledTableCell align="center">
          {formatDate(item?.actual_date_interview)}
        </StyledTableCell>
        <StyledTableCell align="center">{item?.candidate_name}</StyledTableCell>
        <StyledTableCell align="center">{item?.father_name}</StyledTableCell>
        <StyledTableCell align="center">
          {item?.applied_for_post}
        </StyledTableCell>

        <StyledTableCell align="center">{item?.mobile_no}</StyledTableCell>
        <StyledTableCell align="center">{item?.email_id}</StyledTableCell>
        <StyledTableCell align="center">
          {formatDate(item?.dob)}
        </StyledTableCell>
        <StyledTableCell align="center">
          <CustomSelect
            label=""
            name="status"
            value={item.status}
            sx={{ backgroundColor: getColorForStatus(item.status) }}
            disabled={!item.interview_form.length || !item.resume.length}
            onChange={(e: any) => {
              item.status = e.target.value;
              handleStatusUpdate(item?._id, item);
            }}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Selected">Selected</MenuItem>
            <MenuItem value="Rejected">Rejected</MenuItem>
            <MenuItem value="Shortlisted">Shortlisted</MenuItem>
            <MenuItem value="No Show">No Show</MenuItem>
          </CustomSelect>
        </StyledTableCell>
        <StyledTableCell align="center">
          <Stack
            direction="row"
            spacing={0.5}
            sx={{ justifyContent: 'center' }}
          >
            <Tooltip title="Download Pdf">
              <IconButton
                color={'primary'}
                size={'small'}
                disabled={isDisableFormDownload(item)}
                onClick={() => {
                  handleDownloadDocs(
                    item?.unique_id,
                    item?.candidate_name,
                    formatDate(item?.date),
                    item?.applied_for_post,
                    item?.mobile_no,
                    item?.email_id,
                    formatDate(item?.dob),
                    item?.profile,
                  );
                }}
              >
                <FaFilePdf />
              </IconButton>
            </Tooltip>
          </Stack>
        </StyledTableCell>
        <StyledTableCell align="center">
          <Stack direction="row" sx={{ alignItems: 'center' }}>
            <CustomUploadIcon
              accept="image/png,image/jpeg,image/jpg,application/pdf"
              id={`${index}_files_interview`}
              multiple={true}
              onChange={(e: any) => {
                uploadImageFile(e, item._id, 'interview_form');
              }}
            />
            {item.interview_form?.length > 0 && (
              <>
                <ViewIconButton
                  sx={{ m: 0 }}
                  tooltipTitle="view"
                  onClick={() => {
                    viewFile(item._id, 'interview_form');
                  }}
                />
              </>
            )}
          </Stack>
        </StyledTableCell>
        <StyledTableCell align="center">
          <Stack direction="row" sx={{ alignItems: 'center' }}>
            <CustomUploadIcon
              accept="image/png,image/jpeg,image/jpg,application/pdf"
              id={`${index}_files_resume`}
              multiple={true}
              onChange={(e: any) => {
                uploadImageFile(e, item._id, 'resume');
              }}
            />
            {item.resume?.length > 0 && (
              <>
                <ViewIconButton
                  sx={{ m: 0 }}
                  tooltipTitle="view"
                  onClick={() => {
                    viewFile(item._id, 'resume');
                  }}
                />
              </>
            )}
          </Stack>
        </StyledTableCell>
        <StyledTableCell>
          <Stack
            direction="row"
            sx={{ justifyContent: 'center', alignItems: 'center' }}
          >
            <CustomIconButton
              sx={{ p: 0, m: 1 }}
              tooltipTitle="VIEW PROFILE"
              onClick={() => {
                setIsProfileModal(true);
                setModuleId(item._id);
              }}
            >
              <CgProfile
                style={{
                  color: item.profile ? 'green' : '#343a40',
                  fontSize: '2rem',
                }}
              />
            </CustomIconButton>
            {permissions.includes('edit') && (
              <EditIconButton
                tooltipTitle="EDIT"
                onClick={() => {
                  setModuleId(item?._id || '');
                  setIsModal(true);
                }}
              />
            )}
            {permissions.includes('delete') && (
              <DeleteIconButton
                tooltipTitle="DELETE"
                onClick={() => {
                  handleDelete(item?._id || '');
                }}
              />
            )}
          </Stack>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
};

export default ListRows;
