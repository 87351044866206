import { HiDocumentReport } from 'react-icons/hi';
import { CgCalendarToday } from 'react-icons/cg';
import { RenderTiles } from '../../components/common';
import {
  MdOutlineCalendarMonth,
  MdLocationOn,
  MdSupportAgent,
} from 'react-icons/md';
import { GrVmMaintenance } from 'react-icons/gr';
import { BsReverseListColumnsReverse } from 'react-icons/bs';

import { ImListNumbered, ImStatsDots } from 'react-icons/im';
import { TbRulerMeasure } from 'react-icons/tb';

import { GiBeamsAura, GiDoorHandle } from 'react-icons/gi';
import { SiInstructure } from 'react-icons/si';
import { FcOrgUnit } from 'react-icons/fc';
import { FaList } from 'react-icons/fa';
import Groups3Icon from '@mui/icons-material/Groups3';

const adminTilesObj = [
  {
    sNo: 5,
    link: '/admin/clause5',
    isLink: true,
    clauseNo: 5,
    p: 'EDUCATION AND',
    span: 'TRAINING',
    isIcon: false,
  },
  {
    sNo: 1,
    link: '/common/annualPmPlanUtilityTiles',
    isLink: true,
    icon: <MdOutlineCalendarMonth size="5rem" color="#343a40" />,
    p: 'ANNUAL PM ',
    span: 'PLAN',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/spcPlanlist',
    isLink: true,
    icon: <MdOutlineCalendarMonth size="5rem" color="#343a40" />,
    p: 'SPC',
    span: 'PLAN',
    isIcon: true,
  },

  {
    sNo: 2,
    link: '/common/spcPlanChecklist',
    isLink: true,
    icon: <BsReverseListColumnsReverse size="5rem" color="#087f5b" />,
    p: 'SPC',
    span: 'STATUS',
    isIcon: true,
  },

  // spc different routing for iai
  {
    sNo: 9,
    link: '/common/spc/partlist',
    isLink: true,
    icon: <ImStatsDots size="5rem" color="#003566" />,
    p: 'SPC',
    span: 'REPORT',
    isIcon: true,
  },

  {
    sNo: 2,
    link: '/common/abnormalityHandling',
    isLink: true,
    icon: <GiDoorHandle size="5rem" color="#087f5b" />,
    p: 'ABNORMALITY HANDLING',
    span: 'REPORT',

    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/productionAndMaintenaceHome',
    isLink: true,
    icon: <GrVmMaintenance size="5rem" color="#087f5b" />,
    p: 'Production & Maintenance',
    span: 'Department',

    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/instrumentMasterlist',
    isLink: true,
    icon: <SiInstructure size="5rem" color="#343a40" />,
    p: 'Master list of',
    span: 'Instrument',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/obsoleteInstrumentMasterlist',
    isLink: true,
    icon: <SiInstructure size="5rem" color="#343a40" />,
    p: 'Master list of',
    span: 'Obsolete Instrument',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/tilesMsaplan',
    isLink: true,
    icon: <GiBeamsAura size="5rem" color="#0b7285" />,
    p: 'MSA',
    span: 'PLAN',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/tilesCallibrationplan',
    isLink: true,
    icon: <FcOrgUnit size="5rem" color="#343a40" />,
    p: 'CALLIBRATION',
    span: 'PLAN',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/masterListLocationList',
    isLink: true,
    icon: <MdLocationOn size="5rem" color="#343a40" />,
    p: 'MANAGE',
    span: 'LOCATION',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/masterListApprovedExternalProvederList',
    isLink: true,
    icon: <FaList size="5rem" color="#343a40" />,
    p: 'APPROVED EXTERNAL',
    span: 'PROVIDERS',
    isIcon: true,
  },
  {
    sNo: 9,
    link: `/common/mushinAquaMastersTiles`,
    isLink: true,
    icon: <ImListNumbered size="5rem" color="#1864ab" />,
    p: 'MUSHIN AQUA',
    span: 'MASTERS',
    isIcon: true,
  },
  {
    sNo: 9,
    link: `/common/mushinHelpDesk`,
    isLink: true,
    icon: <MdSupportAgent size="5rem" color="#1864ab" />,
    p: 'MUSHIN HELP',
    span: 'DESK',
    isIcon: true,
  },
];

const AdminHome = () => {
  return (
    <RenderTiles
      tilesObj={process.env.NODE_ENV === 'development' ? adminTilesObj : []}
    />
  );
};

export default AdminHome;
