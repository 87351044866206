import { Box, Divider, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Loader } from '../../components/common/Loader';
import {
  CustomButton,
  SaveButton,
  CloseButton,
  SubmitButton,
} from '../../components/common/button/CustomButtons';
import ChildHeader from '../../components/ui/ChildHeader';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';
import PartDetailsHeader from '../../components/ui/PartDeatailsHeader';
import AutocompleteMuiCustom from '../../components/common/AutocompleteMuiCustom';
import axios from 'axios';
import { IData } from './interface';
import InputMuiCustom from '../../components/common/inputs/InputMuiCustom';
import AlertMui from '../../components/common/AlertMui';
import moment from 'moment';
import DatePickerMui from '../../components/common/DatePickerMui';
import { RiFileExcel2Line } from 'react-icons/ri';
import formatDate from '../../components/common/formatDate';
import TController from './components/create/TController';

const CreateEdit = () => {
  const [loader, setLoader] = useState<boolean>(false);
  let { partId, id } = useParams();
  const partName = useQuery('part_name');
  const partNumber = useQuery('part_number');
  const navigate = useNavigate();

  const [DialogClose, confirmClose] = useConfirm(
    'CLOSE',
    'Are you sure you want to leave this page?'
  );
  const [spcData, setSpcData] = useState<IData>({
    part_id: '',
    process_id: '',
    supplier: '',
    spec_id: '',
    customer: '',
    specification: '',
    control_product: '',
    process_name: '',
    instrument: '',
    l_count: '',
    no_of_dec: null,
    spc_array: Array.from({ length: 150 }, () => null),
    date: '',
    utolerance: '',
    ltolerance: '',
    cp: '',
    cpk: '',
  });
  const [process, setProcess] = useState([]);
  const [render, setRender] = useState(false);
  const [spec, setSpec] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertforSubmmit, setAlertforSubmmit] = useState(false);
  const [autoCompleteProcess, setAutoCompleteProcess] = useState<any>('');
  const [autoCompleteSpec, setAutoCompleteSpec] = useState<any>('');
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update SPC' : 'Create SPC'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get(`/api/spcRoute/controlplan/${partId}`);
        spcData.customer = res.data.customer;
        spcData.supplier = res.data.supplier;
        setProcess(res.data.processes);

        //
        if (id) {
          const dataspc: any = await axios.get(`/api/spcRoute/${id}`);
          setSpcData(dataspc.data);
          // console.log(spcData,'eeeeeee')
          let getProcess = res.data.processes.filter((item: any) => {
            return item._id === dataspc.data.process_id;
          });
          getProcess = getProcess[0];
          const getSpec = getProcess?.specs.filter((item: any) => {
            return item._id === dataspc.data.spec_id;
          });
          setAutoCompleteProcess(getProcess);
          setSpec(getProcess.specs);
          setAutoCompleteSpec(getSpec[0]);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, [id]);
  // console.log(spcData)
  // console.log(process,'eeeeeee')

  // useEffect(()=>{
  //   console.log('xxx')

  // },[spcData]);

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;

    if (
      !autoCompleteSpec?.charecteristics ||
      !autoCompleteProcess?.process_name
    ) {
      setAlert(true);
      return;
    }

    spcData.specification = autoCompleteSpec?.charecteristics;
    spcData.control_product = autoCompleteSpec?.control_product;
    spcData.process_name = autoCompleteProcess?.process_name;
    spcData.part_id = partId || null;

    if (id) {
      await axios
        .put(`/api/spcRoute/${id}`, spcData)
        .then((res) => {
          window.alert('succesfully submitted');
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/spcRoute', spcData)
        .then((res) => {
          navigate(
            `/common/createEditSpc/${partId}/${res.data.id}?part_name=${partName}&part_number=${partNumber}`
          );
          window.alert('succesfully submitted');
        })
        .catch((err) => console.log(err));
    }
  };

  const viewSpcSheet = async () => {
    if (
      !autoCompleteSpec?.charecteristics ||
      !autoCompleteProcess?.process_name
    ) {
      setAlert(true);
      return;
    }

    if (!id) {
      window.alert('please submitt the document first');
      return;
    }
    setAlertforSubmmit(true);
    setLoader(true);
    axios
      .post(`/api/spcRoute/viewSheet/${id}`, {
        part_name: partName,
        part_no: partNumber,
        spec: autoCompleteSpec?.charecteristics,
        control_product: autoCompleteSpec?.control_product,
        operation: autoCompleteProcess?.process_name,
      })
      .then((res) => {
        if (res.status === 200)
          window.open(
            'https://docs.google.com/spreadsheets/d/1JD7nLzcHlMx491YRdyUycKUEJKxLxpNukvzNC_RWZzw/edit?usp=sharing ',
            '_blank'
          );
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  function excelDownload() {
    // console.log('dfdgdfgh');
    axios
      .post(
        `/api/editExcelRoute/SPC`,
        {
          details: {
            data: spcData,

            partName: partName,
            partNumber: partNumber,
            Date: formatDate(spcData?.date),
          },
        },
        { responseType: 'blob' }
      )
      .then((response) => {
        const blob = new Blob([response.data]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `SPC ${partName} ${partNumber} ${
            spcData?.process_name
          } ${moment().format('dddd, MMMM Do YYYY, h:mm:ss a')}.xlsx`
        );
        // document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const cal = () => {
    const spec = spcData?.control_product;
    if (spec === null) return;
    const inputString = spec;

    // Define a regular expression to match numbers with or without decimal points
    const regex = /-?\d+(\.\d+)?/g;

    // Use the regular expression to extract numbers from the string
    const numbers = inputString.match(regex);
    if (numbers === null) return;
    // Convert the extracted numbers to numeric values
    const numericValues = numbers.map(parseFloat);
    if (numericValues.length !== 2) return;

    if (spec?.includes('±')) {
      const temp = numericValues[1];
      spcData.utolerance = temp;
      spcData.ltolerance = temp;
    } else if (spec?.includes('+')) {
      const temp = numericValues[1];

      spcData.utolerance = temp;
    } else if (spec?.includes('-')) {
      const temp = numericValues[1];

      spcData.ltolerance = temp;
    } else if (spec?.includes('~')) {
      const mean = (numericValues[1] + numericValues[0]) / 2;
      const ut = numericValues[1] - mean;
      const lt = mean - numericValues[0];
      spcData.utolerance = ut;
      spcData.ltolerance = lt;
    }
  };

  return (
    <Box>
      <Loader loader={loader} />
      <Box>
        <ChildHeader text="STATISTICAL PROCESS CONTROL STUDY">
          <CustomButton
            color="primary"
            sx={{
              margin: '10px',
              backgroundColor: '#f8f9fa',
              color: '#343a40',
            }}
            icon={<RiFileExcel2Line />}
            disabled={false}
            size="large"
            onClick={() => excelDownload()}
          >
            Excel Download
          </CustomButton>
          {/* <CustomButton
            sx={{
              margin: '10px',
              backgroundColor: '#f8f9fa',
              color: '#343a40',
            }}
            icon={<VisibilityIcon sx={{ color: '#343a40' }} />}
            disabled={false}
            size="large"
            onClick={() => viewSpcSheet()}
          >
            View SPC
          </CustomButton> */}
          <SubmitButton
            label="Submit"
            onClick={() => {
              handleSumbit();
            }}
          />
          <CloseButton
            label="Close"
            onClick={async () => {
              const ans = await confirmClose();
              if (!ans) return;
              navigate(-1);
            }}
          />
        </ChildHeader>
        <PartDetailsHeader
          partName={partName || ''}
          partNumber={partNumber || ''}
        />

        <Box sx={{ flexGrow: 1, width: '98%', margin: '2rem auto' }}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <DatePickerMui
                label="Date"
                value={moment(spcData.date || null)}
                onChange={(date) => {
                  spcData.date = date;
                  setRender((prev) => !prev);
                }}
              />
            </Grid>

            <Grid item xs={2}>
              <InputMuiCustom
                type="string"
                name="part_no"
                variant={'outlined'}
                placeholder={'Part No.'}
                value={partNumber || ''}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={2}>
              <InputMuiCustom
                type="string"
                name="part_name"
                variant={'outlined'}
                placeholder={'Part Name'}
                value={partName || ''}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <AutocompleteMuiCustom
                id={'process_name'}
                label="OPERATION/PROCESS NAME"
                option_name={'process_name'}
                value={autoCompleteProcess}
                arrayofObj={process}
                onChange={(e, value) => {
                  value?.process_name
                    ? (spcData.process_id = value._id)
                    : (spcData.process_id = '');
                  setAutoCompleteProcess(value);
                  value?.specs ? setSpec(value.specs) : setSpec([]);
                  setAutoCompleteSpec('');
                  setRender((prev) => !prev);
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <AutocompleteMuiCustom
                id={'charecteristics'}
                label={'SPECIFIC. / PARAMETER'}
                option_name={'charecteristics'}
                value={autoCompleteSpec}
                arrayofObj={spec}
                onChange={(e, value) => {
                  value?._id
                    ? (spcData.spec_id = value._id)
                    : (spcData.spec_id = '');
                  setAutoCompleteSpec(value);
                  spcData.instrument = value?.instrument;
                  spcData.utolerance = value?.utolerance;
                  spcData.ltolerance = value?.ltolerance;
                  spcData.control_product = value?.control_product;
                  if (value !== null) {
                    cal();
                  }
                  setRender((prev) => !prev);

                  // console.log(value)
                }}
              />
            </Grid>

            {/* <Grid item xs={3}>
              <InputMuiCustom
                type="string"
                name="part_name"
                variant={'outlined'}
                placeholder={'Tolerance'}
                value={autoCompleteSpec?.control_product}
                disabled={false}
              />
            </Grid> */}
          </Grid>

          <Grid container spacing={2} sx={{ mt: 0.2 }}>
            <Grid item xs={3}>
              <InputMuiCustom
                variant={'outlined'}
                type="text"
                name="instrument"
                placeholder={'Instrument'}
                value={spcData.instrument}
                onChange={(e) => {
                  spcData.instrument = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <InputMuiCustom
                variant={'outlined'}
                type="text"
                name="control_product"
                placeholder={`SPECIFICATION`}
                value={spcData.control_product}
                onChange={(e) => {
                  spcData.control_product = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <InputMuiCustom
                variant={'outlined'}
                type="text"
                name="utolerance"
                placeholder={`Upper Tolerance(+)`}
                value={spcData.utolerance}
                onChange={(e) => {
                  spcData.utolerance = e.target.value;
                  setRender((prev) => !prev);
                }}
                // InputProps={{
                //   readOnly: true,
                // }}
              />
            </Grid>

            <Grid item xs={3}>
              <InputMuiCustom
                variant={'outlined'}
                type="text"
                name="ltolerance"
                placeholder={`Lower Tolerance(-)`}
                value={spcData.ltolerance}
                onChange={(e) => {
                  spcData.ltolerance = e.target.value;
                  setRender((prev) => !prev);
                }}
                // InputProps={{
                //   readOnly: true,
                // }}
              />
            </Grid>

            <Grid item xs={3}>
              <InputMuiCustom
                variant={'outlined'}
                type="text"
                name="cp"
                placeholder={`Cp`}
                onChange={(e) => {
                  spcData.cp = e.target.value;
                  setRender((prev) => !prev);
                }}
                value={spcData.cp}
              />
            </Grid>
            <Grid item xs={3}>
              <InputMuiCustom
                variant={'outlined'}
                type="text"
                name="cpk"
                placeholder={`Cpk`}
                onChange={(e) => {
                  spcData.cpk = e.target.value;
                  setRender((prev) => !prev);
                }}
                value={spcData.cpk}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider />

        <Box sx={{ flexGrow: 1, width: '98%', margin: '2rem auto' }}>
          <TController {...{ spcData }} />

          {/* <Grid container spacing={2}>
            {spcData?.spc_array?.length > 0 &&
              spcData?.spc_array.map((_, sopidx) => (
                <Grid item xs={1.2} key={`${sopidx}+uud`}>
                  <InputMuiCustom
                    type="number"
                    name="part_name"
                    placeholder={`${sopidx + 1}`}
                    onChange={(e) => {
                      spcData.spc_array[sopidx] = +e.target.value;
                      setRender((prev) => !prev);
                    }}
                    value={spcData.spc_array[sopidx]}
                  />
                </Grid>
              ))}
          </Grid> */}
        </Box>
        <AlertMui
          alert={alert}
          message={'operarion and specification are empty'}
          setAlert={setAlert}
          severity={'warning'}
        />
        <AlertMui
          alert={alertforSubmmit}
          message={'Remember to submit any changes to see the updated sheet.'}
          setAlert={setAlertforSubmmit}
          severity={'info'}
        />
      </Box>

      <DialogClose isSubmitContent={false} />
      <DialogSubmit isSubmitContent={false} />
    </Box>
  );
};

export default CreateEdit;
