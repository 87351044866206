import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import useConfirm from '../../components/common/useConfirm';
import AutocompleteGridmui from '../../components/common/autocomplete/AutocompleteGridmui';
import { useParams } from 'react-router-dom';

const CreatePartStroke = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { partId } = useParams();
  const [data, setData] = useState<any>({});
  const [render, setRender] = useState(false);
  const [machineData, setMachineData] = useState<any>([]);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Document' : 'CreatePartStroke Document'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`,
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/partStrokeRateRoute/getDocumentById/${id}`)
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);
  useEffect(() => {
    axios
      .get(`/api/machineRoutes/machine/ForAutocomplete`)
      .then((res) => {
        const response = res.data;
        setMachineData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (!data.machine_id) return alert('Please! pick machine');
    if (id) {
      await axios
        .put(`/api/partStrokeRateRoute/${id}`, data)
        .then((res) => {
          if (res.data.status === 'duplicate') return alert(res.data.msg);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      data.item_master_id = partId;
      await axios
        .post('/api/partStrokeRateRoute', data)
        .then((res) => {
          if (res.data.status === 'duplicate') return alert(res.data.msg);
          setIsModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <AutocompleteGridmui
        label={'MACHINE NO.:'}
        id={'machine_id'}
        option_name={'machine_no'}
        value={data.machine_id || ''}
        arrayofObj={machineData}
        onChange={(e, value) => {
          data.machine_id = value;
          setRender((prev) => !prev);
        }}
        last_child={2}
      />
      <GridInputs
        id={'stroke'}
        name={'stroke'}
        html_for={'stroke'}
        label_name={'STROKE/MINUTE:'}
        input_type={'number'}
        focusType={'onblur'}
        value={data?.stroke || ''}
        onChange={handleChange}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default CreatePartStroke;
