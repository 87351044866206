import { Box, Divider, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Loader } from '../../components/common/Loader';
import {
  CustomButton,
  SaveButton,
  CloseButton,
  SubmitButton,
} from '../../components/common/button/CustomButtons';
import ChildHeader from '../../components/ui/ChildHeader';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';
import PartDetailsHeader from '../../components/ui/PartDeatailsHeader';
import AutocompleteMuiCustom from '../../components/common/AutocompleteMuiCustom';
import axios from 'axios';
import InputMuiCustom from '../../components/common/inputs/InputMuiCustom';
import AlertMui from '../../components/common/AlertMui';
import DataArray from './DataArray';
import CreateHeader from './CreateHeader';
import { apiConstant, createConstant } from './constant';
import { RiFileExcel2Line } from 'react-icons/ri';
import moment from 'moment';
import formatDate from '../../components/common/formatDate';
import { IData } from '../spc/interface';

const CreateEdit = () => {
  const [loader, setLoader] = useState<boolean>(false);
  let { partId, id } = useParams();
  const partName = useQuery('partName');
  const partNumber = useQuery('partNumber');
  const customer_name = useQuery('customer_name');
  const navigate = useNavigate();

  const [DialogClose, confirmClose] = useConfirm(
    'CLOSE',
    'Are you sure you want to leave this page?'
  );
  const [msaData, setMscData] = useState<any>({
    trail_A: Array.from({ length: 30 }, () => null),
    trail_B: Array.from({ length: 30 }, () => null),
    trail_C: Array.from({ length: 30 }, () => null),
  });
  const [process, setProcess] = useState([]);
  const [render, setRender] = useState(false);
  const [spec, setSpec] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertforSubmmit, setAlertforSubmmit] = useState(false);
  const [autoCompleteProcess, setAutoCompleteProcess] = useState<any>('');
  const [autoCompleteSpec, setAutoCompleteSpec] = useState<any>('');
  const [instrumentType, setInstrumentType] = useState<any>({});
  // const [process, setProcess] = useState([]);





  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Supplier' : 'Create Supplier'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  // useEffect(() => {

  
  //   if (!id) return;
  //   axios
  //     .get(`${apiConstant.getByid}/${id}`)
  //     .then((res) => {
  //       setMscData(res.data);
  //     })
  //     .catch((err) => console.log(err));
  // }, [id]);
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get(`/api/spcRoute/controlplan/${partId}`);
        // spcData.customer = res.data.customer;
        // spcData.supplier = res.data.supplier;
        setProcess(res.data.processes);

        //
        if (id) {
          const datamsa: any = await axios.get(`${apiConstant.getByid}/${id}`);
          setMscData(datamsa.data);
          // console.log(spcData,'eeeeeee')
          // console.log(msaData,'ddddddddddd')

          let getProcess = res.data.processes.filter((item: any) => {
            return item._id === datamsa.data.process_id;
          });
          getProcess = getProcess[0];
          const getSpec = getProcess?.specs.filter((item: any) => {
            return item._id === datamsa.data.spec_id;
          });
          setAutoCompleteProcess(getProcess);
          setSpec(getProcess.specs);
          setAutoCompleteSpec(getSpec[0]);
          setInstrumentType({'instrument_type':datamsa.data?.instrument_type})
          // console.log(datamsa,'xxxx');
          
          // console.log(autoCompleteProcess,autoCompleteSpec,spec,'dicl')
        }
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, [id]);

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;

    if (
      !autoCompleteSpec?.charecteristics ||
      !autoCompleteProcess?.process_name
    ) {
      setAlert(true);
      return;
    }

    msaData.specification = autoCompleteSpec?.charecteristics;
    msaData.control_product = autoCompleteSpec?.control_product;
    msaData.process_name = autoCompleteProcess?.process_name;
    msaData.part_id = partId || null;
    msaData.instrument_type=instrumentType?.instrument_type
    // console.log(msaData,'ddddddddddd')
    
    if (id) {
      axios
        .put(`${apiConstant.updateByid}/${id}`, msaData)
        .then((res) => {
          window.alert('succesfully submitted');
        })
        .catch((err) => console.log(err));
    } else {
      msaData.part_id = partId;
      axios
        .post(`${apiConstant.createid}`, msaData)
        .then((res) => {
          window.alert('succesfully submitted');
        })
        .catch((err) => console.log(err));
    }

    
  };
  function excelDownload() {
    axios
      .post(
        `/api/editExcelRoute/MSA`,
        {
          details: {
            data:msaData,
            partName: partName,
            partNumber: partNumber,
            date:formatDate(msaData?.date)
          },
        },
        { responseType: 'blob' }
      )
      .then((response) => {
        const blob = new Blob([response.data]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `MSA ${partName} ${partNumber} ${msaData?.instrument_id?.instrument_type} ${moment().format(
          'dddd, MMMM Do YYYY, h:mm:ss a'
        )}.xlsx`);
        // link.setAttribute('download', 'msa.xlsx');
        // document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const viewSpcSheet = async () => {};
  // console.log(msaData,'jkjkh');
  return (
    <Box>
      <Loader loader={loader} />
      <Box>
        <ChildHeader text={createConstant.childHeader}>
          <CustomButton
            color="primary"
            sx={{
              margin: '10px',
              backgroundColor: '#f8f9fa',
              color: '#343a40',
            }}
            icon={<RiFileExcel2Line />}
            disabled={false}
            size="large"
            onClick={() => excelDownload()}
          >
            Excel Download
          </CustomButton>

          <SubmitButton
            label="Sumbit"
            onClick={() => {
              handleSumbit();
            }}
          />
          <CloseButton
            label="Close"
            onClick={async () => {
              const ans = await confirmClose();
              if (!ans) return;
              navigate(-1);
            }}
          />
        </ChildHeader>
        <CreateHeader {...{ customer_name, partName, partNumber, msaData,process ,spec,setSpec,autoCompleteProcess,setAutoCompleteProcess,setAutoCompleteSpec,autoCompleteSpec,setInstrumentType,instrumentType}} />
        <Divider />
        <DataArray array={msaData.trail_A} trial={'A'} />

        <DataArray array={msaData.trail_B} trial={'B'} />

        <DataArray array={msaData.trail_C} trial={'C'} />
        <AlertMui
          alert={alert}
          message={'operarion and specification are empty'}
          setAlert={setAlert}
          severity={'warning'}
        />
        <AlertMui
          alert={alertforSubmmit}
          message={'Remember to submit any changes to see the updated sheet.'}
          setAlert={setAlertforSubmmit}
          severity={'info'}
        />
      </Box>
      <DialogClose isSubmitContent={false} />
      <DialogSubmit isSubmitContent={false} />
    </Box>
  );
};

export default CreateEdit;
