import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import SelectGridMui from '../../components/common/selectscustom/SelectGridMui';
import useConfirm from '../../components/common/useConfirm';
import moment from 'moment';
import DateGridMui from '../../components/common/dateCustom/DateGridMui';
import AutocompleteGridmui from '../../components/common/autocomplete/AutocompleteGridmui';
import { DivStyled } from '../../assets/styles/components/DivStyledLableInputs';
import CustomUploadButton from '../../components/common/button/CustomUploadButton';
import { apiConstant } from './helpers/contant';
import {
  capitalizeEveryWord,
  generateRandomID,
  isemailValid,
} from './helpers/utils';

const Create = ({
  id,
  setIsModal,
  setModuleId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setModuleId: any;
}) => {
  const [moduleData, setModuleData] = useState<any>({
    // unique_id: generateRandomID(),
    date: new Date(),
  });

  const [render, setRender] = useState(false);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Employee' : 'Create Employee'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/${apiConstant.getbyId}/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .get(`/api/${apiConstant.get}/generateUniqueId`)
        .then((res) => {
          setModuleData((prev: any) => ({
            ...prev,
            unique_id: res?.data?.newUniqueId,
          }));
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  useEffect(() => {
    axios
      .get(`/api/department/department/departmentForAutoComplete`)
      .then((res) => {
        if (res.data) {
          setDepartment(res.data);
        }
      })
      .catch((err) => console.log(err));
    axios
      .get(
        `/api/masterListDesignationRoute/designation/designationForAutoComplete`
      )
      .then((res) => {
        if (res.data) {
          setDesignation(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  function handleModulData(e: React.ChangeEvent<HTMLInputElement>) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    if (moduleData?.candidate_name) {
      moduleData.candidate_name = capitalizeEveryWord(
        moduleData.candidate_name
      );
    }

    if (id) {
      await axios
        .put(`/api/${apiConstant.update}/${id}`, moduleData)
        .then((res) => {
          setIsModal(false);
          setModuleId(null);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post(`/api/${apiConstant.create}`, moduleData)
        .then((res) => {
          setIsModal(false);
          setModuleId(null);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <GridInputs
        id={'unique_id'}
        name={'unique_id'}
        html_for={'unique_id'}
        label_name={'UNIQUE ID'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.unique_id}
      />
      <DateGridMui
        lable="DATE OF APPLICATION"
        onChange={(date: any) => {
          moduleData.date = date;
        }}
        value={moduleData?.date ? moment(moduleData?.date) : null}
      />
      <DateGridMui
        lable="PLAN DATE OF INTERVIEW"
        onChange={(date: any) => {
          moduleData.plan_date_interview = date;
        }}
        value={
          moduleData?.plan_date_interview
            ? moment(moduleData?.plan_date_interview)
            : null
        }
      />
      <DateGridMui
        lable="ACTUAL DATE OF INTERVIEW"
        onChange={(date: any) => {
          moduleData.actual_date_interview = date;
        }}
        value={
          moduleData?.actual_date_interview
            ? moment(moduleData?.actual_date_interview)
            : null
        }
      />
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'REMARKS'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.remarks}
        onChange={handleModulData}
      />
      <GridInputs
        id={'candidate_name'}
        name={'candidate_name'}
        html_for={'candidate_name'}
        label_name={'CANDIDATE NAME'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.candidate_name}
        onChange={handleModulData}
      />
      <GridInputs
        id={'father_name'}
        name={'father_name'}
        html_for={'father_name'}
        label_name={"Father's NAME"}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.father_name}
        onChange={handleModulData}
      />
      <GridInputs
        id={'applied_for_post'}
        name={'applied_for_post'}
        html_for={'applied_for_post'}
        label_name={'APPLIED FOR POST'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.applied_for_post}
        onChange={handleModulData}
      />
      <GridInputs
        id={'mobile_no'}
        name={'mobile_no'}
        html_for={'mobile_no'}
        label_name={'MOBILE NO.'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.mobile_no}
        onChange={handleModulData}
      />
      <AutocompleteGridmui
        label={'DESIGNATION'}
        id={'designation'}
        option_name={'designation'}
        value={moduleData.designation_id || ''}
        arrayofObj={designation}
        onChange={(e, value) => {
          moduleData.designation_id = value;
          setRender((prev) => !prev);
        }}
        last_child={2}
      />
      <AutocompleteGridmui
        label={'DEPARTMENT'}
        id={'department_name'}
        option_name={'department_name'}
        value={moduleData.department_id || ''}
        arrayofObj={department}
        onChange={(e, value) => {
          moduleData.department_id = value;
          setRender((prev) => !prev);
        }}
        last_child={2}
      />
      <GridInputs
        id={'email_id'}
        name={'email_id'}
        html_for={'email_id'}
        label_name={'EMAIL ID	'}
        placeholder="abc@gmail.com"
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.email_id}
        onChange={handleModulData}
      />
      <DateGridMui
        lable="DATE OF BIRTH"
        onChange={(date: any) => {
          moduleData.dob = date;
        }}
        value={moduleData?.dob ? moment(moduleData?.dob) : null}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
