import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import useConfirm from '../../components/common/useConfirm';
import { SelectChangeEvent } from '@mui/material';
import DateGridMui from '../../components/common/dateCustom/DateGridMui';
import moment from 'moment';
import TimeGridMui from '../../components/common/dateCustom/TimeGridMui';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [data, setData] = useState<any>({});
  const [_, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Shift' : 'Create Shift'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`,
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/shiftSchedulingRoute/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>,
  ) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/shiftSchedulingRoute/${id}`, data)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/shiftSchedulingRoute', data)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <GridInputs
        id={'shift'}
        name={'shift'}
        html_for={'shift'}
        label_name={'SHIFT:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.shift}
        onChange={handleChange}
      />
      <TimeGridMui
        lable="START TIME:"
        value={moment(data?.start_time || null)}
        onChange={(date: any) => {
          const updatedStartTime = moment().set({
            hour: date.hour(),
            minute: date.minute(),
            second: date.second(),
          });
          setData((prev: any) => ({ ...prev, start_time: updatedStartTime }));
        }}
      />
      <TimeGridMui
        lable="END TIME:"
        value={moment(data?.end_time || null)}
        onChange={(date: any) => {
          const updatedStartTime = moment().set({
            hour: date.hour(),
            minute: date.minute(),
            second: date.second(),
          });

          setData((prev: any) => ({ ...prev, end_time: updatedStartTime }));
        }}
      />

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
