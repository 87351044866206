import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { HiDocumentReport } from 'react-icons/hi';
export type tilesType = {
  sNo: number;
  link: string;
  isLink: boolean;
  isIcon: boolean;
  clauseNo?: string | number;
  p: string;
  span: string;
  icon?: any;
  style?: {};
}[];
const tiles = [
  {
    sNo: 1,
    link: '/common/createEditRevitDailyProduction',
    isLink: true,
    clauseNo: 4,
    icon: <HiDocumentReport size="5rem" color="#a12222" />,
    p: 'Rivet',
    span: 'Body',
    isIcon: true,
  },
];
const Clause = () => {
  return (
    <>
      <ChildHeader text="DAILY PRODUCTION REPORT" />
      <RenderTiles tilesObj={tiles} height="100vh" justify="start" />
    </>
  );
};

export default Clause;
