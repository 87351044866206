import { useEffect, useState } from 'react';
import TController from './components/view/TController';
import axios from 'axios';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import {
  CustomButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import { useQuery } from '../../../hooks/UseQuery';
import PartTable from './components/view/PartTable';
import ModalCustom from '../../../components/common/Modal';
import useConfirm from '../../../components/common/useConfirm';
import { PersitedDatePicker } from '../../../components/common';
import { Stack } from '@mui/material';
import AutocompleteMuiCustom from '../../../components/common/AutocompleteMuiCustom';
import DaywiseSummary from './components/view/DaywiseSummary';

const View = () => {
  const date = useQuery('date');
  const [moduleData, setModuleData] = useState<any>([]);
  const [partIdx, setPartIdx] = useState(0);
  const isView = useQuery('isView') === 'true' ? true : false;
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isOpenSummary, setIsOpenSummary] = useState(false);
  const [machineIdValue, setMachineIdValue] = useState({ _id: null });

  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?',
  );
  const [DialogDelete, confirmDelete] = useConfirm(
    'Delete',
    'Are you sure you want to  this row permanently?',
  );

  const [machines, setMachines] = useState([]);
  useEffect(() => {
    axios
      .get('/api/machineRoutes/machine/ForAutocomplete')
      .then((res) => {
        setMachines(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const getMonthlyUpdatedData = () => {
    axios
      .get(
        `/api/dailyProductionRivetPlanning/monthly/${machineIdValue._id}?date=${date}`,
      )
      .then((res) => {
        if (res.data) {
          setModuleData(res.data.results);
        }
      })
      .catch((err) => {
        setModuleData([]);
        console.log(err);
      });
  };
  useEffect(() => {
    if (date && machineIdValue?._id) {
      getMonthlyUpdatedData();
    } else {
      setModuleData([]);
    }
  }, [date, machineIdValue]);

  const handleSumbit = async () => {
    if (!date || !machineIdValue?._id) {
      alert('date and machine required');
      return;
    }
    const ans = await confirmSubmit();
    if (!ans) return;
    const modifiedModuleData = moduleData.map((item: any) => ({
      ...item,
      machine_id: machineIdValue._id,
    }));
    await axios
      .post(`/api/dailyProductionRivetPlanning`, modifiedModuleData)
      .then((res) => {
        if (res.data) {
          getMonthlyUpdatedData();
          alert('Updated Successfully');
        }
      })
      .catch((err) => console.log(err));
  };

  const handelDeleteRows = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/dailyProductionRivetPlanning/${id}`)
      .then((res) => {
        if (res.data) {
          alert('Deleted Successfully');
          getMonthlyUpdatedData();
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Box>
        <ChildHeader text={'DAILY PRODUCTION RIVET DASHBOARD PLANNING'}>
          {!isView && (
            <>
              <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                }}
                onClick={async () => {
                  if (!machineIdValue._id)
                    return alert('please choose machine first!');
                  const values = [...moduleData];
                  values.push({
                    month: date ? new Date(date).getMonth() + 1 : undefined,
                    year: date ? new Date(date).getFullYear() : undefined,
                    machine_id: moduleData.machine_id,
                  });
                  setModuleData(values);
                }}
              >
                ADD ROWS
              </CustomButton>
              <SubmitButton label="UPDATE" onClick={() => handleSumbit()} />
            </>
          )}
        </ChildHeader>
      </Box>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'start',
          alignItems: 'center',
          gap: 2,
          m: 1,
        }}
      >
        <PersitedDatePicker label="Search By Year" views={['year', 'month']} />
        <Box width={200}>
          <AutocompleteMuiCustom
            id="Machine"
            label=""
            option_name="machine_no"
            arrayofObj={machines || []}
            value={machineIdValue || ''}
            onChange={(e, value) => {
              setMachineIdValue(value);
            }}
          />
        </Box>
      </Stack>

      <TController
        {...{
          moduleData,
          isView,
          handelDeleteRows,
          isOpenPopup,
          setIsOpenPopup,
          setPartIdx,
          setIsOpenSummary,
        }}
      />
      <ModalCustom
        title="PART LIST"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <PartTable moduleData={moduleData[partIdx]} />
      </ModalCustom>
      <ModalCustom
        title="DAYWISE SUMMARY"
        openModal={isOpenSummary}
        closeModal={() => {
          setIsOpenSummary(false);
        }}
      >
        <DaywiseSummary records={moduleData[partIdx]?.records} />
      </ModalCustom>
      <DialogSubmit />
      <DialogDelete />
    </>
  );
};

export default View;
