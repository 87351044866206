import { Box } from '@mui/material';
import { RenderTable } from '../../../../components/ui';
import TBody from './TBody';
import THead from './THead';

interface IProps {
  spcData: any;
 
}

const TController = ({
    spcData,

}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead />
        <TBody {...{ spcData}} />
     
      </RenderTable>
    </Box>
  );
};

export default TController;
