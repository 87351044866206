import React, { useState } from 'react';
import { Box, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { TableCustom } from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';

const tableHeaderObj = [
  [
    { text: 'S.NO.', rowspan: 1, colspan: 1 },
    { text: 'TOOL DESC.', rowspan: 1, colspan: 1 },
  ],
];

const ViewToolItemList = ({ moduleId }: { moduleId?: any }) => {
  const [data, setData] = useState<any[]>([]);
  const fetchPartData = () => {
    if (!moduleId) return;
    axios
      .get(`/api/itemMasterRoute/getPartToolById/${moduleId}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchPartData, 300, []);
  return (
    <Box width={600}>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={item?._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{item?.tool_desc}</TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
    </Box>
  );
};

export default ViewToolItemList;
