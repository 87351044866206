import { MenuItem } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import CustomSelect from '../../../../components/common/SelectField';

const Operator = ({
  machineId,
  process,
  field,
  isView,
  subDepartmentId,
}: {
  machineId: string;
  process: any;
  field: string;
  dataObj?: any;
  isView?: any;
  subDepartmentId?: any;
}) => {
  const [operators, setOpeartors] = useState([]);
  const [render, setRender] = useState(false);
  useEffect(() => {
    if (machineId) {
      axios
        .get(
          `/api/manMachineMatrix/operatorById/${machineId}?subDepartmentId=${subDepartmentId}`,
        )
        .then((res) => {
          if (res.data) {
            setOpeartors(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [machineId]);
  return (
    <>
      {isView ? (
        process[field]
      ) : (
        <>
          <div>
            <CustomSelect
              name="operator"
              value={process[field] || ''}
              onChange={(e) => {
                process[field] = e.target.value;
                setRender((prev) => !prev);
              }}
            >
              <MenuItem>None</MenuItem>
              <MenuItem value={process[field]}>{process[field]}</MenuItem>
              {operators.map((operator: any) => (
                <MenuItem value={operator.employee_name}>
                  {operator.employee_name}
                </MenuItem>
              ))}
              <MenuItem value="Other">Other</MenuItem>
            </CustomSelect>
          </div>
        </>
      )}
    </>
  );
};
export default Operator;
