import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import MenuItem from '@mui/material/MenuItem';
import { IData } from './Interface';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import SelectGridMui from '../../components/common/selectscustom/SelectGridMui';
import useConfirm from '../../components/common/useConfirm';
import GridSelect from '../../components/common/inputs/GridSelect';
import { SelectChangeEvent } from '@mui/material';
import moment from 'moment';
import DateGridMui from '../../components/common/dateCustom/DateGridMui';
import AutocompleteGridmui from '../../components/common/autocomplete/AutocompleteGridmui';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [machine, setMachine] = useState<IData>({});
  const [department, setDepartment] = useState([]);
  const [subDepartments, setSubDepartments] = useState([]);
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Machine' : 'Create Machine'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`,
  );

  useEffect(() => {
    axios
      .get(`/api/department`)
      .then((res) => setDepartment(res.data.results))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    if (machine.department_id) {
      axios
        .get(
          `/api/subdepartment/getSubDepartmentBydepartment/${machine.department_id._id}`,
        )
        .then((res) => setSubDepartments(res.data))
        .catch((err) => console.log(err));
    }
  }, [machine.department_id]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/machineRoutes/${id}`)
        .then((res) => {
          setMachine(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleSupplier(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>,
  ) {
    setMachine((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/machineRoutes/${id}`, machine)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/machineRoutes', machine)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <GridInputs
        id={'machine_name'}
        name={'machine_name'}
        html_for={'machine_name'}
        label_name={'Machine Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.machine_name}
        onChange={handleSupplier}
      />
      <GridInputs
        id={'machine_no'}
        name={'machine_no'}
        html_for={'machine_no'}
        label_name={'Machine ID:'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.machine_no}
        onChange={handleSupplier}
      />
      <AutocompleteGridmui
        label={'Department'}
        id={'department_name'}
        option_name={'department_name'}
        value={machine.department_id || ''}
        arrayofObj={department}
        onChange={(e, value) => {
          machine.department_id = value;
          machine.sub_department_id = null;
          setRender((prev) => !prev);
        }}
        last_child={2}
      />
      <AutocompleteGridmui
        label={'Sub Department'}
        id={'sub_department_id'}
        option_name={'name'}
        value={machine.sub_department_id || ''}
        arrayofObj={subDepartments}
        onChange={(e, value) => {
          machine.sub_department_id = value;
          setRender((prev) => !prev);
        }}
        last_child={2}
      />
      <DateGridMui
        lable="Mfg. Date:"
        onChange={(date: any) => {
          machine.mfg_date = date;
          setRender((prev) => !prev);
        }}
        value={moment(machine.mfg_date) || null}
      />
      <GridInputs
        id={'capacity'}
        name={'capacity'}
        html_for={'capacity'}
        label_name={'Capacity:'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.capacity}
        onChange={handleSupplier}
      />
      <GridInputs
        id={'material_grade'}
        name={'material_grade'}
        html_for={'material_grade'}
        label_name={'Material Grade:'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.material_grade}
        onChange={handleSupplier}
      />
      <GridInputs
        id={'diameter'}
        name={'diameter'}
        html_for={'diameter'}
        label_name={'Diameter:'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.diameter}
        onChange={handleSupplier}
      />
      <GridInputs
        id={'length'}
        name={'length'}
        html_for={'length'}
        label_name={'Length (min to max):'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.length}
        onChange={handleSupplier}
      />
      <GridInputs
        id={'head_style'}
        name={'head_style'}
        html_for={'head_style'}
        label_name={'Head Style:'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.head_style}
        onChange={handleSupplier}
      />
      <GridInputs
        id={'make'}
        name={'make'}
        html_for={'make'}
        label_name={'Make:'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.make}
        onChange={handleSupplier}
      />
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks:'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.remarks}
        onChange={handleSupplier}
      />

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
