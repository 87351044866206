import { AiOutlinePartition } from 'react-icons/ai';
import { FaUserCog } from 'react-icons/fa';
import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
export type tilesType = {
  sNo: number;
  link: string;
  isLink: boolean;
  isIcon: boolean;
  clauseNo?: string | number;
  p: string;
  span: string;
  icon?: any;
  style?: {};
}[];
const tiles = [
  {
    sNo: 1,
    link: '/common/shiftSchedulingList?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <AiOutlinePartition size="5rem" color="#a12222" />,
    p: 'Shift',
    span: 'Scheduling',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/listOfScheduledDowntime',
    isLink: true,
    clauseNo: 4,
    icon: <FaUserCog size="5rem" color="#135089" />,
    p: 'List Of Schdule',
    span: 'Downtime',
    isIcon: true,
  },
];
const ShiftCreationTiles = () => {
  return (
    <>
      <ChildHeader text="Shift Creation" />
      <RenderTiles tilesObj={tiles} height="100vh" justify="start" />
    </>
  );
};

export default ShiftCreationTiles;
