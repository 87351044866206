import ExcelJS from 'exceljs';

interface ExcelData {
  headers: string[];
  data: any[][];
  sheetName: string;
  fileName: string;
}

export const generateExcel = async ({
  headers,
  data,
  sheetName,
  fileName
}: ExcelData): Promise<Blob> => {
  try {
    console.log("Starting Excel generation...");
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(sheetName);

    console.log("Adding and styling headers...");
    // Add headers
    worksheet.addRow(headers);

    // Style headers
    const headerRow = worksheet.getRow(1);
    headerRow.height = 40; // Increased header height
    headerRow.eachCell((cell, colNumber) => {
      cell.font = { bold: true, size: 11, color: { argb: 'FFFFFF' } };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4472C4' } // Dark blue background
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      
      // Set border
      cell.border = {
        top: { style: 'thin', color: { argb: 'FFFFFF' } },
        bottom: { style: 'thin', color: { argb: 'FFFFFF' } },
        left: { style: 'thin', color: { argb: 'FFFFFF' } },
        right: { style: 'thin', color: { argb: 'FFFFFF' } }
      };
    });

    console.log("Adding data...");
    // Add data
    data.forEach((rowData, rowIndex) => {
      const row = worksheet.addRow(rowData);
      
      // Apply styling to all cells in the row, even if they're empty
      for (let colIndex = 1; colIndex <= headers.length; colIndex++) {
        const cell = row.getCell(colIndex);
        cell.font = { size: 10 };
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        
        // Alternating row colors
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: rowIndex % 2 === 0 ? 'F2F2F2' : 'FFFFFF' }
        };
        
        // Thin borders
        cell.border = {
          top: { style: 'thin', color: { argb: 'D3D3D3' } },
          bottom: { style: 'thin', color: { argb: 'D3D3D3' } },
          left: { style: 'thin', color: { argb: 'D3D3D3' } },
          right: { style: 'thin', color: { argb: 'D3D3D3' } }
        };
      }
    });

    console.log("Auto-fitting columns and rows...");
    // Auto-fit columns
    worksheet.columns.forEach((column:any) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell:any) => {
        const columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = Math.min(maxLength + 4, 40); // Increased maximum width to 40
    });

    // Auto-adjust row heights
    worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
      let maxHeight = 0;
      row.eachCell({ includeEmpty: true }, (cell) => {
        const cellValue = cell.value ? cell.value.toString() : '';
        const cellLines = cellValue.split('\n').length;
        const estimatedHeight = cellLines * 20; // Increased to 20 units per line
        if (estimatedHeight > maxHeight) {
          maxHeight = estimatedHeight;
        }
      });
      row.height = Math.max(maxHeight, rowNumber === 1 ? 40 : 30); // Minimum height of 30 for data rows, 40 for header
    });

    // Add padding to cells
    worksheet.eachRow({ includeEmpty: true }, (row) => {
      row.eachCell({ includeEmpty: true }, (cell) => {
        cell.alignment = { ...cell.alignment, indent: 1, vertical: 'middle', horizontal: 'center', wrapText: true };
      });
    });

    console.log("Generating Excel buffer...");
    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    console.log("Excel buffer generated successfully.");

    return new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  } catch (error) {
    console.error('Error in generateExcel:', error);
    if (error instanceof Error) {
      console.error('Error message:', error.message);
      console.error('Error stack:', error.stack);
    }
    throw error; // Re-throw the error to be caught in the calling function
  }
};




//////////////////////////////////////////////
////////////////////////////////////////////