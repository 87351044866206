import moment from 'moment';
import { useState } from 'react';

import { TableCompHead } from '../../../../../../../../components/ui';
import formatDate from '../../../../../../../../components/common/formatDate';
import DatePickerMui from '../../../../../../../../components/common/DatePickerMui';
import { TableInput } from '../../../../../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
  customer: any;
}

const heading = [
  'S.No.',
  'Look At',
  'Look From',
  'Observation',
  'Judgement (with Remarks)',
];
const THead = ({ moduleData, isView, customer }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={7}
        rowSpan={4}
        labels={['FORMAT NO', 'ISSUE DATE', 'REV. NO.', 'REVISION DATE']}
        values={['F SYS 30', '01/04/2019', '03', '01/07/2022']}
        heading="PROCESS AND PRODUCT AUDIT  CHECK SHEET"
      />
      <tr>
        <th colSpan={3} align="left">
          AUDIT DATE:{' '}
          {isView ? (
            formatDate(moduleData.d1)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.d1)}
              onChange={(date) => {
                moduleData.d1 = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={4} align="left">
          Process Name:
          <TableInput
            isView={isView}
            name="process_name"
            type="text"
            value={moduleData.process_name}
            onChange={(e) => {
              moduleData.process_name = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Shift :{' '}
          <TableInput
            isView={isView}
            name="shift"
            type="text"
            value={moduleData.shift}
            onChange={(e) => {
              moduleData.shift = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={4} align="left">
          Auditor Name:{' '}
          <TableInput
            isView={isView}
            name="auditor_name"
            type="text"
            value={moduleData.auditor_name}
            onChange={(e) => {
              moduleData.auditor_name = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={7} align="left">
          PART NAME :{' '}
          <TableInput
            isView={isView}
            name="s1"
            type="text"
            value={moduleData.s1}
            onChange={(e) => {
              moduleData.s1 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={7} align="left">
          PART No. :{' '}
          <TableInput
            isView={isView}
            name="s2"
            type="text"
            value={moduleData.s2}
            onChange={(e) => {
              moduleData.s2 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        {heading.map((header) => (
          <th
            key={header}
            rowSpan={header === 'Observation' ? 1 : 3}
            colSpan={header === 'Observation' ? 3 : 1}
          >
            {header}
          </th>
        ))}
      </tr>
      <tr>
        <th>Mandrel</th>
        <th>Rivet Body</th>
        <th>Assembly</th>
      </tr>
      <tr>
        <th>
          Date:
          {isView ? (
            formatDate(moduleData.d1)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.d1)}
              onChange={(date) => {
                moduleData.d1 = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th>
          Date:
          {isView ? (
            formatDate(moduleData.d2)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.d2)}
              onChange={(date) => {
                moduleData.d2 = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th>
          Date:
          {isView ? (
            formatDate(moduleData.d3)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.d3)}
              onChange={(date) => {
                moduleData.d3 = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
    </thead>
  );
};

export default THead;
