import { useState } from 'react';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';
import { convToTime } from '../../../../../utils/helpers';
import CustomMobileTimePickerMui from '../../../../../components/common/CustomMobileTimePickerMui';
import Operator from '../../../components/Operator';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import moment from 'moment';
interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  autoCompleteMachineName: any;
  handleSumbit: any;
}

const TBody = ({
  moduleData,
  isView,
  handelDeleteRows,
  autoCompleteMachineName,
  handleSumbit,
}: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <tr key={index + 'module data'}>
              <td style={{ minWidth: '150px' }}>
                {isView ? (
                  formatDate(process.date)
                ) : (
                  <DatePickerMui
                    label=""
                    value={moment(process.date || null)}
                    onChange={(date) => {
                      process.date = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>
              <td style={{ minWidth: '110px' }}>
                {isView ? (
                  convToTime(process.hour)
                ) : (
                  <CustomMobileTimePickerMui
                    value={process.hour}
                    onChange={(date: any) => {
                      process.hour = date;
                      setRender((prev) => !prev);
                    }}
                    ampm={true}
                    openTo="hours"
                    views={['hours', 'minutes']}
                  />
                )}
              </td>

              <td style={{ minWidth: '200px' }}>
                {autoCompleteMachineName?._id && process.date && (
                  <Operator
                    onSubmit={handleSumbit}
                    field="operator"
                    machineId={autoCompleteMachineName?._id}
                    dataObj={{
                      date: process.date,
                    }}
                    {...{ isView,process }}
                  />
                )}
              </td>
              <td style={{ minWidth: '150px' }}>
                <TableInput
                  isView={isView}
                  name="product_desc"
                  type="text"
                  value={process.product_desc}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="target_shots"
                  type="text"
                  value={process.target_shots}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '110px' }}>
                {isView ? (
                  convToTime(process.counter_start)
                ) : (
                  <CustomMobileTimePickerMui
                    value={process.counter_start}
                    onChange={(date: any) => {
                      process.counter_start = date;
                      setRender((prev) => !prev);
                    }}
                    ampm={true}
                    openTo="hours"
                    views={['hours', 'minutes']}
                  />
                )}
              </td>

              <td style={{ minWidth: '110px' }}>
                {isView ? (
                  convToTime(process.counter_end)
                ) : (
                  <CustomMobileTimePickerMui
                    value={process.counter_end}
                    onChange={(date: any) => {
                      process.counter_end = date;
                      setRender((prev) => !prev);
                    }}
                    ampm={true}
                    openTo="hours"
                    views={['hours', 'minutes']}
                  />
                )}
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="total_shots"
                  type="text"
                  value={process.total_shots}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="off_shots"
                  type="text"
                  value={process.off_shots}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="n_f"
                  type="text"
                  value={process.n_f}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="catch"
                  type="text"
                  value={process.catch}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="a_b"
                  type="text"
                  value={process.a_b}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="b_h"
                  type="text"
                  value={process.b_h}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="solder"
                  type="text"
                  value={process.solder}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="bend"
                  type="text"
                  value={process.bend}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="crack"
                  type="text"
                  value={process.crack}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="dent"
                  type="text"
                  value={process.dent}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="rejection_detail_others"
                  type="text"
                  value={process.rejection_detail_others}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="total_rej_shots"
                  type="text"
                  value={process.total_rej_shots}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="ok_shots"
                  type="text"
                  value={process.ok_shots}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="die_maint"
                  type="text"
                  value={process.die_maint}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="die_dh_over"
                  type="text"
                  value={process.die_dh_over}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="degassing"
                  type="text"
                  value={process.degassing}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="sft_ch_over"
                  type="text"
                  value={process.sft_ch_over}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="no_operator"
                  type="text"
                  value={process.no_operator}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="mc_maint"
                  type="text"
                  value={process.mc_maint}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="power_cut"
                  type="text"
                  value={process.power_cut}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="no_plan"
                  type="text"
                  value={process.no_plan}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '80px' }} colSpan={isView ? 2 : 1}>
                <TableInput
                  isView={isView}
                  name="reason_less_prod_others"
                  type="text"
                  value={process.reason_less_prod_others}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {!isView && (
                <td style={{ minWidth: '100px' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
