import moment from 'moment';

export const spec = {
  s_no: null,
  char_product: null,
  char_process: null,
  special_character: null,
  control_product: null,
  mean: null,
  machine: null,
  tool_no: null,
  utolerance: null,
  ltolerance: null,
  unit: null,
  utl: null,
  ltl: null,
  control_product_remark: null,
  methods_measure: null,
  methods_size: null,
  methods_by: null,
  recording: null,
  responsibility: null,
  methods_process: null,
  reaction_plan: null,
  error_proofing: null,
  corrective_action: null,
  process_capability: null,
  appearance: null,
  spec_to_pir: false,
  spec_to_pdir: false,
  spec_to_setup: false,
  spec_to_Incoming_Report: false,
  checkbox4_num: null,
  check4_symbol: null,
  remarks: null,
  key: null,
};
const processes = [
  {
    process_no: null,
    process_name: null,
    specs: [spec],
  },
];

export const initialStat = {
  part_id: null,
  revision_no: null,
  revision_date: moment(),
  part_type: null,
  remarks: null,
  control_plan_no: null,
  drg_std_status: null,
  format_no: null,
  model: null,
  end_customer: null,
  latest_change_level: null,
  supplier_approval_date: moment(),
  supplier_other_approval_date: moment(),
  customer_engg_approval_date: moment(),
  customer_quality_approval_date: moment(),
  customer_other_approval_date: moment(),
  processes: [],
};
