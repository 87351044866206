import axios from 'axios';

export const excelDownload = async () => {
  await axios
    .get(`/api/editExcelRoute/employeeList`, {
      responseType: 'blob',
    })
    .then((response) => {
      const blob = new Blob([response.data]);
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `LIST OF EMPLOYEES ${
          new Date().toLocaleDateString('en-GB') || null
        } .xlsx`
      );
      link.click();
      URL.revokeObjectURL(link.href);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};

export const handleImport = (e: any, setLoader: any, setRender: any) => {
  setLoader(true);
  const formData = new FormData();
  formData.append('file', e.target.files[0], 'Employee List - Import.xlsx');
  axios
    .post('/api/uploadPart/employeeList', formData)
    .then((res) => {
      setLoader(false);
      setRender((prev: any) => !prev);
      // window.alert(res.data.msg);
    })
    .catch((err) => {
      setLoader(false);
      if (err.response.data.err) {
        window.alert(err.response.data.err);
      } else {
        window.alert(
          'Something went wrong. Please check if you are uploading the correct excel sheet or the excel sheet might be corrupted.'
        );
      }
    });
};

const generateRandomLetters = () => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let letters = '';
  for (let i = 0; i < 2; i++) {
    letters += alphabet[Math.floor(Math.random() * alphabet.length)];
  }
  return letters;
};

export const generateRandomID = () => {
  const random = Math.floor(Math.random() * 1000000)
    .toString()
    .padStart(6, '0');
  const letters = generateRandomLetters();
  return letters + '-' + random.slice(-4);
};

export const isemailValid = (email: string) => {
  const pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
  return pattern.test(email) ? true : false;
};

export const capitalizeEveryWord = (str: string) =>
  str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
