import moment from 'moment';

export const processes = [
  {
    date: null,
    time: null,
    stage: '',
    machine_no: '',
    production_order_no: '',
    item_code: '',
    suspected_qty: null,
    nature_of_nc: '',
    containment_action: '',
    root_cause: '',
    ok_qty: null,
    scrap_qty: null,
    reworked_qty: null,
    accepted_qty: null,
    qa_sign: '',
    prd_sign: '',
    remarks: '',
  },
];

export const initialState = {
  annual_month: '',
  processes: Array.from({ length: 1 }, () => ({ ...processes[0] })),
};
