import { useState } from 'react';
import { TableCompHead } from '../../../components/ui';
import { FaSortAmountDown } from 'react-icons/fa';
import { CustomIconButton } from '../../../components/common/button/CustomIconButton';

interface IProps {
  moduleData: any;
  setModuleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView, setModuleData }: IProps) => {
  const [render, setRender] = useState(false);

  const sortByDate = () => {
    const data = moduleData;

    data.processes.sort((a: { date: string }, b: { date: string }) => {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return dateA - dateB;
    });

    setModuleData({ ...data });
  };

  return (
    <thead>
      <TableCompHead
        colSpan={21}
        rowSpan={4}
        labels={[
          'FORMAT NO: ',
          'ISSUE DATE: ',
          'REVISION NO: ',
          'REVISION DATE: ',
        ]}
        values={['F QAD 23', '01/04/2017', '04', '16/10/2023']}
        heading="NON CONFORMANCE REPORT"
      />
      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'S.NO.',
          'DATE',
          'TIME',
          'STAGE',
          'MACHINE NO.',
          // 'MC NO.',
          'PRODUCTION ORDER NO.',
          'ITEM CODE',
          'SUSPECTED QTY',
          'UOM',
          'NATURE OF NC',
          'RED BIN ANALYSIS & ACTION PLAN',
          'DISPOSITION TYPE',
          'QA SIGN',
          'PRD SIGN',
          'REMARKS',
        ].map((head, index) => (
          <th
            style={{ textTransform: 'uppercase' }}
            rowSpan={index === 10 || index === 11 ? 1 : 2}
            colSpan={
              (index === 10 && 3) ||
              (index === 11 && 4) ||
              (index === 14 && 2) ||
              1
            }
          >
            {head}
            {head === 'DATE' && (
              <CustomIconButton
                tooltipTitle={'Sort'}
                color="secondary"
                sx={{ pl: 2 }}
                disabled={false}
                size="small"
                onClick={sortByDate}
              >
                <FaSortAmountDown style={{ color: '#c92a2a' }} />
              </CustomIconButton>
            )}
          </th>
        ))}
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'CONTAINMENT ACTION',
          'ROOT CAUSE',
          'Action Taken',
          'OK QTY',
          'SCRAP QTY',
          'REWORKED QTY',
          'ACCEPTED UD QTY',
        ].map((head, index) => (
          <th style={{ textTransform: 'uppercase' }}>{head}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
