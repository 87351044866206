export const listConstant = {
  childHeader: 'CALLIBRATION plan list',
  addButtonLable: 'New CALLIBRATION',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'CALLIBRATION plan list',
  theadHeader: 'Variable Plan for MSA',
  extracolSpan: 7,
};

export const checkListList = {
  childHeader: 'CALLIBRATION STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'instrumentplanRoute',
  gettableDataurl: 'instrumentplanRoute/monthly',
  getPlanByid: 'instrumentplanRoute',
  createPlanByid: 'instrumentplanRoute',
  updatePlanByid: 'instrumentplanRoute',
  sync: 'instrumentplanRoute/handleSync',
  getAllCheckList: 'instrumentplanRoute/getAllCheckList',
  checkListStatus: 'instrumentplanRoute/handleStatus',
  checklistUpload: 'instrumentplanRoute/handleUpload',
  checklistDeleteImage: 'instrumentplanRoute/handleDeleteImage',
};

export const navigationConstant = {
  createPlanSchedule: '/common/instrumentplancreate',
};
