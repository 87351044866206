import { BsFillEaselFill } from 'react-icons/bs';
import { GrTest } from 'react-icons/gr';
import { HiDocumentReport } from 'react-icons/hi';
import { ImUserTie } from 'react-icons/im';
import { Groups3 } from '@mui/icons-material';

const tiles = [
  // {
  //   sNo: 1,
  //   link: '/common/clauseUploadList?documentType=masterProcedureClause5&heading=MASTER PROCEDURE',
  //   isLink: true,
  //   clauseNo: 5,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: 'MASTER',
  //   span: 'PROCEDURE',
  //   isIcon: true,
  // },
  {
    sNo: 11,
    link: '/common/hireCandidatePortalList',
    isLink: true,
    icon: <Groups3 sx={{ fontSize: '5rem', color: '#1971c2' }} />,
    p: 'Hire Candidate',
    span: 'Portal',
    isIcon: true,
  },
  {
    sNo: 1,
    // link: 'https://uc.secure.aqua.mushinlabs.com/',
    link: '/common/masterlistoffaculty?filter=createdAt',
    isLink: true,
    // target: '_blank',
    p: 'Master List Of',
    span: 'FACULTY',
    isIcon: true,
    icon: <ImUserTie size="5rem" color="#135089" />,
  },
  {
    sNo: 1,
    link: '/common/employee_list?filter=createdAt',
    isLink: true,
    p: 'MASTER LIST OF',
    span: 'EMPLOYEE',
    isIcon: true,
    icon: <ImUserTie size="5rem" color="#135089" />,
  },

  {
    sNo: 1,
    link: '/common/effectivenessTestPaperHome',
    isLink: true,
    p: 'EFFECTIVENESS TEST',
    span: 'PAPER',
    isIcon: true,
    icon: <GrTest size="5rem" color="#135089" />,
  },
  {
    sNo: 1,
    link: '/common/trainingTniHome',
    isLink: true,
    p: 'TRAINING',
    span: '',
    isIcon: true,
    icon: <BsFillEaselFill size="5rem" color="#135089" />,
  },
  {
    sNo: 1,
    link: '/common/skillEvaluationHome',
    isLink: true,
    p: 'SKILL',
    span: 'EVALUATION',
    isIcon: true,
    icon: <BsFillEaselFill size="5rem" color="#135089" />,
  },
  {
    sNo: 10,
    link: `/common/qualityDepartmentUpload/?documentType=luxLevelMonitoring&heading=LUX LEVEL MONITORING SHEET`,
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'HR DMS',
    span: 'Upload Documents',
    isIcon: true,
  },
];

export default tiles;
