export const listConstant = {
  childHeader: 'Calibration Audit List',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Calibration Audit List',
  theadHeader: 'Calibration Audit Plan',
  extracolSpan: 2,
};

export const checkListList = {
  childHeader: 'Calibration Audit STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'calibrationAuditPlanRoute',
  gettableDataurl: 'calibrationAuditPlanRoute/monthly',
  getPlanByid: 'calibrationAuditPlanRoute',
  createPlanByid: 'calibrationAuditPlanRoute',
  updatePlanByid: 'calibrationAuditPlanRoute',
  sync: 'calibrationAuditPlanRoute/handleSync',
  getAllCheckList: 'calibrationAuditPlanRoute/getAllCheckList',
  checkListStatus: 'calibrationAuditPlanRoute/handleStatus',
  checklistUpload: 'calibrationAuditPlanRoute/handleUpload',
  checklistDeleteImage: 'calibrationAuditPlanRoute/handleDeleteImage',
  checklistData: 'calibrationAuditPlanRoute/checklistRoute',
  getMasterDataAutocomplete:
    'masterlistinstrument/instrumentList/instumentForAutoComplete',
};

export const tilesConstant = {
  childHeaderText: 'Calibration Audit',
};

export const navigationConstant = {
  createPlanSchedule: '/common/callibrationplancreate',
  // createChecklist: '/common/supplierAuditPlanChecklistCreate',
};
