import React, { useState, useEffect, useRef } from 'react';
import { Button, Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import ChildHeader from '../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../components/common/FilterBy';
import { SearchBar } from '../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../components/common';
import useDebounce from '../../hooks/useDebounce';
import { ViewIconButton } from '../../components/common/button/CustomIconButton';
import ModalCustom from '../../components/common/Modal';
import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';
import formatDate from '../../components/common/formatDate';
import ReportFormat from '../../components/ui/ReportFormat';
import ViewFile from './components/ViewFile';
import { calculateDueDate } from './utils';
import TableScroll from '../../components/ui/renderTable/TableScroll';
import { usePermission } from '../../utils/helpers/usePermission';

const tableHeaderObj = [
  [
    { text: 'S.No.', rowspan: 1, colspan: 1 },
    { text: 'DESCRIPTION', rowspan: 1, colspan: 1 },
    { text: 'SOURCE (H/OS)', rowspan: 1, colspan: 1 },
    { text: 'ID NO.', rowspan: 1, colspan: 1 },
    { text: 'MAKE', rowspan: 1, colspan: 1 },
    { text: 'DEPARTMENT', rowspan: 1, colspan: 1 },
    { text: 'RANGE', rowspan: 1, colspan: 1 },
    { text: 'LEAST COUNT', rowspan: 1, colspan: 1 },
    { text: 'FREQUENCY', rowspan: 1, colspan: 1 },
    { text: 'INSTRUMENT STATUS', rowspan: 1, colspan: 1 },
    { text: 'CALIBRATION ON', rowspan: 1, colspan: 1 },
    { text: 'CALIBRATION DUE ON', rowspan: 1, colspan: 1 },
    { text: 'DELAY IF ANY', rowspan: 1, colspan: 1 },
    { text: 'ACCEPTANCE CRITERIA', rowspan: 1, colspan: 1 },
    { text: 'REMARKS', rowspan: 1, colspan: 1 },
    { text: 'VIEW FILE', rowspan: 1, colspan: 1 },
  ],
];

const List = () => {
  const permissions = usePermission(10);
  const [data, setData] = useState<any[]>([]);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const instrument = useQuery('instrument');
  const location = useQuery('location');
  const page = useQuery('page') || 1;

  const [categoryData, setCategoryData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const idRef = useRef('');

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?',
  );

  useEffect(() => {
    axios
      .get(`/api/masterListLocationRoute/locationList/categoryForAutoComplete`)
      .then((res) => {
        if (res?.data) {
          const formattedData = res.data.map((item: any) => ({
            name: item.name,
            value: item.name,
          }));
          setLocationData(formattedData);
        }
      })
      .catch((err) => console.log(err));
    axios
      .get('/api/masterlistinstrument/instrumentcategory')
      .then((res) => {
        if (res?.data?.result) {
          const formattedData = res.data.result.map((item: any) => ({
            name: item.name,
            value: item.name,
          }));
          setCategoryData(formattedData);
        }
      })
      .catch((err) => console.log(err));
  }, [render]);

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/masterlistinstrument/getObsoleteInstrument?page=${page}&sortBy=${filter}&instrument=${instrument}&location=${location}&search=${search}&perPage=${perPage}`,
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.result);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 500, [
    page,
    search,
    render,
    instrument,
    location,
    filter,
    isOpenPopup,
  ]);

  const excelDownload = async () => {
    await axios
      .get(
        `/api/editExcelRoute/masterListOfObsoleteInstrument?page=${page}&sortBy=${filter}&instrument=${instrument}&location=${location}&search=${search}&perPage=${perPage}`,
        {
          responseType: 'blob',
        },
      )
      .then((response) => {
        const blob = new Blob([response.data]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `masterListOfObsoleteInstrument.xlsx`);
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <>
      <ChildHeader text="INSTRUMENT LIST">
        <Button
          type="button"
          variant="contained"
          color="primary"
          size="large"
          style={{
            background: '#76102a',
            marginRight: '1rem',
          }}
          onClick={() => {
            excelDownload();
          }}
        >
          Download List
        </Button>
      </ChildHeader>
      <ReportFormat
        text1={'Format No.: F QAD 04'}
        text2={'Issue Date: 01/04/2017'}
        text3={'Revision: 02'}
        text4={'Revision Date: 04/09/2021'}
      />
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <Stack direction={'row'} gap={2} flex="1">
          <FilterBy
            sx={{ width: 200 }}
            name="location"
            label="Filter By Location"
            options={locationData}
          />
          <FilterBy
            sx={{ width: 200 }}
            name="instrument"
            label="Filter By Instrument Description"
            options={categoryData}
          />
        </Stack>
      </SearchFilterContainer>
      <TableScroll>
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {data &&
              data.map((item: any, index: number) => (
                <React.Fragment key={item._id}>
                  <TableRow>
                    <TableCell align="center">
                      {perPage * (+page - 1) + index + 1}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {item?.instrument_type}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {item?.source}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {item?.id_no}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {item?.make}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {item?.department?.department_name}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {item?.range}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {item?.least_count}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {item?.frequency_in_year}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        textTransform: 'capitalize',
                        ...(item.instrument_status === 'ACTIVE' && {
                          backgroundColor: '#84a98c',
                        }),
                        ...(item.instrument_status === 'OBSOLETE' && {
                          backgroundColor: '#e9c46a',
                        }),
                      }}
                    >
                      {item?.instrument_status}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {formatDate(item?.calibration_done_date)}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        textTransform: 'capitalize',
                        ...(new Date(
                          calculateDueDate(
                            item?.calibration_done_date,
                            +item?.frequency_in_year,
                          ),
                        ) < new Date() && {
                          background: 'red',
                          color: 'white',
                        }),
                      }}
                    >
                      {calculateDueDate(
                        item?.calibration_done_date,
                        +item?.frequency_in_year,
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {item?.delay}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {item?.acceptence_criteria}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {item?.remarks}
                    </TableCell>
                    <TableCell>
                      <Stack
                        direction={'row'}
                        alignItems="center"
                        justifyContent="center"
                      >
                        {item.files?.length > 0 && (
                          <>
                            <ViewIconButton
                              tooltipTitle="view"
                              sx={{ marginLeft: '1.5rem' }}
                              onClick={() => {
                                idRef.current = item._id || '';
                                setIsOpenPopup(true);
                              }}
                            />
                          </>
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </TableCustom>
      </TableScroll>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        title="FILE LIST"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <ViewFile id={idRef.current} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
