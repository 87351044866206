import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import SelectGridMui from '../../../components/common/selectscustom/SelectGridMui';
import { MenuItem } from '@mui/material';
import DatePickerMui from '../../../components/common/DatePickerMui';
import moment from 'moment';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';

const Create = ({
  id,
  setIsModal,
  setListId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setListId: any;
}) => {
  const [data, setData] = useState<any>({});
  // const [categoryData, setCategoryData] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Customer' : 'Create Customer'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  const [department, setDepartment] = useState([]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/masterlistofAppearance/${id}`)
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  // useEffect(() => {
  //   axios
  //     .get(`/api/department`)
  //     .then((res) => setDepartment(res.data.results))
  //     .catch((err) => console.log(err));

  //   axios
  //     .get('/api/masterlistofAppearance/instrumentcategory')
  //     .then((res) => {
  //       if (res.data.result) {
  //         setCategoryData(res.data.result);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  function handleData(e: React.ChangeEvent<HTMLInputElement>) {
    setData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    console.log('gggggggggggggggggg', data);
    setIsSubmit(true);
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/masterlistofAppearance/${id}`, data)
        .then((res) => {
          setIsSubmit(false);
          setListId(null);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/masterlistofAppearance', data)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setListId(null);
        })
        .catch((err) => {
          setIsSubmit(false);
          console.log(err);
        });
    }
  };
  console.log(data);
  return (
    <>
      <GridInputs
        id={'equipment_no'}
        name={'equipment_no'}
        html_for={'equipment_no'}
        label_name={'Equipment No.'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.equipment_no || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'equipment_name'}
        name={'equipment_name'}
        html_for={'equipment_name'}
        label_name={'Equipment Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.equipment_name || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'capacity'}
        name={'capacity'}
        html_for={'capacity'}
        label_name={'Capacity:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.capacity || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'make'}
        name={'make'}
        html_for={'make'}
        label_name={'Make:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.make || ''}
        onChange={handleData}
      />
      <GridDatePicker
        label_name="Installation Year"
        html_for={'installation_year'}
        views={['year']}
        value={data?.installation_year || null}
        onChange={(date) => {
          data.installation_year = moment(date);
          setRender((prev) => !prev);
        }}
      />
      <AutocompleteGridmui
        label={'Location'}
        id="location"
        option_name={'department_name'}
        value={data?.department_id || ''}
        arrayofObj={department}
        onChange={(e, value) => {
          data.department_id = value;
          setRender((prev) => !prev);
        }}
      />
      <GridInputs
        id={'frequency'}
        name={'frequency'}
        html_for={'frequency'}
        label_name={'Frequency:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.frequency}
        onChange={handleData}
      />
      {/* <GridInputs
        id={'function'}
        name={'function'}
        html_for={'function'}
        label_name={'Function:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.function || ''}
        onChange={handleData}
        last_child={2}
      /> */}
      {/* <GridInputs
        id={'type_of_industry'}
        name={'type_of_industry'}
        html_for={'type_of_industry'}
        label_name={'Type Of Industry:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.type_of_industry || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'id_no'}
        name={'id_no'}
        html_for={'id_no'}
        label_name={'UNIQUE ID NO.:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.id_no || ''}
        onChange={handleData}
      />

      <GridInputs
        id={'range'}
        name={'range'}
        html_for={'range'}
        label_name={'SIZE'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.range || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'least_count'}
        name={'least_count'}
        html_for={'least_count'}
        label_name={'LEAST COUNT:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.least_count || ''}
        onChange={handleData}
      />

      <SelectGridMui
        name="frequency_cycle"
        value={data?.frequency_cycle || ''}
        lable={'CALIBRATION FREQ.:'}
        onChange={(e: any) => handleData(e)}
      >
        <MenuItem value="">
          <em>NONE</em>
        </MenuItem>
        <MenuItem value={1}>1 Month</MenuItem>
        <MenuItem value={3}>3 Month</MenuItem>
        <MenuItem value={6}>6 Month</MenuItem>
        <MenuItem value={12}>12 Month</MenuItem>
      </SelectGridMui>

      <GridInputs
        id={'function'}
        name={'function'}
        html_for={'function'}
        label_name={'Function:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.function || ''}
        onChange={handleData}
        last_child={2}
      /> */}

      {/* <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.remarks || ''}
        onChange={handleData}
        last_child={2}
      /> */}
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
