import React, { useRef, useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import ChildHeader from '../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../components/common/FilterBy';
import { SearchBar } from '../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../components/common';
import useDebounce from '../../hooks/useDebounce';
import {
  CustomIconButton,
  ViewIconButton,
} from '../../components/common/button/CustomIconButton';
import { useQuery } from '../../hooks/UseQuery';
import formatDate from '../../components/common/formatDate';
import Add from '@mui/icons-material/Add';
import ModalCustom from '../../components/common/Modal';
import MachineList from './components/MachineList';
import ViewMachineList from './components/ViewMachineList';
import ToolItemList from './components/ToolItemList';
import ViewToolItemList from './components/ViewToolItemList';

const tableHeaderObj = [
  [
    { text: 'S.NO.', rowspan: 1, colspan: 1 },
    { text: 'ITEM NUMBER', rowspan: 1, colspan: 1 },
    { text: 'ITEM DESCRIPTION', rowspan: 1, colspan: 1 },
    { text: 'CREATED AT', rowspan: 1, colspan: 1 },
    { text: 'MACHINE', rowspan: 1, colspan: 1 },
    { text: 'TOOL', rowspan: 1, colspan: 1 },
  ],
];
const filterOptions = [{ value: 'createdAt', name: 'A-Z' }];

const List = () => {
  const [data, setData] = useState<any[]>([]);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const search = useQuery('search') || '';
  const sort = useQuery('sort');
  const page = useQuery('page') || 1;

  const moduleIdref = useRef<any>(null);
  const [machineIds, setMachineIds] = useState<string[]>([]);
  const [isMachineModal, setIsMachineModal] = useState(false);
  const [isViewMachineModal, setIsViewMachineModal] = useState(false);
  const [toolIds, setToolIds] = useState<string[]>([]);
  const [isToolModal, setIsToolModal] = useState(false);
  const [isViewToolModal, setIsViewToolModal] = useState(false);
  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/itemMasterRoute?page=${page}&sortBy=${sort}&search=${search}&perPage=${10}`,
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 500, [
    page,
    search,
    render,
    sort,
    isMachineModal,
    isToolModal,
  ]);

  const handleMachineUpdate = (id: string) => {
    const machineIndex = machineIds.indexOf(id); // checking spare part id exists or not , if not exist then push if not then there is only one option that is to remove id
    if (machineIndex === -1) {
      machineIds.push(id);
    } else {
      machineIds.splice(machineIndex, 1);
    }
    if (moduleIdref && Array.isArray(machineIds)) {
      axios
        .patch(
          `/api/itemMasterRoute/machineIds/${moduleIdref.current}`,
          machineIds,
        )
        .then((res) => {
          if (res.data) {
            alert('updated successfull');
            setRender((prev) => !prev);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const handleToolUpdate = (id: string) => {
    const toolIndex = toolIds.indexOf(id); // checking spare part id exists or not , if not exist then push if not then there is only one option that is to remove id
    if (toolIndex === -1) {
      toolIds.push(id);
    } else {
      toolIds.splice(toolIndex, 1);
    }
    if (moduleIdref && Array.isArray(toolIds)) {
      axios
        .patch(`/api/itemMasterRoute/toolIds/${moduleIdref.current}`, toolIds)
        .then((res) => {
          if (res.data) {
            alert('updated successfull');
            setRender((prev) => !prev);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <ChildHeader text="ITEM MASTER VS MACHINE AND TOOL"></ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <Stack direction={'row'} gap={2} flex="1">
          <FilterBy
            sx={{ width: 200 }}
            name="sort"
            label="Sort"
            options={filterOptions}
          />
        </Stack>
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item.item_no}</TableCell>
                  <TableCell align="center">{item.item_description}</TableCell>
                  <TableCell align="center">
                    {formatDate(item?.createdAt || '')}
                  </TableCell>
                  <TableCell align="center">
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <CustomIconButton
                        tooltipTitle="add machine"
                        onClick={() => {
                          moduleIdref.current = item._id;
                          setMachineIds(
                            item.machine_ids.map(
                              (machine: any) => machine._id,
                            ) || [],
                          );
                          setIsMachineModal(true);
                        }}
                      >
                        <Add />
                      </CustomIconButton>
                      <ViewIconButton
                        tooltipTitle="view machine"
                        onClick={() => {
                          moduleIdref.current = item._id;
                          setIsViewMachineModal(true);
                        }}
                      />
                    </Stack>
                  </TableCell>
                  <TableCell align="center">
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <CustomIconButton
                        tooltipTitle="add tool"
                        onClick={() => {
                          moduleIdref.current = item._id;
                          setToolIds(
                            item.tool_item_ids.map(
                              (category: any) => category._id,
                            ) || [],
                          );
                          setIsToolModal(true);
                        }}
                      >
                        <Add />
                      </CustomIconButton>
                      <ViewIconButton
                        tooltipTitle="view tool"
                        onClick={() => {
                          moduleIdref.current = item._id;
                          setIsViewToolModal(true);
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <ModalCustom
        title="MACHINE LIST"
        openModal={isMachineModal}
        closeModal={() => {
          setIsMachineModal(false);
        }}
      >
        <MachineList {...{ handleMachineUpdate, machineIds }} />
      </ModalCustom>
      <ModalCustom
        title="MACHINE LIST"
        openModal={isViewMachineModal}
        closeModal={() => {
          setIsViewMachineModal(false);
        }}
      >
        <ViewMachineList moduleId={moduleIdref.current} />
      </ModalCustom>
      <CustomPagination totalPage={totalPages} />

      <ModalCustom
        title="TOOL ITEM CODE LIST"
        openModal={isToolModal}
        closeModal={() => {
          setIsToolModal(false);
        }}
      >
        <ToolItemList {...{ handleToolUpdate, toolIds }} />
      </ModalCustom>
      <ModalCustom
        title="TOOL ITEM CODE LIST"
        openModal={isViewToolModal}
        closeModal={() => {
          setIsViewToolModal(false);
        }}
      >
        <ViewToolItemList moduleId={moduleIdref.current} />
      </ModalCustom>
    </>
  );
};

export default List;
