import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';
import AutocompleteMuiCustom from '../../../components/common/AutocompleteMuiCustom';
import { Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import GridSelect from '../../../components/common/inputs/GridSelect';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import { Loader } from '../../../components/common/Loader';
import ModalCustom from '../../../components/common/Modal';

import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import { DivStyled } from '../../../assets/styles/components/DivStyledLableInputs';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../components/common/button/CustomIconButton';
import PartTable from './components/PartTable';
import { Checkbox } from '@mui/material';
import { IData } from './helpers/interfaces';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [data, setData] = useState<any>({
    ...{
      exclude_report: false,
      complaint_status: 'OPEN',
      m1: { check: '', quantity: '', verification: '' },
      m2: { check: '', quantity: '', verification: '' },
      m3: { check: '', quantity: '', verification: '' },
    },
  });
  const [files, setFiles] = useState<any>(null);
  const [images, setImages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Customer Complaint' : 'Create Customer Complaint'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`,
  );
  const [customerData, setCustomerData] = useState([]);
  const [defectData, setDefectData] = useState([]);
  const [isPartModal, setIsPartModal] = useState(false);
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/supplierComplaintRoutes/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  useEffect(() => {
    axios
      .get('/api/defectRoutes/defectList/defectForAutoComplete')
      .then((res) => {
        setDefectData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  function handleCustomerData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>,
  ) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }
  const handleSumbit = async () => {
    if (!data.defect_id) {
      alert('Please enter defect name');
      return;
    }
    const ans = await confirmSubmit();
    if (!ans) return;

    setIsLoading(true);

    if (typeof data.defect_id === 'object') {
      data.defect_id = data.defect_id._id;
    }
    if (typeof data.part_id === 'object') {
      data.part_id = data.part_id._id;
    }

    if (id) {
      await axios
        .put(`/api/supplierComplaintRoutes/${id}`, data)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/supplierComplaintRoutes', data)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <Loader loader={isLoading} />
      <GridInputs
        id={'complaint_no'}
        name={'complaint_no'}
        html_for={'complaint_no'}
        label_name={'Complaint No.: '}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.complaint_no || ''}
        onChange={handleCustomerData}
      />
      <GridDatePicker
        label_name="Date of Occurance"
        html_for={'issue_reported_date'}
        value={data?.date_of_occurrence || null}
        onChange={(date) => {
          data.date_of_occurrence = date;
        }}
      />
      <DivStyled style={{ alignItems: 'center' }}>
        <label>{'Item Number'}</label>
        <div
          style={{
            borderLeft: '2px solid #ced4da',
            paddingLeft: '1rem',
            width: '100%',
          }}
        >
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.6rem',
                }}
              >
                {typeof data?.part_id === 'object' && data?.part_id !== null
                  ? data?.part_id?.item_no
                  : data?.partNumber || ''}
              </p>
            </Grid>

            {data.part_id ? (
              <Grid item xs={2}>
                <RemoveIconButton
                  tooltipTitle="remove part"
                  onClick={() => {
                    data.part_id = null;
                    data.partNumber = null;
                    data.partName = null;

                    setRender((prev) => !prev);
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={2}>
                <AddIconButton
                  tooltipTitle="add part"
                  onClick={() => {
                    setIsPartModal(true);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </DivStyled>
      <DivStyled style={{ alignItems: 'center' }}>
        <label>{'Item Description'}</label>
        <div
          style={{
            borderLeft: '2px solid #ced4da',
            paddingLeft: '1rem',
            width: '100%',
          }}
        >
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.6rem',
                }}
              >
                {typeof data?.part_id === 'object' && data?.part_id !== null
                  ? data?.part_id?.item_description
                  : data?.partName || ''}
              </p>
            </Grid>
          </Grid>
        </div>
      </DivStyled>
      <GridInputs
        id={'mrn'}
        name={'mrn'}
        html_for={'mrn'}
        label_name={'MRN: '}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.mrn || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'supplier'}
        name={'supplier'}
        html_for={'supplier'}
        label_name={'SUPPLIER: '}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.supplier || ''}
        onChange={handleCustomerData}
      />
      <AutocompleteGridmui
        id={'defect_name'}
        label={'Defects'}
        option_name={'defect_name'}
        arrayofObj={defectData}
        value={data.defect_name || data.defect_id || ''}
        onChange={(e, value) => {
          data.defect_id = value._id;
          data.defect_name = value;
          setRender((prev) => !prev);
        }}
      />
      <GridInputs
        id={'total_quantity'}
        name={'total_quantity'}
        html_for={'total_quantity'}
        label_name={'TOTAL QUANTITY: '}
        input_type={'number'}
        focusType={'onblur'}
        value={data?.total_quantity || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'uom'}
        name={'uom'}
        html_for={'uom'}
        label_name={'UOM: '}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.uom || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'root_cause'}
        name={'root_cause'}
        html_for={'root_cause'}
        label_name={'Root Cause: '}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.root_cause || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'action_taken'}
        name={'action_taken'}
        html_for={'action_taken'}
        label_name={'Action Taken: '}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.action_taken || ''}
        onChange={handleCustomerData}
      />
      <GridDatePicker
        label_name="Target Date"
        html_for={'target_date'}
        value={data?.target_date || null}
        onChange={(date) => {
          data.target_date = date;
        }}
      />
      <GridDatePicker
        label_name="Actual Date"
        html_for={'actual_closure_date'}
        value={data?.actual_closure_date || null}
        onChange={(date) => {
          data.actual_closure_date = date;
        }}
      />
      <GridInputs
        id={'resp'}
        name={'resp'}
        html_for={'resp'}
        label_name={'Resp.: '}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.resp || ''}
        onChange={handleCustomerData}
      />
      <h1>Effectiveness</h1>
      <GridSelect
        name="M - I"
        label_name="M1"
        html_for={'m1'}
        value={data?.m1?.check || ''}
        onChange={(
          e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>,
        ) => {
          data.m1.check = e.target.value;
          setRender((prev) => !prev);
        }}
      >
        <MenuItem value="Ok">OK</MenuItem>
        <MenuItem value="Reject">Reject</MenuItem>
      </GridSelect>
      <GridInputs
        id={'uom'}
        name={'uom'}
        html_for={'uom'}
        label_name={'M1 UOM: '}
        input_type={'text'}
        focusType={'onblur'}
        value={data.m1?.uom || ''}
        onChange={(e) => {
          data.m1.uom = e.target.value;
          setRender((prev) => !prev);
        }}
      />
      <GridInputs
        id={'m1'}
        name={'m1'}
        html_for={'m1'}
        label_name={'Qty. checked: '}
        input_type={'number'}
        focusType={'onblur'}
        value={data?.m1?.quantity || ''}
        onChange={(e) => {
          data.m1.quantity = e.target.value;
          setRender((prev) => !prev);
        }}
      />
      <GridSelect
        name="M - II"
        label_name="M2"
        html_for={'m2'}
        value={data?.m2?.check || ''}
        onChange={(e) => {
          data.m2.check = e.target.value;
          setRender((prev) => !prev);
        }}
      >
        <MenuItem value="Ok">OK</MenuItem>
        <MenuItem value="Reject">Reject</MenuItem>
      </GridSelect>
      <GridInputs
        id={'uom'}
        name={'uom'}
        html_for={'uom'}
        label_name={'M2 UOM: '}
        input_type={'text'}
        focusType={'onblur'}
        value={data.m2?.uom || ''}
        onChange={(e) => {
          data.m2.uom = e.target.value;
          setRender((prev) => !prev);
        }}
      />
      <GridInputs
        id={'m2'}
        name={'m2'}
        html_for={'m2'}
        label_name={'Qty. checked: '}
        input_type={'number'}
        focusType={'onblur'}
        value={data?.m2?.quantity || ''}
        onChange={(e) => {
          data.m2.quantity = e.target.value;
          setRender((prev) => !prev);
        }}
      />
      <GridSelect
        name="M - III"
        label_name="M3"
        html_for={'m3'}
        value={data?.m3?.check || ''}
        onChange={(e) => {
          data.m3.check = e.target.value;
          setRender((prev) => !prev);
        }}
      >
        <MenuItem value="Ok">OK</MenuItem>
        <MenuItem value="Reject">Reject</MenuItem>
      </GridSelect>
      <GridInputs
        id={'uom'}
        name={'uom'}
        html_for={'uom'}
        label_name={'M3 UOM: '}
        input_type={'text'}
        focusType={'onblur'}
        value={data.m3?.uom || ''}
        onChange={(e) => {
          data.m3.uom = e.target.value;
          setRender((prev) => !prev);
        }}
      />
      <GridInputs
        id={'m3'}
        name={'m3'}
        html_for={'m3'}
        label_name={'Qty. checked: '}
        input_type={'number'}
        focusType={'onblur'}
        value={data?.m3?.quantity || ''}
        onChange={(e) => {
          data.m3.quantity = e.target.value;
          setRender((prev) => !prev);
        }}
      />
      <h1>On Site Verification</h1>
      <GridInputs
        id={'MI'}
        name={'MI'}
        html_for={'MI'}
        label_name={'M I: '}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.m1?.verification || ''}
        onChange={(e) => {
          data.m1.verification = e.target.value;
          setRender((prev) => !prev);
        }}
      />
      <GridInputs
        id={'MII'}
        name={'MII'}
        html_for={'MII'}
        label_name={'M II: '}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.m2?.verification || ''}
        onChange={(e) => {
          data.m2.verification = e.target.value;
          setRender((prev) => !prev);
        }}
      />{' '}
      <GridInputs
        id={'MIII'}
        name={'MIII'}
        html_for={'MIII'}
        label_name={'M III: '}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.m3?.verification || ''}
        onChange={(e) => {
          data.m3.verification = e.target.value;
          setRender((prev) => !prev);
        }}
      />
      <GridInputs
        id={'horizontal_deployment'}
        name={'horizontal_deployment'}
        html_for={'horizontal_deployment'}
        label_name={'Horizontal Deployment:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.horizontal_deployment || ''}
        onChange={handleCustomerData}
      />
      <h1>STANDARDIZATION</h1>
      <GridSelect
        name="quality_alert"
        label_name="Quality Alert"
        html_for={'quality_alert'}
        value={data?.quality_alert || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <GridSelect
        name="drg_pfd"
        label_name="DRG / PFD"
        html_for={'drg_pfd'}
        value={data?.drg_pfd || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <GridSelect
        name="fmea"
        label_name="FMEA"
        html_for={'fmea'}
        value={data?.fmea || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <GridSelect
        name="control_plan"
        label_name="Control Plan"
        html_for={'control_plan'}
        value={data?.control_plan || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <GridSelect
        name="inspection_standard"
        label_name="Inspection Standard"
        html_for={'inspection_standard'}
        value={data?.inspection_standard || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <GridInputs
        id={'mc_check_sheet'}
        name={'mc_check_sheet'}
        html_for={'mc_check_sheet'}
        label_name={'MC Check Sheet: '}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.mc_check_sheet || ''}
        onChange={handleCustomerData}
      />
      <GridSelect
        name="complaint_status"
        label_name="Complaint Status"
        html_for={'complaint_status'}
        value={data?.complaint_status || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="OPEN">OPEN</MenuItem>
        <MenuItem value="CLOSED">CLOSED</MenuItem>
      </GridSelect>
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remark/Status: '}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.remarks || ''}
        onChange={handleCustomerData}
      />
      <div>
        <Checkbox
          name="exclude_report"
          checked={data?.exclude_report}
          onChange={(e) => {
            setData((prev: any) => ({
              ...prev,
              [e.target.name]: e.target.checked,
            }));
          }}
        />

        <span
          style={{
            fontWeight: '500',
            fontSize: '20px',
          }}
        >
          Exclude from report
        </span>
      </div>
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
      <ModalCustom
        openModal={isPartModal}
        closeModal={() => {
          setIsPartModal(false);
        }}
        title="PART"
      >
        <PartTable moduleData={data} />
      </ModalCustom>
    </>
  );
};

export default Create;
