import styled from 'styled-components';

export const StyledTableHeaderTh1: any = styled.th`
  position: sticky;
  border: 1px solid black;
  z-index: 1;
  height: 50px;
  background-color: ${(props: any) =>
    props?.bgColor ? props?.bgColor : '#4f81bd'};
  color: ${(props: any) => (props?.color ? props?.color : '#000000')};
  left: 0;
  width: 50px;
  min-width: 50px;
`;
export const StyledTableHeaderTh2: any = styled.th`
  position: sticky;
  border: 1px solid black;
  z-index: 1;
  height: 50px;
  background-color: ${(props: any) =>
    props?.bgColor ? props?.bgColor : '#4f81bd'};
  color: ${(props: any) => (props?.color ? props?.color : '#000000')};
  left: 50px;
  width: 200px;
  min-width: 200px;
`;
export const StyledTableHeaderTh3: any = styled.th`
  position: sticky;
  border: 1px solid black;
  z-index: 1;
  height: 50px;
  background-color: ${(props: any) =>
    props?.bgColor ? props?.bgColor : '#4f81bd'};
  color: ${(props: any) => (props?.color ? props?.color : '#000000')};
  left: 250px;
  width: 200px;
  min-width: 200px;
`;
export const StyledTableHeaderTh4: any = styled.th`
  position: sticky;
  border: 1px solid black;
  z-index: 1;
  height: 50px;
  background-color: ${(props: any) =>
    props?.bgColor ? props?.bgColor : '#4f81bd'};
  color: ${(props: any) => (props?.color ? props?.color : '#000000')};
  left: 450px;
  width: 200px;
  min-width: 200px;
`;

export const StyledTableBodyTh1: any = styled.th`
  position: sticky;
  border: 1px solid black;
  z-index: 1;
  height: 50px;
  background-color: ${(props: any) =>
    props?.bgColor ? props?.bgColor : '#4f81bd'};
  color: ${(props: any) => (props?.color ? props?.color : '#000000')};
  left: 0;
  width: 50px;
  min-width: 50px;
`;
export const StyledTableBodyTh2: any = styled.th`
  position: sticky;
  border: 1px solid black;
  z-index: 1;
  height: 50px;
  background-color: ${(props: any) =>
    props?.bgColor ? props?.bgColor : '#4f81bd'};
  color: ${(props: any) => (props?.color ? props?.color : '#000000')};
  left: 50px;
  width: 200px;
  min-width: 200px;
`;
export const StyledTableBodyTh3: any = styled.th`
  position: sticky;
  border: 1px solid black;
  z-index: 1;
  height: 50px;
  background-color: ${(props: any) =>
    props?.bgColor ? props?.bgColor : '#4f81bd'};
  color: ${(props: any) => (props?.color ? props?.color : '#000000')};
  left: 250px;
  width: 200px;
  min-width: 200px;
`;
export const StyledTableBodyTh4: any = styled.th`
  position: sticky;
  border: 1px solid black;
  z-index: 1;
  height: 50px;
  background-color: ${(props: any) =>
    props?.bgColor ? props?.bgColor : '#4f81bd'};
  color: ${(props: any) => (props?.color ? props?.color : '#000000')};
  left: 450px;
  width: 200px;
  min-width: 200px;
`;
