import TableCompHead from '../../../../../components/ui/TableCompHead';
import { StyledTableHeaderRow } from '../../../../../components/ui/tableFreeze/RowFreeze.styled';

const head1 = [
  'JOB. NO.',
  'PART NAME',
  '%JOB DESCRIPTION',
  'PLANNED QTY',
  'ACTUAL QTY',
  'STROKE/MIN',
  'TOTAL TIME REQUIRED IN MINS FOR JOB COMPLETION',
  'TOTAL DOWNTIME',
  'START DATE',
  'PREDICTED COMPLETION DATE',
  'ACTUAL COMPLETION DATE & TIME',
  'DAYWISE SUMMARY',
  'ACTION',
];

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '100px',
};
const THead = () => {
  return (
    <thead>
      <TableCompHead
        colSpan={26}
        rowSpan={4}
        labels={[]}
        values={[]}
        heading="Planning Dashboard"
      />
      <StyledTableHeaderRow>
        {head1.map((heading, index) => (
          <th key={heading + index} rowSpan={2} style={{ ...style1 }}>
            {heading}
          </th>
        ))}
      </StyledTableHeaderRow>
    </thead>
  );
};

export default THead;
