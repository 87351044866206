import { Button, Paper, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CloseButton,
  CustomButton,
  SaveButton,
  SubmitButton,
} from '../../../../components/common/button/CustomButtons';
import formatDate from '../../../../components/common/formatDate';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { useQuery } from '../../../../hooks/UseQuery';
import { useAuth } from '../../../../services/auth/AuthProvider';
import TController from './components/create/TController';
import useGetData from './helpers/useGetData';
import { apiConstant, createConstant } from './constant';
import { GoSync } from 'react-icons/go';
import useConfirm from '../../../../components/common/useConfirm';
import { Loader } from '../../../../components/common/Loader';
import lodash from 'lodash';
// import BarLineChart from './components/Graph/BarLineChart';

const maintenanceObj = {
  april: {
    p: false,
    a: [],
  },
  may: {
    p: false,
    a: [],
  },
  june: {
    p: false,
    a: [],
  },
  july: {
    p: false,
    a: [],
  },
  august: {
    p: false,
    a: [],
  },
  september: {
    p: false,
    a: [],
  },
  october: {
    p: false,
    a: [],
  },
  november: {
    p: false,
    a: [],
  },
  december: {
    p: false,
    a: [],
  },
  january: {
    p: false,
    a: [],
  },
  february: {
    p: false,
    a: [],
  },
  march: {
    p: false,
    a: [],
  },
};

interface MyData {
  moduleData: any;
  Sync: any;
  isLoading: boolean;
  error?: string | null;
  handelSync: any;
}

const Create = () => {
  const { id } = useParams();
  const date = useQuery('date');
  const { state } = useAuth();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [DialogSave, confirmSave] = useConfirm(
    'SAVE',
    'Are you sure you want to Update this?'
  );
  // const { /*moduleData isLoading, error,*/ handelSync, Sync }: MyData =
  //   useGetData(id);
  const [moduleData, setModuleData] = useState<any>([{}]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [DialogClose, confirmClose] = useConfirm(
    'CLOSE',
    'Are you sure you want to leave this page?'
  );
  const [DialogAddTask, confirmDialogAddTask] = useConfirm(
    'Add Task',
    'Are you sure you want to add a Task?'
  );
  const [DialogRemoveTask, confirmDialogRemoveTask] = useConfirm(
    'Remove Task',
    'Are you sure you want to remove a Task?'
  );
  const [DialogSyncMachine, confirmDialogSyncMachine] = useConfirm(
    'Sync Machine',
    'Are you sure you want to Sync new Machines?'
  );
  const [DialogUpdate, confirmDialogUpdate] = useConfirm(
    'Update Plan',
    'Are you sure you want to update this plan?'
  );
  const [render, setRender] = useState(false);

  useEffect(() => {
    console.log('gjjjjjjjjj');
    setIsLoading(true);
    if (id) {
      axios
        .get(`/api/preventiveMaintenance/getPreventiveById/${id}`) // but selected partid why in iai secure?
        // .get(`/api/${apiConstant.getPlanByid}/${id}`)
        .then((res) => {
          setModuleData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, []);

  const onUpdate = async (isSubmitted: boolean) => {
    // moduleData.isSubmitted = isSubmitted;
    // moduleData.report_prepared_by = state.user?.name;
    // const ans = await confirmSave();
    const ans = await confirmDialogUpdate();
    if (!ans) return;

    axios
      .put(`/api/preventiveMaintenance/updatePreventive/${id}`, moduleData)
      .then((res) => alert('updated successfully '))
      .then(() => {
        setIsLoading(false);
        navigate(-1);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleSyncMachines = async () => {
    let ans = await confirmDialogSyncMachine();
    if (!ans) return;
    setIsLoading(true);
    let machines_id: any = {};
    moduleData.maintenance_list?.forEach((machine: any) => {
      let machine_id;
      if (machine.machine_name) {
        machine_id = machine.machine_id;
      } else {
        machine_id = machine.machine_id._id;
      }
      machines_id[machine_id] = machine_id;
    });
    axios
      .get(`/api/machineRoutes/machine/ForAutocomplete`)
      .then((res) => {
        const timer = setTimeout(() => {
          setIsLoading(false);
          window.alert('successfully updated');
        }, 1000);
        const response = res.data;
        response.forEach((item: any) => {
          if (!(item._id in machines_id)) {
            moduleData.maintenance_list.push({
              machine_no: item.machine_no,
              machine_name: item.machine_name,
              area: item.area,
              machine_id: item._id,
              ...lodash.cloneDeep(maintenanceObj),
            });
          }
        });
        setRender((prev) => !prev);
      })
      .catch((err) => {
        const timer = setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        console.log(err);
      });
  };

  const handleDoubleClick = async (date: any, month: any, idx: any) => {
    if (date) {
      let ans = await confirmDialogAddTask();
      if (ans) {
        moduleData.maintenance_list[idx][month].p = true;
        moduleData.maintenance_list[idx][month].created_date = date;
        setRender((prev) => !prev);
      }
    } else {
      let ans = await confirmDialogRemoveTask();
      if (ans) {
        moduleData.maintenance_list[idx][month].p = false;
        moduleData.maintenance_list[idx][month].created_date = date;
        moduleData.maintenance_list[idx][month].isTask = false;
        delete moduleData.maintenance_list[idx][month].doneDate;
        setRender((prev) => !prev);
      }
    }
  };
  console.log('hhhhhhhhhhhhhhh', moduleData);
  return (
    <Box>
      <Box>
        <Loader loader={isLoading} />
        <ChildHeader text={`${createConstant.childHeader}`}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            size="large"
            style={{
              backgroundColor: '#1864ab',
              marginRight: '0.5rem',
            }}
            startIcon={<GoSync />}
            onClick={() => {
              handleSyncMachines(); // handleSyncMachines(true);
            }}
          >
            Synch Machines
          </Button>
          <SubmitButton label="Update" onClick={() => onUpdate(true)} />
          <CloseButton
            onClick={async () => {
              let ans = await confirmClose();
              if (!ans) return;
              navigate(-1);
            }}
            label="Close"
          />
        </ChildHeader>
      </Box>
      <TController {...{ moduleData, isView, handleDoubleClick }} />
      {/* <Box style={{ paddingBottom: '5rem', width: '50vw' }}>
        <BarLineChart moduleData={moduleData} />
      </Box> */}
      <DialogClose isSubmitContent={false} />
      <DialogAddTask isSubmitContent={false} />
      <DialogRemoveTask isSubmitContent={false} />
      <DialogSyncMachine isSubmitContent={false} />
      {/* <DialogSave isSubmitContent={false} /> */}
      <DialogUpdate isSubmitContent={false} />
    </Box>
  );
};

export default Create;
