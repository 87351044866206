export const listConstant = {
  childHeader: 'PRODUCT WISE AUDIT',
  addButtonLable: 'NEW PRODUCT WISE AUDIT',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PRODUCT WISE AUDIT',
};

export const createConstant = {
  childHeader: 'PRODUCT WISE AUDIT PLAN',
  theadHeader: 'PRODUCT WISE AUDIT PLAN',
  extracolSpan: 5,
};

export const checkListList = {
  childHeader: 'PRODUCT WISE AUDIT PLAN STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'annualpmplanProductWiseRoute',
  gettableDataurl: 'annualpmplanProductWiseRoute/monthly',
  getPlanByid: 'annualpmplanProductWiseRoute',
  createPlanByid: 'annualpmplanProductWiseRoute',
  updatePlanByid: 'annualpmplanProductWiseRoute',
  sync: 'annualpmplanProductWiseRoute/handleSync',
  getAllCheckList: 'annualpmplanProductWiseRoute/getAllCheckList',
  checkListStatus: 'annualpmplanProductWiseRoute/handleStatus',
  checklistUpload: 'annualpmplanProductWiseRoute/handleUpload',
  checklistDeleteImage: 'annualpmplanProductWiseRoute/handleDeleteImage',
  checklistData: 'annualpmplanProductWiseRoute/checklistRoute',
};

export const navigationConstant = {
  createPlanSchedule: '/common/annualPmPlanUtilityCreate',
  createChecklist: '/common/annualPmPlanChecklistCreate',
};
