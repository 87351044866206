import {
  Box,
  MenuItem,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { usePermission } from '../../../../utils/helpers/usePermission';
import {
  CustomPagination,
  PersitedDatePicker,
  TableCustom,
} from '../../../../components/common';
import {
  AddButton,
  UpdateButton,
} from '../../../../components/common/button/CustomButtons';
import ModalCustom from '../../../../components/common/Modal';
import { SupervisorPartTable } from '../../../../components/ui';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { useQuery } from '../../../../hooks/UseQuery';
import { useAuth } from '../../../../services/auth/AuthProvider';
import ModalInput from './ModalInput';
import { listConstant, apiConstant, navigationConstant } from './constant';
import React from 'react';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../../../components/common/button/CustomIconButton';
import { useNavigate } from 'react-router-dom';
import CustomSelect from '../../../../components/common/SelectField';

const getYearRange = (currentDate: any) => {
  if (!currentDate) return;
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Title', rowspan: 1, colspan: 1 },
    { text: 'Date Range', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const TableList = () => {
  const { state } = useAuth();
  const { user } = state;
  const date = useQuery('date');
  const [tableData, setTableData] = useState<any[]>([{}]);
  const [listId, setListId] = useState('');
  const [render, setRender] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const navigate = useNavigate();
  const permissions = usePermission(4);
  useEffect(() => {
    axios
      .get(`/api/${apiConstant.gettableDataurl}/${date}`)
      .then((res) => {
        if (res.data) setTableData(res.data);
      })
      .catch((err) => console.log(err));
  }, [render, date, isOpenPopup]);

  const handleDelete = async (id: string) => {
    let isDeleted = window.confirm(
      'Are you sure you want to delete this document?'
    );
    if (isDeleted) {
      axios
        .delete(`/api/${apiConstant.deleteurl}/${id}`)
        .then(() => {
          alert('document deleted successfully');
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
  };

  return (
    <Box>
      <ChildHeader text={`${listConstant.childHeader}`}>
        {permissions?.includes('edit') && (
          <AddButton
            label={`${listConstant.addButtonLable}`}
            onClick={() => setIsOpenPopup(true)}
          />
        )}
      </ChildHeader>
      <Box sx={{ margin: 2 }}>
        <PersitedDatePicker
          label={`${listConstant.datePickerLabel}`}
          views={['year']}
        />
      </Box>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {tableData &&
            tableData.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{item?.title}</TableCell>
                  <TableCell align="center">
                    {getYearRange(item.date_range)}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <ViewIconButton
                        tooltipTitle="create plan"
                        onClick={() => {
                          navigate(
                            `${navigationConstant.createPlanSchedule}/${
                              item?._id
                            }?yearRange=${getYearRange(item?.date_range)}`
                          );
                        }}
                      />
                      {permissions.includes('edit') && (
                        <EditIconButton
                          tooltipTitle="EDIT"
                          onClick={() => {
                            setListId(item?._id || '');
                            setIsOpenPopup(true);
                          }}
                        />
                      )}
                      {permissions.includes('delete') && (
                        <DeleteIconButton
                          tooltipTitle="DELETE"
                          onClick={() => {
                            handleDelete(item?._id || '');
                          }}
                        />
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <ModalCustom
        title={`${listConstant.modalTile}`}
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
          setListId('');
        }}
      >
        <ModalInput
          setIsModal={setIsOpenPopup}
          id={listId}
          setListId={setListId}
        />
      </ModalCustom>
    </Box>
  );
};

export default TableList;
