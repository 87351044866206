import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
import { createConstant } from '../../constant';
import { useQuery } from '../../../../hooks/UseQuery';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '200px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};
interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const yearRange = useQuery('yearRange');
  const date = new Date(moduleData?.date_range)
    ?.getFullYear()
    .toString()
    .slice(-2);
  return (
    <thead>
      <TableCompHead
        colSpan={14 + createConstant.extracolSpan}
        rowSpan={4}
        labels={[
          'FORMAT NO: ',
          'ISSUE DATE: ',
          'REVISION NO: ',
          'REVISION DATE: ',
        ]}
        values={['F APQP 30A', '15/02/2021', '02', '01/03/2022']}
        heading={`${createConstant.theadHeader}  - ANNUAL (${yearRange})`}
      />

      {[
        'S. NO.',
        'PART NAME',
        'PART NO.',
        'CRITICAL DIMENSION',
        'PLAN/ACTUAL',
      ].map((el, index) => (
        <th
          key={`${index}_head`}
          align="center"
          style={{
            textAlign: 'center',
            ...style1,
            minWidth: `${(el === 'S. No.' && '50px') || style1.minWidth}`,
          }}
        >
          {el}
        </th>
      ))}

      {[
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC',
        'JAN',
        'FEB',
        'MAR',
      ].map((el, index) => (
        <th
          key={`${index}_headcol`}
          align="center"
          style={{
            textAlign: 'center',
            ...style2,
          }}
        >
          {el}
          {/* -{index > 8 ? +date + 1 : date} */}
        </th>
      ))}
      <th
        style={{ textAlign: 'center', ...style1, minWidth: '250px' }}
        colSpan={2}
      >
        Remarks
      </th>
    </thead>
  );
};

export default THead;
