import { RenderTiles } from '../../components/common';
import { VscServerProcess } from 'react-icons/vsc';
import { FcInspection } from 'react-icons/fc';
import {
  MdApproval,
  MdComputer,
  MdFeaturedPlayList,
  MdSupervisorAccount,
} from 'react-icons/md';
import { TbFileReport, TbReport } from 'react-icons/tb';
// import { TbReportAnalytics } from 'react-icons/tb';
// import { HiDocumentReport } from 'react-icons/hi';
// import { RiDeleteBin4Fill } from 'react-icons/ri';
import { CgCalendarToday } from 'react-icons/cg';
import { TbNotebook } from 'react-icons/tb';

const supervisorTilesObj: {
  sNo: number;
  link: string;
  isLink: boolean;
  isIcon: boolean;
  clauseNo?: string | number | undefined;
  p: string;
  span: string;
  icon?: any;
  red?: string | undefined;
  target?: string | undefined;
  style?: {} | undefined;
}[] = [
  // {
  //   sNo: 5,
  //   link: '/supervisor/clause5',
  //   isLink: true,
  //   clauseNo: 5,
  //   p: 'EDUCATION AND',
  //   span: 'TRAINING',
  //   isIcon: false,
  // },
  // {
  //   sNo: 1,
  //   link: '/supervisor/pirList',
  //   isLink: true,
  //   icon: <VscServerProcess size="5rem" color="#adb5bd" />,
  //   p: 'LINE',
  //   span: 'INSPECTION OLD',
  //   isIcon: true,
  // },
  // {
  //   sNo: 1,
  //   link: '/supervisor/lineInspectionList',
  //   isLink: true,
  //   icon: <VscServerProcess size="5rem" color="#a12222" />,
  //   p: 'LINE',
  //   span: 'INSPECTION',
  //   isIcon: true,
  // },
  // {
  //   sNo: 1,
  //   link: '/supervisor/pdirList',
  //   isLink: true,
  //   icon: <TbReport size="5rem" color="#a12222" />,
  //   p: 'FINAL',
  //   span: 'INSPECTION',
  //   isIcon: true,
  // },
  // {
  //   sNo: 1,
  //   link: '/supervisor/setupList',
  //   isLink: true,
  //   icon: <MdApproval size="5rem" color="#a12222" />,
  //   p: 'FPA/',
  //   span: 'LPA',
  //   isIcon: true,
  // },
  // {
  //   sNo: 1,
  //   link: '/supervisor/materialIncomingList',
  //   isLink: true,
  //   icon: <FcInspection size="5rem" color="#a12222" />,
  //   p: 'MATERIAL INCOMING',
  //   span: 'INSPECTION',
  //   isIcon: true,
  // },
  // {
  //   sNo: 2,
  //   link: '/common/production',
  //   isLink: true,
  //   icon: <CgCalendarToday size="5rem" color="#212529" />,
  //   p: 'PRODUCTION',
  //   span: 'MANAGEMENT',
  //   isIcon: true,
  // },
  // {
  //   sNo: 5,
  //   link: 'https://uc.secure.aqua.mushinlabs.com',
  //   target: '_blank',
  //   isLink: true,
  //   clauseNo: 3,
  //   icon: <MdSupervisorAccount size="5rem" color="#a12222" />,
  //   p: '4M',
  //   span: 'MANAGEMENT',
  //   isIcon: true,
  // },
  // {
  //   sNo: 10,
  //   link: '/common/abnormalityLogBookList',
  //   isLink: true,
  //   icon: <TbNotebook size="5rem" color="#a12222" />,
  //   p: 'ABNORMALITY',
  //   span: 'LOG BOOK',
  //   isIcon: true,
  // },
  // {
  //   sNo: 6,
  //   link: 'https://uc.secure.aqua.mushinlabs.com',
  //   isLink: true,
  //   clauseNo: 3,
  //   icon: <MdFeaturedPlayList size="5rem" color="#135089" />,
  //   p: '4M MONITERNING',
  //   span: 'REPORTS',
  //   isIcon: true,
  // },
  // {
  //   sNo: 9,
  //   link: 'https://uc.secure.aqua.mushinlabs.com',
  //   isLink: true,
  //   clauseNo: 3,
  //   icon: <MdComputer size="5rem" color="#a12222" />,
  //   p: 'CUSTOMER COMPLAINT',
  //   span: 'TRACKER',
  //   isIcon: true,
  // },
  // {
  //   sNo: 10,
  //   link: 'https://uc.secure.aqua.mushinlabs.com',
  //   isLink: true,
  //   clauseNo: 3,
  //   icon: <MdSupervisorAccount size="5rem" color="#a12222" />,
  //   p: 'ABNORMALITY REQUEST',
  //   span: 'FORM',
  //   isIcon: true,
  // },
  // {
  //   sNo: 11,
  //   link: 'https://uc.secure.aqua.mushinlabs.com',
  //   isLink: true,
  //   clauseNo: 3,
  //   icon: <MdFeaturedPlayList size="5rem" color="#135089" />,
  //   p: 'ABNORMALITY  MONITERNING',
  //   span: 'REPORTS',
  //   isIcon: true,
  // },
];

const SupervisorHome = () => {
  return <RenderTiles tilesObj={supervisorTilesObj} height="100vh" />;
};

export default SupervisorHome;
