import React, { useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';

import {
  AddButton,
  CustomButton,
} from '../../components/common/button/CustomButtons';
import ChildHeader from '../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../components/common/FilterBy';
import { SearchBar } from '../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../components/common';
import useDebounce from '../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../components/common/Modal';
import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';

import Profile from './components/Profile';
import { AiOutlineDownload } from 'react-icons/ai';
import CustomUploadButton from '../../components/common/button/CustomUploadButton';
import { Loader } from '../../components/common/Loader';
import formatDate from '../../components/common/formatDate';
import { usePermission } from '../../utils/helpers/usePermission';

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'EMPLOYEE NAME', rowspan: 1, colspan: 1 },
    { text: 'EMPLOYEE CODE', rowspan: 1, colspan: 1 },
    { text: 'DESIGNATION', rowspan: 1, colspan: 1 },
    { text: 'DEPARTMENT', rowspan: 1, colspan: 1 },
    { text: 'SUB-DEPARTMENT', rowspan: 1, colspan: 1 },
    { text: 'DOJ', rowspan: 1, colspan: 1 },
    { text: 'QUALIFICATION', rowspan: 1, colspan: 1 },
    { text: 'SKILL LEVEL', rowspan: 1, colspan: 1 },
    { text: 'EMPLOYEE TYPE', rowspan: 1, colspan: 1 },
    { text: 'PROFILE', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [{ value: 'createdAt', name: 'Most Recent First' }];
const inductionFormUrl: { [key: string]: string } = {
  Staff:
    'https://mushin-labs.s3.ap-south-1.amazonaws.com/united-cores/misc/Induction+Form+-+Staff.pdf',
  'Operator-Helper':
    'https://mushin-labs.s3.ap-south-1.amazonaws.com/united-cores/misc/Induction+Form+-+Operator.pdf',
};

const List = () => {
  const [data, setData] = useState<any[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [moduleId, setModuleId] = useState<string | null>(null);
  const [isProfileModal, setIsProfileModal] = useState(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [profile, setProfile] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const permissions = usePermission(31);
  const search = useQuery('search');
  const filter = useQuery('filter');
  const page = useQuery('page') || 1;

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE Employee',
    'Are you sure you want to delete this?',
  );
  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/employee?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}`,
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };
  useDebounce(fetchData, 500, [page, search, render, isModal]);

  const deleteModuleById = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/employee/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  const uploadProfile = async (e: any, id: any) => {
    if (!window.confirm('Are you sure you want upload this profile')) return;
    const files = e.target.files[0];
    if (!files) return;
    let formData = new FormData();
    formData.append('profile', files);
    setIsLoading(true);
    e.target.value = '';
    axios
      .patch(`/api/employee/uploadProfile/${id}`, formData)
      .then((res) => {
        if (res.data) {
          alert('document upload successly');
          setIsLoading(false);
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const deleteProfile = async (id: any) => {
    if (!window.confirm('Are you sure you want delete this profile')) return;
    await axios
      .put(`/api/employee/${id}`, { profile: null })
      .then((res) => {
        if (res.data) {
          alert('document delete successly');
          setRender((prev) => !prev);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Loader loader={isLoading} />
      <ChildHeader text="EMPLOYEE LIST">
        {permissions.includes('edit') && (
          <AddButton onClick={() => setIsModal(true)} label="New Employee" />
        )}
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <FilterBy options={filterOptions} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item?.employee_name}</TableCell>
                  <TableCell align="center">{item?.employee_code}</TableCell>
                  <TableCell align="center">
                    {item?.designation_id?.designation}
                  </TableCell>
                  <TableCell align="center">
                    {item?.department_id?.department_name}
                  </TableCell>
                  <TableCell align="center">
                    {item?.sub_department_id?.name}
                  </TableCell>
                  <TableCell align="center">{formatDate(item?.doj)}</TableCell>
                  <TableCell align="center">
                    {item?.qualification_id?.name}
                  </TableCell>
                  <TableCell align="center">
                    {item?.skill_level_id?.name}
                  </TableCell>
                  <TableCell align="center">
                    {item?.employee_type_id?.name}
                  </TableCell>
                  <TableCell align="center">
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      {permissions.includes('edit') && (
                        <CustomUploadButton
                          accept="image/png,image/jpeg,image/jpg"
                          id={`${index}_files_`}
                          multiple={true}
                          onChange={(e) => {
                            uploadProfile(e, item._id);
                          }}
                        />
                      )}
                      {item.profile && (
                        <>
                          <ViewIconButton
                            tooltipTitle="VIEW PROFILE"
                            onClick={() => {
                              setIsProfileModal(true);
                              setProfile(item?.profile);
                            }}
                          />
                          {permissions.includes('delete') && (
                            <DeleteIconButton
                              tooltipTitle="DELETE PROFILE"
                              onClick={() => {
                                deleteProfile(item._id);
                              }}
                            />
                          )}
                        </>
                      )}
                    </Stack>
                  </TableCell>

                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      {permissions.includes('edit') && (
                        <EditIconButton
                          tooltipTitle="EDIT"
                          onClick={() => {
                            setModuleId(item?._id || '');
                            setIsModal(true);
                          }}
                        />
                      )}
                      {permissions.includes('delete') && (
                        <DeleteIconButton
                          tooltipTitle="DELETE"
                          onClick={() => {
                            deleteModuleById(item?._id || '');
                          }}
                        />
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setModuleId(null);
          setIsModal(false);
        }}
        title="CREATE NEW EMPLOYEE"
      >
        <Create id={moduleId} setIsModal={setIsModal} />
      </ModalCustom>
      <ModalCustom
        openModal={isProfileModal}
        closeModal={() => {
          setProfile('');
          setIsProfileModal(false);
        }}
        title="PROFILE"
      >
        <Profile profileUrl={profile} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
