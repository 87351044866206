import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { AiOutlineUnorderedList } from 'react-icons/ai';
export type tilesType = {
  sNo: number;
  link: string;
  isLink: boolean;
  isIcon: boolean;
  clauseNo?: string | number;
  p: string;
  span: string;
  icon?: any;
  style?: {};
}[];

const tiles = [
  {
    sNo: 2,
    link: '/common/masterlistofdepartment?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    p: 'Master List Of',
    span: 'Department',
    icon: <AiOutlineUnorderedList size="5rem" color="#343a40" />,
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/masterlistofsubdepartment?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    p: 'Master List Of',
    span: 'Sub Department',
    icon: <AiOutlineUnorderedList size="5rem" color="#343a40" />,
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/common/masterListDesignation?filter=createdAt',
    isLink: true,
    p: 'MASTER LIST OF',
    span: 'DESIGNATION',
    isIcon: true,
    icon: <AiOutlineUnorderedList size="5rem" color="#135089" />,
  },
  {
    sNo: 5,
    link: '/common/masterListOfQualification?filter=createdAt',
    isLink: true,
    p: 'MASTER LIST OF',
    span: 'QUALIFICATION',
    isIcon: true,
    icon: <AiOutlineUnorderedList size="5rem" color="#135089" />,
  },
  {
    sNo: 6,
    link: '/common/masterListOfSkillLevel?filter=createdAt',
    isLink: true,
    p: 'MASTER LIST OF',
    span: 'SKILL LEVEL',
    isIcon: true,
    icon: <AiOutlineUnorderedList size="5rem" color="#135089" />,
  },
  {
    sNo: 7,
    link: '/common/masterListOfEmployeeType?filter=createdAt',
    isLink: true,
    p: 'MASTER LIST OF',
    span: 'EMPLOYEE TYPE',
    isIcon: true,
    icon: <AiOutlineUnorderedList size="5rem" color="#135089" />,
  },
];

const MushinAquaMastersTiles = () => {
  return (
    <>
      <ChildHeader text="MUSHIN AQUA MASTERS" />
      <RenderTiles tilesObj={tiles} height="100vh" />
    </>
  );
};

export default MushinAquaMastersTiles;
