export const listConstant = {
  childHeader: 'MACHINE WISE AUDIT',
  addButtonLable: 'NEW MACHINE WISE AUDIT',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW MACHINE WISE AUDIT',
};

export const createConstant = {
  childHeader: 'MACHINE WISE AUDIT PLAN',
  theadHeader: 'MACHINE WISE AUDIT PLAN',
  extracolSpan: 4,
};

export const checkListList = {
  childHeader: 'MACHINE WISE AUDIT PLAN STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'annualpmplanMachineWiseRoute',
  gettableDataurl: 'annualpmplanMachineWiseRoute/monthly',
  getPlanByid: 'annualpmplanMachineWiseRoute',
  createPlanByid: 'annualpmplanMachineWiseRoute',
  updatePlanByid: 'annualpmplanMachineWiseRoute',
  sync: 'annualpmplanMachineWiseRoute/handleSync',
  getAllCheckList: 'annualpmplanMachineWiseRoute/getAllCheckList',
  checkListStatus: 'annualpmplanMachineWiseRoute/handleStatus',
  checklistUpload: 'annualpmplanMachineWiseRoute/handleUpload',
  checklistDeleteImage: 'annualpmplanMachineWiseRoute/handleDeleteImage',
  checklistData: 'annualpmplanMachineWiseRoute/checklistRoute',
};

export const navigationConstant = {
  createPlanSchedule: '/common/annualPmPlanMachineWiseCreate',
  createChecklist: '/common/annualPmPlanChecklistMachineWiseCreate',
};
