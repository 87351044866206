import React, { useState, useEffect } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { AddButton } from '../../components/common/button/CustomButtons';
import ChildHeader from '../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../components/common/FilterBy';
import { SearchBar } from '../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../components/common';
import useDebounce from '../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
} from '../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../components/common/Modal';
import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';
import CategoryList from './CategoryList';
import ToolItemCodeList from './ToolItemCodeList';
import formatDate from '../../components/common/formatDate';
import {
  StyledTableCell,
  StyledTableRow,
} from '../../components/ui/module.styled';
import CustomUploadButton from '../../components/common/button/CustomUploadButton';
const style1 = {
  backgroundColor: '#4F81BD',
  // backgroundColor: '#00B050',
  color: '#fff',
  minWidth: '80px',
  border: '1px solid #343a40',
};
const tableHeaderObj = [
  [
    { text: 'S.NO.', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'Item No.', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'Serial Number', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'ITEM DESCRIPTION', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'Admission Date', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'STATUS', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'ACTION', rowspan: 1, colspan: 1, style: { ...style1 } },
  ],
];

const List = () => {
  const [data, setData] = useState<any[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [listId, setListId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const search = useQuery('search') || '';
  const category = useQuery('category');
  const statusFilter = useQuery('statusFilter');
  const page = useQuery('page') || 1;
  const [categoryModal, setCategoryModal] = useState(false);
  const [toolItemModal, setToolItemModal] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);

  const [categoryData, setCategoryData] = useState([]);
  const statusFilterData = [
    {
      name: 'Available',
      value: 'Available',
    },
    {
      name: 'Unavailable',
      value: 'Unavailable',
    },
  ];

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?'
  );

  useEffect(() => {
    axios
      .get(
        '/api/masterListOfToolRoute/toolItemCodeList/toolItemCodeForAutoComplete'
      )
      .then((res) => {
        if (res?.data) {
          const formattedData = res.data.map((item: any) => ({
            name: item.name,
            value: item.name,
          }));
          setCategoryData(formattedData);
        }
      })
      .catch((err) => console.log(err));
  }, [render]);

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/masterListOfToolRoute/?page=${page}&category=${category}&search=${search}&perPage=${perPage}&status=ACTIVE&statusFilter=${statusFilter}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.result);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 500, [
    page,
    search,
    render,
    isModal,
    category,
    statusFilter,
  ]);

  const handleDelete = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/masterListOfToolRoute/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  const handleImport = (e: any) => {
    setLoader(true);
    const formData = new FormData();
    formData.append('file', e.target.files[0], 'Employee List - Import.xlsx');
    axios
      .post('/api/masterListOfToolRoute/uploadList', formData)
      .then((res) => {
        setLoader(false);
        setRender((prev: any) => !prev);
        // window.alert(res.data.msg);
      })
      .catch((err) => {
        setLoader(false);
        if (err.response.data.err) {
          window.alert(err.response.data.err);
        } else {
          window.alert(
            'Something went wrong. Please check if you are uploading the correct excel sheet or the excel sheet might be corrupted.'
          );
        }
      });
  };

  return (
    <>
      <ChildHeader text="TOOL LIST">
        <CustomUploadButton
          lable={'Upload List'}
          onChange={(e) => handleImport(e)}
        />
        <AddButton
          onClick={() => setToolItemModal(true)}
          label="TOOL ITEM NO"
        />
        <AddButton onClick={() => setIsModal(true)} label="NEW TOOL" />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <Stack direction={'row'} gap={2} flex="1">
          <FilterBy
            sx={{ width: 200 }}
            name="category"
            label="Filter By Item No"
            options={categoryData}
          />
          <FilterBy
            sx={{ width: 200 }}
            name="statusFilter"
            label="Filter By Status"
            options={statusFilterData}
          />
        </Stack>
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <StyledTableRow index={index}>
                  <StyledTableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ textTransform: 'capitalize' }}
                  >
                    {item?.tool_item_id?.name}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ textTransform: 'capitalize' }}
                  >
                    {item?.tool_no}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ textTransform: 'capitalize' }}
                  >
                    {item?.tool_desc}
                  </StyledTableCell>

                  <StyledTableCell
                    align="center"
                    style={{ textTransform: 'capitalize' }}
                  >
                    {formatDate(item?.admission_date)}
                  </StyledTableCell>

                  <StyledTableCell
                    align="center"
                    style={{ textTransform: 'capitalize' }}
                  >
                    {item?.status}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setListId(item?._id || '');
                          setIsModal(true);
                        }}
                      />

                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          handleDelete(item?._id || '');
                        }}
                      />
                    </Stack>
                  </StyledTableCell>
                </StyledTableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setListId(null);
          setIsModal(false);
        }}
        title={listId ? 'UPDATE NEW TOOL' : 'CREATE NEW TOOL'}
      >
        <Create id={listId} setIsModal={setIsModal} setListId={setListId} />
      </ModalCustom>
      <ModalCustom
        openModal={toolItemModal}
        closeModal={() => {
          setToolItemModal(false);
        }}
        title="TOOL ITEM NO LIST"
      >
        <ToolItemCodeList />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
