import { SetStateAction, useState } from 'react';
import CustomSelect from '../../../../../components/common/SelectField';
import { Grid, MenuItem, Tooltip } from '@mui/material';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { TimePicker } from '@mui/x-date-pickers';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../../components/common/formatDate';
import { convToTime } from '../../../../../utils/helpers';
import CustomMobileTimePickerMui from '../../../../../components/common/CustomMobileTimePickerMui';
import Operator from '../../../components/Operator';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  autoCompleteMachineValue: any;
  handleSumbit: any;
}

const TBody = ({ autoCompleteMachineValue,handleSumbit,moduleData, isView, handelDeleteRows }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <tr key={index + 'module data'}>
              <td style={{ minWidth: '150px' }}>
                {isView ? (
                  formatDate(process.date)
                ) : (
                  <DatePickerMui
                    label=""
                    value={moment(process.date || null)}
                    onChange={(date) => {
                      process.date = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>
              <td style={{ minWidth: '120px' }}>
                {isView ? (
                  convToTime(process.time)
                ) : (
                  <CustomMobileTimePickerMui
                    value={process.time}
                    onChange={(date: any) => {
                      process.time = date;
                      setRender((prev) => !prev);
                    }}
                    ampm={true}
                    openTo="hours"
                    views={['hours', 'minutes']}
                  />
                )}
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="part_count"
                  type="number"
                  value={process.part_count}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="actual_production"
                  type="number"
                  value={process.actual_production}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="part_count_insert_after_changing"
                  type="number"
                  value={process.part_count_insert_after_changing}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="name_of_insert_tool"
                  type="text"
                  value={process.name_of_insert_tool}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="rework_qty"
                  type="number"
                  value={process.rework_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="rej_qty"
                  type="number"
                  value={process.rej_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '200px' }}>
                {autoCompleteMachineValue?._id && process.date && (
                  <Operator
                    onSubmit={handleSumbit}
                    field="operator"
                    machineId={autoCompleteMachineValue?._id}
                    dataObj={{
                      date: process.date,
                    }}
                    {...{ isView,process }}
                  />
                )}
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="rework"
                  type="number"
                  value={process.rework}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="insert_tool_change"
                  type="number"
                  value={process.insert_tool_change}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="inspection"
                  type="number"
                  value={process.inspection}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="setting"
                  type="number"
                  value={process.setting}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="power_cut"
                  type="number"
                  value={process.power_cut}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="machine_maintenance"
                  type="number"
                  value={process.machine_maintenance}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="no_feed"
                  type="number"
                  value={process.no_feed}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="no_operator"
                  type="number"
                  value={process.no_operator}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="no_material"
                  type="number"
                  value={process.no_material}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="machine_problem"
                  type="number"
                  value={process.machine_problem}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="rework_details"
                  type="number"
                  value={process.rework_details}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="rest_problems"
                  type="number"
                  value={process.rest_problems}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '80px' }} colSpan={isView ? 2 : 1}>
                <TableInput
                  isView={isView}
                  name="remarks"
                  type="text"
                  value={process.remarks}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {!isView && (
                <td style={{ minWidth: '100px' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
