export const checklistData = [
  {
    loot_at: 'POKA-YOKE ',
    look_for: '1) List of POKA-YOKE 2) System for POKA-YOKE varification',
    obs: '',
    status: '',
  },
  {
    loot_at: 'Customer complaint countermeasure.',
    look_for: '1) Countermeasure should be effective',
    obs: '',
    status: '',
  },
  {
    loot_at: 'In-house countermeasure.',
    look_for: '1) Countermeasure should be effective',
    obs: '',
    status: '',
  },
  {
    loot_at: 'Process & Product Audit Previous History Documents  ',
    look_for:
      '1) All Non Conformity are closed properly? ( Check 8D / CAPA ) raised during last audits ',
    obs: '',
    status: '',
  },
  {
    loot_at: 'Tool Identification',
    look_for: '1) Every tool Should be identify',
    obs: '',
    status: '',
  },
  {
    loot_at: 'Coherence System',
    look_for: '1) Coherence Between Documents for parts which to be audited ',
    obs: '',
    status: '',
  },
  {
    loot_at: 'Limit Sample',
    look_for:
      '1) Are OK Master Samples and First Off Samples avilable  on Machine or not ? 2) Set inspection or last quality inspected sample are retained on machine?',
    obs: '',
    status: '',
  },
  {
    loot_at: 'Operator working as per Standards.',
    look_for:
      '1) Operation standard is specified and operator work as per standard',
    obs: '',
    status: '',
  },
  {
    loot_at: 'Autonomous maintenance',
    look_for:
      '1) Daily checking of machine is specified and is implemented, and operator aware about that',
    obs: '',
    status: '',
  },
  {
    loot_at: 'Lux level',
    look_for: '1) Lux Level On Shop Floor & Machines ',
    obs: '',
    status: '',
  },
  {
    loot_at: 'Machine Safety ',
    look_for: '1) Safety Sensor in machine is working or Not ?',
    obs: '',
    status: '',
  },
  {
    loot_at: 'NC part control',
    look_for:
      '1) Avalibility of Red bin for rejection On Machine and records of rejection are maintained.',
    obs: '',
    status: '',
  },
  {
    loot_at: 'FIFO control',
    look_for:
      '1) Verify the Tag of material for FIFO & Tracibility from Raw material To Dispatched Invoice ( Is it ok or Not )?',
    obs: '',
    status: '',
  },
  {
    loot_at: 'Operator Fatique',
    look_for: '1) Should be Ok',
    obs: '',
    status: '',
  },
  {
    loot_at: 'Operator Skill Level',
    look_for: '1) Operator Skill Level should be atleast L2.',
    obs: '',
    status: '',
  },
  {
    loot_at: 'Drop Parts',
    look_for: '1) There is no dropping part from container',
    obs: '',
    status: '',
  },
  {
    loot_at: 'Tool Management',
    look_for:
      '1) Tool life defined and monitoring. 2) Tool inspection are in practice and recorded.',
    obs: '',
    status: '',
  },
  {
    loot_at: 'Safety Conditions',
    look_for:
      '1) Safety Guard on the M/C 2) Are Safety & PPE equipments using by Operator, Casuals & Inspectors. 3) Working environment is good',
    obs: '',
    status: '',
  },
];
