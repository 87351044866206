import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../../../hooks/UseQuery';
import { actionTakens, actionTakenTickMarks, reasons } from '../helpers/data';
import axios from 'axios';
import TBodyRow from './TBodyRow';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: (index: number) => void;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setPartIdx: React.Dispatch<React.SetStateAction<number>>;
  onSubmit: () => void;
}
function getReportValues(process: any, reportKey: string, field: string) {
  if (process.reason && process.action_taken) {
    if (
      actionTakenTickMarks[`${process?.reason}${process?.action_taken}`]?.[
        reportKey
      ]
    ) {
      return process[`${reportKey}_id`]?.[field];
    }
  }

  if (process.reason) {
    if (actionTakenTickMarks[`${process?.reason}`]?.[reportKey]) {
      return process[`${reportKey}_id`]?.[field];
    }
  }
}
const TBody = ({
  handelDeleteRows,
  moduleData,
  isView,
  setIsOpenPopup,
  onSubmit,
  setPartIdx,
}: IProps) => {
  const [render, setRender] = useState(false);
  const [machineData, setMachineData] = useState([]);
  const navigate = useNavigate();
  const annual_month = useQuery('annual_date') || '';
  const redirect = async (
    process: any,
    reportId: any,
    documentType: string,
    isViewInside: Boolean
  ) => {
    await onSubmit();
    if (reportId) {
      if (process.part_id?._id) {
        navigate(
          `/common/createncReport/${process.part_id?._id}/${process._id}/${reportId._id}?annual_date=${annual_month}&documentType=${documentType}&isView=${isViewInside}`
        );
        return;
      }
      navigate(
        `/common/createncReport/${process.part_id}/${process._id}/${reportId._id}?annual_date=${annual_month}&documentType=${documentType}&isView=${isViewInside}`
      );
      return;
    }
    if (process.part_id?._id) {
      navigate(
        `/common/createncReport/${process.part_id?._id}/${process._id}?annual_date=${annual_month}&documentType=${documentType}&isView=${isViewInside}`
      );
      return;
    }
    navigate(
      `/common/createncReport/${process.part_id}/${process._id}?annual_date=${annual_month}&documentType=${documentType}&isView=${isViewInside}`
    );
  };

  useEffect(() => {
    axios
      .get('/api/machineRoutes/machine/ForAutocomplete')
      .then((res) => {
        setMachineData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <tbody>
      {moduleData.processes.map((process: any, index: number) => (
        <TBodyRow
          {...{
            isView,
            process,
            machineData,
            handelDeleteRows,
            setIsOpenPopup,
            setPartIdx,
            index,
            getReportValues,
            redirect,
            moduleData,
          }}
        />
      ))}
    </tbody>
  );
};

export default TBody;
