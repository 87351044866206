import { SetStateAction, useState } from 'react';
import CustomSelect from '../../../../../components/common/SelectField';
import { Grid, MenuItem, Tooltip } from '@mui/material';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { TimePicker } from '@mui/x-date-pickers';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../../components/common/formatDate';
import { convToTime } from '../../../../../utils/helpers';
import CustomMobileTimePickerMui from '../../../../../components/common/CustomMobileTimePickerMui';
import Operator from '../../../components/Operator';
interface IProps {
  moduleData: any;
  isView: boolean;
  handleSumbit: any;
  machineData: any;
  autoCompleteMachineValue: any;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setPartIdx: any;
}

const TBody = ({
  moduleData,
  isView,
  handleSumbit,
  machineData,
  autoCompleteMachineValue,
  handelDeleteRows,
  isOpenPopup,
  setIsOpenPopup,
  setPartIdx,
}: IProps) => {
  const [render, setRender] = useState(false);

  // a and b is no of cavity and shots
  function calcTotalQty(
    a: number | null | undefined,
    b: number | null | undefined,
  ): number | null {
    if (a === null || b === null || a === undefined || b === undefined) {
      return null;
    }

    return a * b;
  }
  // a and b is no of cavity and shots and c is rejected qty
  function calcOkQty(
    a: number | null | undefined,
    b: number | null | undefined,
    c: number | null | undefined,
  ): number | null {
    if (
      a === null ||
      b === null ||
      c === null ||
      a === undefined ||
      b === undefined ||
      c === undefined
    ) {
      return null;
    }

    const multiplicationResult = a * b;
    const subtractionResult = multiplicationResult - c;

    return subtractionResult;
  }

  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <tr key={index + 'module data'}>
              <td style={{ minWidth: '150px' }}>
                {isView ? (
                  formatDate(process.date)
                ) : (
                  <DatePickerMui
                    label=""
                    value={moment(process.date)}
                    onChange={(date) => {
                      process.date = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>
              <td style={{ minWidth: '80px' }}>
                {isView ? (
                  process.shift
                ) : (
                  <CustomSelect
                    name="shift"
                    value={process.shift || ''}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem value="A">A</MenuItem>
                    <MenuItem value="B">B</MenuItem>=
                  </CustomSelect>
                )}
              </td>
              <td style={{ minWidth: '200px' }}>
                {isView
                  ? process?.mc_no?.machine_no
                  : process.date &&
                    process.part_id && (
                      <AutocompleteMuiCustom
                        id="Machine"
                        label=""
                        option_name="machine_no"
                        arrayofObj={machineData}
                        value={autoCompleteMachineValue[index]}
                        onChange={(e, value) => {
                          process.mc_no = value?._id;
                          autoCompleteMachineValue[index] = value;
                          setRender((prev) => !prev);
                        }}
                      />
                    )}
              </td>
              {/* {process.partNumber} */}
              <td style={{ width: '250px', minWidth: '250px' }}>
                {isView ? (
                  process?.part_id &&
                  process?.part_id?.part_name +
                    ' & ' +
                    process.part_id?.part_number
                ) : (
                  <Grid container xs={12} direction="row" alignItems={'center'}>
                    <Grid item xs={10}>
                      <p
                        style={{
                          fontSize: '1.2rem',
                        }}
                      >
                        {typeof process?.part_id === 'object' &&
                        process?.part_id !== null
                          ? process?.part_id?.part_name +
                            ' & ' +
                            process.part_id?.part_number
                          : (process?.partNumber &&
                              process?.partName +
                                ' & ' +
                                process?.partNumber) ||
                            ''}
                        {!process?.part_id && 'Pick Part No.'}
                      </p>
                    </Grid>

                    {process.part_id ? (
                      <Grid item xs={2}>
                        <RemoveIconButton
                          tooltipTitle="remove part"
                          onClick={() => {
                            process.part_id = null;
                            process.partNumber = null;
                            process.partName = null;
                            setRender((prev) => !prev);
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={2}>
                        <AddIconButton
                          tooltipTitle="add part"
                          onClick={() => {
                            setIsOpenPopup(true);
                            setPartIdx(index);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </td>
              <td style={{ minWidth: '80px' }}>
                {isView ? (
                  process.rotor_size
                ) : (
                  <CustomSelect
                    name="rotor_size"
                    value={process.rotor_size || null}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  >
                    {[
                      100, 110, 120, 80, 130, 140, 90, 70, 60, 105, 115, 85, 75,
                      65, 64, 55, 50, 45, 40, 35, 30, 24, 16, 20, 38, 34, 15,
                      18, 13, 8, 19, 25,
                    ]
                      .sort((a, b) => a - b)
                      .map((size, index) => (
                        <MenuItem value={size} key={index + ' ' + size}>
                          {size}
                        </MenuItem>
                      ))}
                  </CustomSelect>
                )}
              </td>
              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="batch_number"
                  type="text"
                  value={process.batch_number}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '200px' }}>
                {autoCompleteMachineValue[index]._id && process.date && (
                  <Operator
                    onSubmit={handleSumbit}
                    field="operator"
                    machineId={autoCompleteMachineValue[index]?._id}
                    subDepartmentId={
                      autoCompleteMachineValue[index]?.sub_department_id
                    }
                    dataObj={{
                      date: process.date,
                      part_id: process.part_id,
                    }}
                    {...{ isView, process }}
                  />
                )}
              </td>
              <td style={{ minWidth: '200px' }}>
                {autoCompleteMachineValue[index]._id && process.date && (
                  <Operator
                    onSubmit={handleSumbit}
                    field="operator_2"
                    machineId={autoCompleteMachineValue[index]?._id}
                    dataObj={{
                      date: process.date,
                      part_id: process.part_id,
                    }}
                    {...{ isView, process }}
                  />
                )}
              </td>
              <td style={{ minWidth: '110px' }}>
                {isView ? (
                  convToTime(process.time_start)
                ) : (
                  <CustomMobileTimePickerMui
                    value={process.time_start}
                    onChange={(date: any) => {
                      process.time_start = date;
                      setRender((prev) => !prev);
                    }}
                    ampm={true}
                    openTo="hours"
                    views={['hours', 'minutes']}
                  />
                )}
              </td>
              <td style={{ minWidth: '110px' }}>
                {isView ? (
                  convToTime(process.time_stop)
                ) : (
                  <CustomMobileTimePickerMui
                    value={process.time_stop}
                    onChange={(date: any) => {
                      process.time_stop = date;

                      setRender((prev) => !prev);
                    }}
                    ampm={true}
                    openTo="hours"
                    views={['hours', 'minutes']}
                  />
                )}
              </td>
              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="shot"
                  type="number"
                  value={process.shot}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '80px' }}>
                {isView ? (
                  process.no_of_cavity
                ) : (
                  <CustomSelect
                    name="no_of_cavity"
                    value={process.no_of_cavity || null}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                  </CustomSelect>
                )}
              </td>
              <td style={{ minWidth: '80px' }}>
                {calcTotalQty(process.no_of_cavity, process.shot)}
              </td>
              <td style={{ minWidth: '80px' }}>
                {calcOkQty(process.no_of_cavity, process.shot, process.rej_qty)}
              </td>
              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="rej_qty"
                  type="number"
                  value={process.rej_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="verified_sign"
                  type="text"
                  value={process.verified_sign}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '80px' }} colSpan={isView ? 2 : 1}>
                <TableInput
                  isView={isView}
                  name="remarks"
                  type="text"
                  value={process.remarks}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {!isView && (
                <td style={{ minWidth: '100px' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
