import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import THead from './THead';
interface IProps {
  moduleData: any;
  isView: boolean;
  machineData: any;
}

const TController = ({ moduleData, isView, machineData }: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
            machineData,
          }}
        />
        <TBody {...{ moduleData, machineData, isView }} />
      </RenderTable>
    </Box>
  );
};

export default TController;
