import { Clause5 } from '../pages/clause5page';
import SupervisorHome from '../pages/supervisorHome/';
import { other } from './supervisor/other';
// import { productions } from './supervisor/production';
import { supervisor } from './supervisor/supervisor';

export const IndexSupervisor = [
  {
    path: 'home',
    element: <SupervisorHome />,
  },
  {
    path: 'clause5',
    element: <Clause5 />,
  },
  ...supervisor,
  ...other,
  // ...productions,
];
