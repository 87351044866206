export const listConstant = {
  childHeader: 'MSA List',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'MSA List',
  theadHeader: 'MSA Plan',
  extracolSpan: 2,
};

export const checkListList = {
  childHeader: 'MSA STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'msaPlanRoute',
  gettableDataurl: 'msaPlanRoute/monthly',
  getPlanByid: 'msaPlanRoute',
  createPlanByid: 'msaPlanRoute',
  updatePlanByid: 'msaPlanRoute',
  sync: 'msaPlanRoute/handleSync',
  getAllCheckList: 'msaPlanRoute/getAllCheckList',
  checkListStatus: 'msaPlanRoute/handleStatus',
  checklistUpload: 'msaPlanRoute/handleUpload',
  checklistDeleteImage: 'msaPlanRoute/handleDeleteImage',
  checklistData: 'msaPlanRoute/checklistRoute',
  getMasterDataAutocomplete:
    'masterlistinstrument/instrumentList/instumentForAutoComplete',
};

export const tilesConstant = {
  childHeaderText: 'MSA Plan',
};

export const navigationConstant = {
  createPlanSchedule: '/common/msaplancreate',
  // createChecklist: '/common/supplierAuditPlanChecklistCreate',
};
