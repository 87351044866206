import { Box, Stack } from '@mui/system';

const Profile = ({ profileUrl }: { profileUrl: string }) => {
  return (
    <Box style={{ width: 'auto', height: '300px' }}>
      {profileUrl ? (
        <img height={'100%'} width="100%" src={profileUrl} />
      ) : (
        <Stack
          alignItems={'center'}
          justifyContent="center"
          height={'90%'}
          width={'100%'}
        >
          <p style={{ fontSize: '2rem', fontWeight: 600, textAlign: 'center' }}>
            No Profile Found
          </p>
        </Stack>
      )}
    </Box>
  );
};

export default Profile;
