import { SetStateAction, useState } from 'react';
import CustomSelect from '../../../../../components/common/SelectField';
import { Grid, MenuItem, Tooltip } from '@mui/material';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { TimePicker } from '@mui/x-date-pickers';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../../components/common/formatDate';
import { convToTime } from '../../../../../utils/helpers';
import CustomMobileTimePickerMui from '../../../../../components/common/CustomMobileTimePickerMui';
import Operator from '../../../components/Operator';

interface IProps {
  moduleData: any;
  isView: boolean;
  // handleSumbit: any;
  // machineData: any;
  supplierData: any;
  // employeeData: any;
  // autoCompleteMachineValue: any;
  // autoCompleteEmployeeValue: any;
  autoCompleteSupplierValue: any;
  handelDeleteRows: any;
  autoCompleteMachineValue: any;
  handleSumbit: any;
  // isOpenPopup: any;
  setIsOpenPopup: any;
  setPartIdx: any;
}

const TBody = ({
  moduleData,
  isView,
  // handleSumbit,
  supplierData,
  // employeeData,
  // autoCompleteEmployeeValue,
  handleSumbit,
  autoCompleteMachineValue,
  autoCompleteSupplierValue,
  handelDeleteRows,
  // isOpenPopup,
  setIsOpenPopup,
  setPartIdx,
}: IProps) => {
  const [render, setRender] = useState(false);

  // a ---> time start , b ---> time end , c ---> no of rows
  function calcTotalStrokes(
    a: number | null | undefined,
    b: number | null | undefined,
    c: number | null | undefined
  ): number | null {
    if (
      a === null ||
      b === null ||
      c === null ||
      a === undefined ||
      b === undefined ||
      c === undefined
    ) {
      return null;
    }

    return (b - a) * c;
  }

  // a ---> time start , b ---> time end , c ---> no of rows , d----> std. weight
  function calcProductionWeight(
    a: number | null | undefined,
    b: number | null | undefined,
    c: number | null | undefined,
    d: number | null | undefined
  ): number | null {
    if (
      a === null ||
      b === null ||
      c === null ||
      d === null ||
      a === undefined ||
      b === undefined ||
      c === undefined ||
      d === undefined
    ) {
      return null;
    }

    return (b - a) * c * d;
  }
  // a ---> time start , b ---> time end , c ---> no of rows , d----> std. weight , e---> rejected  weight
  function calcOkWeight(
    a: number | null | undefined,
    b: number | null | undefined,
    c: number | null | undefined,
    d: number | null | undefined,
    e: number | null | undefined
  ): number | null {
    if (
      a === null ||
      b === null ||
      c === null ||
      d === null ||
      e === null ||
      a === undefined ||
      b === undefined ||
      c === undefined ||
      d === undefined ||
      e === undefined
    ) {
      return null;
    }

    return (b - a) * c * d - e;
  }

  function totalRuntime(
    startTime: string | null | undefined,
    endTime: string | null | undefined
  ): number | null {
    if (
      startTime === undefined ||
      startTime === null ||
      endTime === undefined ||
      endTime === null
    ) {
      return null;
    }
    const start = new Date(startTime);
    const end = new Date(endTime);

    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      throw new Error('Invalid time format');
    }

    const diffInMilliseconds = Math.abs(end.getTime() - start.getTime());
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

    return Math.round(diffInHours);
  }

  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <tr key={index + 'module data'}>
              <td style={{ minWidth: '150px' }}>
                {isView ? (
                  formatDate(process.date)
                ) : (
                  <DatePickerMui
                    label=""
                    value={moment(process.date)}
                    onChange={(date) => {
                      process.date = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>
              <td style={{ minWidth: '80px' }}>
                {isView ? (
                  process.shift
                ) : (
                  <CustomSelect
                    name="shift"
                    value={process.shift || ''}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem value="A">A</MenuItem>
                    <MenuItem value="B">B</MenuItem>=
                  </CustomSelect>
                )}
              </td>
              <td style={{ minWidth: '200px' }}>
                {autoCompleteMachineValue._id && process.date && (
                  <Operator
                    onSubmit={handleSumbit}
                    field="operator"
                    machineId={autoCompleteMachineValue?._id}
                    dataObj={{
                      date: process.date,
                      part_id: process.part_id,
                    }}
                    {...{ isView,process }}
                  />
                )}
              </td>
              {/* {process.partNumber} */}
              <td style={{ width: '280px', minWidth: '280px' }}>
                {isView ? (
                  process?.part_id &&
                  process?.part_id?.part_name +
                    ' & ' +
                    process.part_id?.part_number
                ) : (
                  <Grid container xs={12} direction="row" alignItems={'center'}>
                    <Grid item xs={10}>
                      <p
                        style={{
                          fontSize: '1.2rem',
                        }}
                      >
                        {typeof process?.part_id === 'object' &&
                        process?.part_id !== null
                          ? process?.part_id?.part_name +
                            ' & ' +
                            process.part_id?.part_number
                          : (process?.partNumber &&
                              process?.partName +
                                ' & ' +
                                process?.partNumber) ||
                            ''}
                        {!process?.part_id && 'Pick Part No.'}
                      </p>
                    </Grid>

                    {process.part_id ? (
                      <Grid item xs={2}>
                        <RemoveIconButton
                          tooltipTitle="remove part"
                          onClick={() => {
                            process.part_id = null;
                            process.partNumber = null;
                            process.partName = null;
                            setRender((prev) => !prev);
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={2}>
                        <AddIconButton
                          tooltipTitle="add part"
                          onClick={() => {
                            setIsOpenPopup(true);
                            setPartIdx(index);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </td>
              <td style={{ minWidth: '80px' }}>
                {isView ? (
                  process.rotor_id
                ) : (
                  <CustomSelect
                    name="rotor_id"
                    value={process.rotor_id || ''}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  >
                    {[
                      '22.07',
                      '22',
                      '25',
                      '24',
                      '19.05',
                      '25.4',
                      '16/18',
                      '12.7',
                      '8/10/12/15',
                      '8',
                      '6',
                      '12.70/15.40/17.0/15.0',
                      '15/15.4',
                      '17',
                      '12',
                      '10',
                      '3.2',
                      '3.2/4.7',
                      '6.35',
                    ].map((rotorId, index) => (
                      <MenuItem value={rotorId} key={index + ' ' + rotorId}>
                        {rotorId}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                )}
              </td>
              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="batch_number"
                  type="text"
                  value={process.batch_number}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="material_size"
                  type="text"
                  value={process.material_size}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '80px' }}>
                {isView ? (
                  process.material_grade
                ) : (
                  <CustomSelect
                    name="material_grade"
                    value={process.material_grade || ''}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem value="id1">Grade 1</MenuItem>
                    <MenuItem value="id2">Grade 2</MenuItem>
                    <MenuItem value="id3">Grade 3</MenuItem>
                  </CustomSelect>
                )}
              </td>
              <td style={{ minWidth: '200px' }}>
                {isView ? (
                  process?.supplier_id?.supplier_name
                ) : (
                  <AutocompleteMuiCustom
                    id="Supplier"
                    label=""
                    option_name="supplier_name"
                    arrayofObj={supplierData}
                    value={autoCompleteSupplierValue[index]}
                    onChange={(e, value) => {
                      if (!value) {
                        process.supplier_id = null;
                      }
                      process.supplier_id = value?._id;
                      autoCompleteSupplierValue[index] = value;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>

              <td style={{ minWidth: '100px' }}>
                {isView ? (
                  convToTime(process.time_start)
                ) : (
                  <CustomMobileTimePickerMui
                    value={process.time_start}
                    onChange={(date: any) => {
                      process.time_start = date;
                      setRender((prev) => !prev);
                    }}
                    ampm={true}
                    openTo="hours"
                    views={['hours', 'minutes']}
                  />
                )}
              </td>
              <td style={{ minWidth: '100px' }}>
                {isView ? (
                  convToTime(process.time_stop)
                ) : (
                  <CustomMobileTimePickerMui
                    value={process.time_stop}
                    onChange={(date: any) => {
                      process.time_stop = date;
                      setRender((prev) => !prev);
                    }}
                    ampm={true}
                    openTo="hours"
                    views={['hours', 'minutes']}
                  />
                )}
              </td>
              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="counter_start"
                  type="number"
                  value={process.counter_start}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="counter_end"
                  type="number"
                  value={process.counter_end}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '80px' }}>
                {isView ? (
                  process.no_of_rows
                ) : (
                  <CustomSelect
                    name="no_of_rows"
                    value={process.no_of_rows || null}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                  </CustomSelect>
                )}
              </td>
              <td style={{ minWidth: '80px' }}>
                {/* <TableInput
                  isView={isView}
                  name="total_strokes"
                  type="number"
                  value={process.total_strokes}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                /> */}
                {calcTotalStrokes(
                  process.counter_start,
                  process.counter_end,
                  process.no_of_rows
                )}
              </td>
              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="spm"
                  type="number"
                  value={process.spm}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '80px' }}>
                {/* <TableInput
                  isView={isView}
                  name="total_run_time_inHrs"
                  type="number"
                  value={process.total_run_time_inHrs}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                /> */}
                {totalRuntime(process.time_start, process.time_stop)}
              </td>
              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="avg_strokes_per_hr"
                  type="number"
                  value={process.avg_strokes_per_hr}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="std_weight"
                  type="number"
                  value={process.std_weight}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '80px' }}>
                {/* production weight column or total weight column */}

                {calcProductionWeight(
                  process.counter_start,
                  process.counter_end,
                  process.no_of_rows,
                  process.std_weight
                )}
              </td>
              <td style={{ minWidth: '80px' }}>
                {/* added by sir ok weight column*/}

                {calcOkWeight(
                  process.counter_start,
                  process.counter_end,
                  process.no_of_rows,
                  process.std_weight,
                  process.rej_qty
                )}
              </td>
              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="rej_qty"
                  type="number"
                  value={process.rej_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="verified_sign"
                  type="text"
                  value={process.verified_sign}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '80px' }} colSpan={isView ? 2 : 1}>
                <TableInput
                  isView={isView}
                  name="remarks"
                  type="text"
                  value={process.remarks}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {!isView && (
                <td style={{ minWidth: '100px' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
