import axios from 'axios';
import { useEffect, useState } from 'react';

const PartStrokeRate = ({
  machineId,
  process,
  partId,
  setRender,
}: {
  machineId: string;
  process: any;
  partId?: any;
  setRender?: any;
}) => {
  const [strokeRate, setStrokeRate] = useState(0);

  useEffect(() => {
    if (machineId && partId) {
      axios
        .get(
          `/api/partStrokeRateRoute/strokeByMachineId/${machineId}?partId=${partId}`,
        )
        .then((res) => {
          if (res.data) {
            process.stroke = res.data.stroke;
            setStrokeRate(res.data.stroke);
            setRender((prev: any) => !prev);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      process.stroke = 0;
      setStrokeRate(0);
    }
  }, [machineId, partId]);
  return <>{strokeRate}</>;
};
export default PartStrokeRate;
