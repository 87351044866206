import React, { useState } from 'react';
import { Button, Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  AddButton,
  CustomButton,
} from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import ModalInput from './ModalInput';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { usePermission } from '../../../utils/helpers/usePermission';

import { IData } from './Interface';
import CustomTileButton from '../../../components/common/button/CustomTileButton';
import DescriptionIcon from '@mui/icons-material/Description';

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Title', rowspan: 1, colspan: 1 },
    { text: 'Department', rowspan: 1, colspan: 1 },
    { text: 'Edit Title', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [
  { value: 'createdAt', name: 'A-Z' },
  { value: 'faculty_name', name: 'Name' },
];

const List = () => {
  const permissions = usePermission(31);

  const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [tniTopicId, setTNITopicId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);

  const search = useQuery('search');
  const filter = useQuery('filter');
  const page = useQuery('page') || 1;

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?',
  );
  const navigate = useNavigate();

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/skillEvaluation/getAllSkillEvaluation/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}`,
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteTNITopic = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/skillEvaluation/deleteSkillEvaluation/${id}`)
      .then((res) => {
        alert('TNI deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, isModal]);

  return (
    <>
      <ChildHeader text="SKILL REQUIREMENT SHEET LIST">
        {permissions.includes('edit') && (
          <AddButton
            onClick={() => setIsModal(true)}
            label="New SKILL REQUIREMENT SHEET"
          />
        )}
      </ChildHeader>
      <SearchFilterContainer filter={false}>
        <SearchBar label="Search By Title" />
        {/* <FilterBy options={filterOptions} /> */}
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: IData, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item?.title}</TableCell>
                  <TableCell align="center">
                    {item?.department_id?.department_name}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      overflow: 'auto',
                    }}
                  >
                    {permissions.includes('edit') && (
                      <Button
                        variant="contained"
                        style={{
                          background: '#003566',
                          color: '#fafafa',
                        }}
                        startIcon={<DescriptionIcon />}
                        onClick={() => {
                          setTNITopicId(item?._id || '');
                          setIsModal(true);
                        }}
                      >
                        Edit Title and Departments
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      {permissions.includes('edit') && (
                        <EditIconButton
                          tooltipTitle="EDIT"
                          onClick={() => {
                            navigate(
                              `/common/createEditViewSkillEvaluation/${item._id}`,
                            );
                          }}
                        />
                      )}
                      <ViewIconButton
                        tooltipTitle="View Topic paper"
                        onClick={() => {
                          navigate(
                            `/common/createEditViewSkillEvaluation/${item._id}?isView=true`,
                          );
                        }}
                      />
                      {permissions.includes('delete') && (
                        <DeleteIconButton
                          tooltipTitle="DELETE"
                          onClick={() => {
                            deleteTNITopic(item?._id || '');
                          }}
                        />
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setTNITopicId(null);
          setIsModal(false);
        }}
        title="CREATE NEW SKILL EVALUATION CRITERIA"
      >
        <ModalInput id={tniTopicId} setIsModal={setIsModal} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
