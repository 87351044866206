import { Grid, IconButton, MenuItem, Tooltip } from '@mui/material';
// import ObservationInput from './ObservationInput';
import { useState } from 'react';
import CustomSelect from '../../../../components/common/SelectField';
import DeleteIcon from '@mui/icons-material/Delete';
import { TableInput } from '../../../../components/common';
import InputMuiCustom, { InputMuiCustomSpc } from '../../../../components/common/inputs/InputMuiCustom';
// import { handleChangeColorOnRemarks } from '../../helpers/handleChangeColorOnRemarks';

interface IProps {
    spcData:any;
}
const TBody = ({ spcData}: IProps) => {
  const [render, setRender] = useState(false);
  const tableRows = Array.from({ length: 5 }, (_, row) => (
    <tr key={row+'spc_row'}>
        <td width={'150px'} >{String.fromCharCode('A'.charCodeAt(0) + row)}</td>
      {spcData.spc_array.slice(row * 25, (row + 1) * 25).map((_:any, sopidx:any) => (
        <td key={sopidx+row*25} style={{backgroundColor:'yellow' } } >

                {/* <Grid item xs={3} key={`${sopidx+row*25}+uud`} > */}
                  <InputMuiCustomSpc
                    type="number"
                    name="part_name"
                    // placeholder={`${sopidx + 1+row*25}`}
                    onChange={(e) => {
                      spcData.spc_array[sopidx+row*25] = +e.target.value;
                      setRender((prev) => !prev);
                    }}
                    value={spcData.spc_array[sopidx+row*25]}
                  />
                {/* </Grid> */}

        </td>
      ))}
    </tr>
  ));
  return (
    <tbody>
        {tableRows}

    </tbody>
      );
};

export default TBody;
