import { FaHouseUser } from 'react-icons/fa';
import { FcConferenceCall } from 'react-icons/fc';
import { RenderTiles } from '../../../components/common';
import ChildHeader from '../../../components/ui/ChildHeader';
export type tilesType = {
  sNo: number;
  link: string;
  isLink: boolean;
  isIcon: boolean;
  clauseNo?: string | number;
  p: string;
  span: string;
  icon?: any;
  style?: {};
}[];

const tiles = [
  {
    sNo: 1,
    link: '/common/ncReportList',
    isLink: true,
    icon: <FcConferenceCall size="5rem" color="#087f5b" />,
    p: 'NON-CONFORMANCE',
    span: 'REPORT',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/inHouseComplaintRegisterList?filter=createdAt',
    isLink: true,
    clauseNo: 8,
    icon: <FaHouseUser size="5rem" color="#a12222" />,
    p: 'IN-HOUSE',
    span: 'COMPLAINT REGISTER',
    isIcon: true,
  },
];
const InHouseHome = () => {
  return (
    <>
      <ChildHeader text="IN-HOUSE COMPLAINT" />
      <RenderTiles tilesObj={tiles} height="100vh" />
    </>
  );
};

export default InHouseHome;
