const style1 = {
  backgroundColor: '#4F81BD',
  // backgroundColor: '#00B050',
  color: '#fff',
  minWidth: '80px',
  border: '1px solid #343a40',
};

export const tableHeaderObj = [
  [
    { text: 'Unique ID', style: { ...style1, minWidth: '140px' } },
    { text: 'Date of application', style: { ...style1, minWidth: '150px' } },
    { text: 'PLAN DATE OF INTERVIEW', style: { ...style1, minWidth: '150px' } },
    {
      text: 'ACTUAL DATE OF INTERVIEW',
      style: { ...style1, minWidth: '150px' },
    },
    { text: 'Candidate Name', style: { ...style1 } },
    { text: "Father's Name", style: { ...style1 } },
    { text: 'Applied for Post', style: { ...style1 } },
    { text: 'Mobile No.', style: { ...style1 } },
    { text: 'Email ID', style: { ...style1 } },
    { text: 'Date of Birth', style: { ...style1 } },
    { text: 'Status', style: { ...style1, minWidth: '160px' } },
    { text: 'Interview Format', style: { ...style1 } },
    { text: 'Interview Form', style: { ...style1 } },
    { text: 'CV', style: { ...style1 } },
    { text: 'ACTION', style: { ...style1 } },
  ],
];

export const checkListList = {
  childHeader: 'ANNUAL PM PLAN STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  get: 'hireCandidatePortalRoute',
  getbyId: 'hireCandidatePortalRoute',
  update: 'hireCandidatePortalRoute',
  create: 'hireCandidatePortalRoute',
  delete: 'hireCandidatePortalRoute',
  uploadFile: 'hireCandidatePortalRoute/uploadFile',
  deleteFile: 'hireCandidatePortalRoute/deleteFile',
};

export const navigationConstant = {
  skillEvaluationTestSheetListRouter:
    '/common/employeeSkillEvaluationSheetList',
  skillEvaluationTestSheetRouter: '/common/employeeSkillEvaluationSheetCreate',
};
