import TBodyRow from './TBodyRow';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: (index: number) => void;
}

const TBody = ({ handelDeleteRows, moduleData, isView }: IProps) => {
  return (
    <tbody>
      {moduleData.processes.map((process: any, index: number) => (
        <TBodyRow
          {...{
            isView,
            process,
            handelDeleteRows,
            index,
          }}
        />
      ))}
    </tbody>
  );
};

export default TBody;
