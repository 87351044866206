import { useCallback, useState } from 'react';
import { TableBody } from '@mui/material';
import axios from 'axios';
import { PDFDocument, rgb } from 'pdf-lib';

import { AddButton } from '../../components/common/button/CustomButtons';
import ChildHeader from '../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../components/common/FilterBy';
import { SearchBar } from '../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../components/common';
import useDebounce from '../../hooks/useDebounce';
import Create from './Create';
import ModalCustom from '../../components/common/Modal';
import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';
import { tableHeaderObj } from './helpers/contant';
import ListRows from './ListRows';
import { useNavigate } from 'react-router-dom';
import { apiConstant } from './helpers/contant';
import { Loader } from '../../components/common/Loader';
import Profile from './components/Profile';
import ViewFile from './components/ViewFile';
import { usePermission } from '../../utils/helpers/usePermission';

const filterOptions = [{ value: 'createdAt', name: 'Most Recent First' }];

const List = () => {
  const permissions = usePermission(31);
  const [data, setData] = useState<any[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [moduleId, setModuleId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [isProfileModal, setIsProfileModal] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [documentType, setDocumentType] = useState('');
  const search = useQuery('search');
  const filter = useQuery('filter');
  const page = useQuery('page') || 1;
  const [isLoading, setIsLoading] = useState(false);
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE Employee',
    'Are you sure you want to delete this?',
  );
  const [DialogStatus, confirmStatus] = useConfirm(
    'Update Status',
    'Are you sure you want to update status?',
  );
  const [DialogUploadFile, confirmUploadFile] = useConfirm(
    'Upload File',
    'Are you sure you want to upload this file?',
  );

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/${apiConstant.get}?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}`,
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 500, [
    page,
    isOpenPopup,
    isProfileModal,
    search,
    render,
    isModal,
  ]);

  const handleDelete = useCallback(async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/${apiConstant.delete}/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleDownloadDocs = useCallback(
    async (
      uid: string,
      name: string,
      date: string,
      applied_post: string,
      mobile_no: string,
      email_id: string,
      dob: string,
      profile_img_url: string,
    ) => {
      const url = `https://mushin-labs.s3.ap-south-1.amazonaws.com/SL-Fastener/candidatehiring/F+HRD+04+-+Interview+Form+-+PDF+Format.pdf`;

      const imageUrl = profile_img_url;

      try {
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        const pdfDoc = await PDFDocument.load(arrayBuffer);
        const imageArrayBuffer = await fetch(imageUrl).then((res) =>
          res.arrayBuffer(),
        );

        let image;
        // Check the content-type or extension of the image to decide whether to use embedPng or embedJpg
        if (profile_img_url.toLowerCase().endsWith('.png')) {
          image = await pdfDoc.embedPng(imageArrayBuffer);
        } else if (
          profile_img_url.toLowerCase().endsWith('.jpg') ||
          profile_img_url.toLowerCase().endsWith('.jpeg')
        ) {
          image = await pdfDoc.embedJpg(imageArrayBuffer);
        } else {
          alert('Unsupported image format Please upload png or jpg or jpeg');
          throw new Error('Unsupported image format');
        }

        // Add your text to the first page of the PDF
        const pages = pdfDoc.getPages();
        const firstPage = pages[0];
        const { width, height } = firstPage.getSize();
        firstPage.drawText(`${date}`, {
          x: 65, // Position from the left edge
          y: height - 293, // Position from the bottom edge
          size: 9,
          color: rgb(0, 0, 0),
        });

        firstPage.drawText(`${name}`, {
          x: 110,
          y: height - 318,
          size: 9,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(`${applied_post}`, {
          x: 118,
          y: height - 343,
          size: 9,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(`${mobile_no}`, {
          x: 215,
          y: height - 491,
          size: 9,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(`${email_id}`, {
          x: 334,
          y: height - 491,
          size: 9,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(`${dob}`, {
          x: 200,
          y: height - 515,
          size: 9,
          color: rgb(0, 0, 0),
        });

        firstPage.drawImage(image, {
          x: 410,
          y: height - 390,
          width: 100,
          height: 100,
        });

        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save();

        // Create a Blob from the PDF bytes and trigger download
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${uid}-${name}-${date}.pdf`;
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    },
    [],
  );

  const getColorForStatus = useCallback((status: string) => {
    const colorMap: any = {
      Pending: '#ffd43b',
      Rejected: '#ff6b6b',
      Selected: '#51cf66',
      Shortlisted: '#ffd43b',
    };
    return colorMap[status] || '';
  }, []);

  const handleStatusUpdate = useCallback(async (id: string, item: any) => {
    const ans = await confirmStatus();
    if (!ans) return;
    await axios
      .put(`/api/${apiConstant.update}/${id}`, item)
      .then((res) => {
        setIsModal(false);
        setModuleId(null);
      })
      .catch((err) => console.log(err));
  }, []);
  const uploadImageFile = async (e: any, id: string, documentType: string) => {
    const ans = await confirmUploadFile();
    if (!ans) {
      e.target.value = '';
      return;
    }
    const files = e.target.files;
    if (!files) return;
    let formData = new FormData();
    for (const key of Object.keys(files)) {
      formData.append('file', files[key]);
    }
    setIsLoading(true);
    axios
      .patch(
        `/api/${apiConstant.uploadFile}/${id}/${documentType}
      `,
        formData,
      )
      .then((res) => {
        if (res.data) {
          alert('document upload successly');
          setIsLoading(false);
          setRender((prev) => !prev);
          e.target.value = '';
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const viewFile = async (id: any, documentName: string) => {
    setIsOpenPopup(true);
    setModuleId(id);
    setDocumentType(documentName);
  };
  return (
    <>
      <ChildHeader text="Candidate LIST">
        {permissions.includes('edit') && (
          <AddButton onClick={() => setIsModal(true)} label="New Candidate" />
        )}
      </ChildHeader>
      <Loader loader={isLoading} />
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <FilterBy options={filterOptions} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data?.map((item: any, index: number) => (
              <ListRows
                {...{
                  item,
                  uploadImageFile,
                  setIsProfileModal,
                  index,
                  handleDownloadDocs,
                  setModuleId,
                  setIsModal,
                  handleDelete,
                  getColorForStatus,
                  handleStatusUpdate,
                  viewFile,
                }}
              />
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setModuleId(null);
          setIsModal(false);
        }}
        title="CREATE NEW Candidate"
      >
        <Create
          id={moduleId}
          setIsModal={setIsModal}
          setModuleId={setModuleId}
        />
      </ModalCustom>
      <ModalCustom
        openModal={isProfileModal}
        closeModal={() => {
          setIsProfileModal(false);
        }}
        title="PROFILE"
      >
        <Profile id={moduleId} {...{ setIsProfileModal }} />
      </ModalCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
          setModuleId(null);
          setDocumentType('');
        }}
      >
        <ViewFile id={moduleId} documentType={documentType} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
      <DialogStatus isSubmitContent={false} />
      <DialogUploadFile isSubmitContent={false} />
    </>
  );
};

export default List;
