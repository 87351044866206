import {
  CustomerComplaintList,
  ListOfDefects,
  SupplierComplaintList,
} from '../../pages/abnormalityHandling';
import { Clause8Home } from '../../pages/clause8';

export const clause8 = [
  {
    path: 'clause8',
    element: <Clause8Home />,
  },
  {
    path: 'defect_list',
    element: <ListOfDefects />,
  },
  {
    path: 'customerComplaintRegister',
    element: <CustomerComplaintList />,
  },
  {
    path: 'supplierComplaintRegister',
    element: <SupplierComplaintList />,
  },
];
