import styled from 'styled-components';

export const StyledTableHeaderRow: any = styled.tr`
  position: sticky;
  width: '100%';
  top: ${(props: any) => props.top || '-0.5rem'};
  border: 1px solid black;
  z-index: 2;
  height: 50px;
  // color: #fff;
`;
