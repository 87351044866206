import moment from 'moment';
const calMinutes = (from: any, to: any) => {
  if (!from && !to) return null;
  // Create Moment.js objects from the time values
  const startTimeObj = moment(from, 'YYYY-MM-DD HH:mm:ss');
  const endTimeObj = moment(to, 'YYYY-MM-DD HH:mm:ss');
  // Calculate the difference in minutes
  const diffInMinutes = endTimeObj.diff(startTimeObj, 'minutes');

  return diffInMinutes;
};

export default calMinutes;
