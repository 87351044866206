import { useAuth } from '../../services/auth/AuthProvider';

export const usePermission = (id: number) => {
  const { state } = useAuth();
  let permissions = [];
  const accessIndex = state.user?.access?.findIndex(
    (obj: any) => obj.id === id,
  );
  if (accessIndex !== -1) {
    permissions = state.user?.access[accessIndex].permissions;
  }
  return permissions;
};
