import { useState } from 'react';
import { Grid } from '@mui/material';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import moment from 'moment';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
  ViewIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setPartIdx: any;
  setIsOpenSummary: any;
}

const getBackgroundColor = (jobCompletion: number) => {
  if (jobCompletion === 100) return '#8ecae6';
  if (jobCompletion >= 90) return '#a7c957';
  if (jobCompletion >= 50) return '#e9c46a';
  return '#f28482';
};

const TBody = ({
  moduleData,
  isView,
  handelDeleteRows,
  setIsOpenPopup,
  setPartIdx,
  setIsOpenSummary,
}: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <>
      <tbody>
        {moduleData.map((process: any, index: number) => (
          <tr key={index + 'module data'}>
            <td width={'100px'} style={{ fontWeight: 'bold' }}>
              {index + 1}
            </td>
            <td style={{ width: '250px', minWidth: '250px' }}>
              {isView ? (
                process?.part_id && process?.part_id?.item_no
              ) : (
                <Grid container xs={12} direction="row" alignItems={'center'}>
                  <Grid item xs={10}>
                    <p
                      style={{
                        fontSize: '1.6rem',
                      }}
                    >
                      {typeof process?.part_id === 'object' &&
                      process?.part_id !== null
                        ? process?.part_id?.item_no
                        : process?.partNumber || ''}
                      {!process?.part_id && 'Pick Part Number'}
                    </p>
                  </Grid>

                  {process.part_id ? (
                    <Grid item xs={2}>
                      <RemoveIconButton
                        tooltipTitle="remove part"
                        onClick={() => {
                          process.part_id = null;
                          process.partNumber = null;
                          process.partName = null;
                          process.tool_item_ids = [];
                          process.tool_ids = [];
                          process.weight_pc = null;
                          setRender((prev) => !prev);
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={2}>
                      <AddIconButton
                        tooltipTitle="add part"
                        onClick={() => {
                          setIsOpenPopup(true);
                          setPartIdx(index);
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </td>
            <td
              style={{
                minWidth: '120px',
                backgroundColor: getBackgroundColor(process.job_completion),
              }}
            >
              {process.job_completion}
            </td>
            <td style={{ minWidth: '120px' }}>
              <TableInput
                isView={isView}
                name="planned_qty"
                type="number"
                value={process.planned_qty}
                onChange={(e) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '120px' }}>{process.actual_qty}</td>
            <td style={{ minWidth: '120px' }}>{process.stroke}</td>
            <td style={{ minWidth: '120px' }}>{process.total_time_required}</td>
            <td style={{ minWidth: '120px' }}>{process.total_downtime}</td>
            <td style={{ minWidth: '170px' }}>
              {isView ? (
                formatDate(process.start_date)
              ) : (
                <DatePickerMui
                  label=""
                  value={moment(process.start_date || null)}
                  onChange={(date) => {
                    process.start_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td style={{ minWidth: '120px' }}>
              {formatDate(process.predicted_completion_date)}
            </td>
            <td style={{ minWidth: '120px' }}>
              {formatDate(process.actual_completion_date)}
            </td>
            <td>
              <ViewIconButton
                tooltipTitle="Daywise Summary"
                onClick={() => {
                  setPartIdx(index);
                  setIsOpenSummary(true);
                }}
              />
            </td>
            <td>
              <DeleteIconButton
                tooltipTitle={'delete'}
                onClick={() => handelDeleteRows(process._id)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

export default TBody;
