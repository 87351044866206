import React, { useState, useEffect, useRef } from 'react';
import { Box, Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import moment from 'moment';

import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { IData } from './helpers/interfaces';
import ViewFile from './components/ViewFile';
import formatDate from '../../../components/common/formatDate';
import TableScroll from '../../../components/ui/renderTable/TableScroll';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import TextAreaInput from '../../../components/common/TextAreaInput';
import ReportFormat from '../../../components/ui/ReportFormat';
import { usePermission } from '../../../utils/helpers/usePermission';
const tableHeaderObj = [
  [
    { text: 'SR NO.', rowspan: 1, colspan: 1 },
    { text: 'Complaint No.', rowspan: 1, colspan: 1 },
    { text: 'DATE OF OCCURRENCE', rowspan: 1, colspan: 1 },
    { text: 'ITEM DESCRIPTION', rowspan: 1, colspan: 1 },
    { text: 'MRN', rowspan: 1, colspan: 1 },
    { text: 'SUPPLIER', rowspan: 1, colspan: 1 },
    { text: 'DEFECT', rowspan: 1, colspan: 1 },
    { text: 'TOTAL QUANTITY', rowspan: 1, colspan: 1 },
    { text: 'UOM', rowspan: 1, colspan: 1 },

    { text: 'ROOT CAUSE ANALYSIS', rowspan: 1, colspan: 1 },
    { text: 'ACTION TAKEN', rowspan: 1, colspan: 1 },
    { text: 'TARGET Date', rowspan: 1, colspan: 1 },
    { text: 'ACTUAL CLOSER DATE', rowspan: 1, colspan: 1 },
    { text: 'RESP.', rowspan: 1, colspan: 1 }, // new
    { text: 'M1 QTY. CHECKED', rowspan: 1, colspan: 1 },
    { text: 'M1 UOM', rowspan: 1, colspan: 1 },
    { text: 'M1', rowspan: 1, colspan: 1 },
    { text: 'M2 QTY. CHECKED', rowspan: 1, colspan: 1 },
    { text: 'M2 UOM', rowspan: 1, colspan: 1 },
    { text: 'M2', rowspan: 1, colspan: 1 },
    { text: 'M3 QTY. CHECKED', rowspan: 1, colspan: 1 },
    { text: 'M3 UOM', rowspan: 1, colspan: 1 },
    { text: 'M3', rowspan: 1, colspan: 1 },
    { text: 'M  I', rowspan: 1, colspan: 1 },
    { text: 'M  II', rowspan: 1, colspan: 1 },
    { text: 'M  III', rowspan: 1, colspan: 1 },
    { text: 'Horizontal Deployment', rowspan: 1, colspan: 1 }, // new
    { text: 'Quality Alert', rowspan: 1, colspan: 1 }, // new

    { text: 'DRG / PFD', rowspan: 1, colspan: 1 },
    { text: 'FMEA', rowspan: 1, colspan: 1 },
    { text: 'Control Plan', rowspan: 1, colspan: 1 },
    { text: 'Inspection Standard', rowspan: 1, colspan: 1 }, // new

    { text: 'M/C CHECK SHEET', rowspan: 1, colspan: 1 },
    { text: 'REMARKS', rowspan: 1, colspan: 1 },
    { text: 'DEFECT PHOTO', rowspan: 1, colspan: 1 },
    { text: 'SUPPORTING DOCUMENTS', rowspan: 1, colspan: 1 },
    { text: 'COMPLAINT STATUS', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];

const List = () => {
  const permissions = usePermission(16);

  const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [complaintId, setComplaintId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [id, setId] = useState<string | null>(null);
  const [isFileOpenPopup, setIsFileOpenPopup] = useState(false);
  const [contentType, setContentType] = useState('');
  const [filterCustomer, setFilterCustomer] = useState<any>([]);
  const [filterDefectsReported, setFilterDefectsReported] = useState<any>([]);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;
  const customer = useQuery('customer');
  const defect = useQuery('defects');
  const [all, setAll] = useState(false);
  const [docFiles, setDocFiles] = useState<any>({});
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenFiles, setIsOpenFiles] = useState(false);
  const idRef = useRef<any>({});
  const [files, setFiles] = useState<any>(null);
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?'
  );

  useEffect(() => {
    axios
      .get('/api/defectRoutes/defectList/defectForAutoComplete')
      .then((res) => {
        if (res?.data) {
          const formattedData = res.data.map((defect: any) => ({
            name: defect.defect_name,
            value: defect.defect_name,
          }));
          setFilterDefectsReported([...formattedData]);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/supplierComplaintRoutes/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}&customer=${customer}&defects=${defect}&all=${all}`
      )
      .catch((err) => {
        console.log(err);
      });

    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteCustomerConcern = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/supplierComplaintRoutes/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };
  const uploadFile = async (e: any, item: any) => {
    if (!window.confirm('Are you sure you want upload this Files')) return;
    const files = e.target.files;
    if (!files) return;
    let formData = new FormData();
    for (const key of Object.keys(files)) {
      formData.append('files', files[key]);
    }
    setIsLoading(true);
    axios
      .patch(`/api/supplierComplaintRoutes/handleUpload/${item._id}`, formData)
      .then((res) => {
        if (res.data) {
          alert('document upload successly');
          setIsLoading(false);
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useDebounce(fetchData, 500, [
    page,
    search,
    render,
    isModal,
    filter,
    customer,
    defect,
    all,
  ]);
  return (
    <>
      <ChildHeader text="Supplier Complaint Register & Action Verification Sheet">
        {permissions.includes('edit') && (
          <>
            <AddButton
              onClick={() => {
                setAll((value) => !value);
              }}
              label={all ? 'Hide' : 'Save'}
            />
            <AddButton
              onClick={() => setIsModal(true)}
              label="NEW SUPPLIER Complaint"
            />
          </>
        )}
      </ChildHeader>
      <ReportFormat
        text1={'Format No.: F SYS 49'}
        text2={'Issue Date: 01/04/2019'}
        text3={'Revision: 03'}
        text4={'Revision Date: 01/06/2022'}
      />
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search By MODEL/CUSTOMER" />

        <FilterBy
          name="defects"
          label="Filter By Defects Reprted"
          options={filterDefectsReported}
        />
      </SearchFilterContainer>

      <TableScroll>
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {data &&
              data.map((item: IData, index: number) => (
                <React.Fragment key={item._id}>
                  <TableRow>
                    <TableCell align="center">
                      {perPage * (+page - 1) + index + 1}
                    </TableCell>
                    <TableCell align="center">{item.complaint_no}</TableCell>
                    <TableCell align="center">
                      {item.date_of_occurrence &&
                        formatDate(item.date_of_occurrence)}
                    </TableCell>
                    <TableCell align="center" style={{ width: '250px' }}>
                      {item.part_id?.item_description}
                    </TableCell>
                    <TableCell align="center">{item?.mrn}</TableCell>

                    <TableCell align="center">{item?.supplier}</TableCell>
                    <TableCell align="center">
                      {item.defect_id?.defect_name}
                    </TableCell>
                    <TableCell align="center">{item.total_quantity}</TableCell>
                    <TableCell align="center">{item?.uom}</TableCell>
                    <TableCell align="left" style={{ minWidth: '300px' }}>
                      {item.root_cause}
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: '300px' }}>
                      {item.action_taken}
                    </TableCell>
                    <TableCell align="center">
                      {item.target_date && formatDate(item.target_date)}
                    </TableCell>
                    <TableCell align="center">
                      {item.actual_closure_date &&
                        formatDate(item.actual_closure_date)}
                    </TableCell>
                    <TableCell align="center">{item?.resp}</TableCell>
                    {['m1', 'm2', 'm3'].map((month: any) => {
                      return (
                        <>
                          <TableCell align="center">
                            {item[month]?.quantity}
                          </TableCell>
                          <TableCell align="center">
                            {item[month]?.uom}
                          </TableCell>
                          <TableCell
                            style={{
                              ...(item[month]?.check &&
                                item[month]?.check.toLowerCase() ===
                                  'reject' && {
                                  background: '#e03131',
                                }),
                              ...(item[month]?.check &&
                                item[month]?.check.toLowerCase() === 'ok' && {
                                  background: '#2f9e44',
                                }),
                              minWidth: '100px',
                            }}
                            align="center"
                          ></TableCell>
                        </>
                      );
                    })}
                    <TableCell align="center" style={{ minWidth: '100px' }}>
                      {item?.m1?.verification}
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: '100px' }}>
                      {item?.m2?.verification}
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: '100px' }}>
                      {item?.m3?.verification}
                    </TableCell>
                    <TableCell align="center">
                      {item.horizontal_deployment}
                    </TableCell>
                    <TableCell align="center">{item.quality_alert}</TableCell>
                    <TableCell align="center">{item.drg_pfd}</TableCell>
                    <TableCell align="center">{item.fmea}</TableCell>
                    <TableCell align="center">{item.control_plan}</TableCell>
                    <TableCell align="center">
                      {item.inspection_standard}
                    </TableCell>
                    <TableCell align="center">{item.mc_check_sheet}</TableCell>
                    <TableCell align="center">{item.remarks}</TableCell>
                    <TableCell align="center">
                      <Stack direction="row" sx={{ justifyContent: 'center' }}>
                        {permissions.includes('edit') && (
                          <CustomUploadButton
                            lable={'Defect photo'}
                            id={`${index}_iamges`}
                            multiple
                            accept="image/png,image/jpeg,image/jpg"
                            onChange={(e) => {
                              uploadFile(e, item);
                            }}
                          />
                        )}

                        {item.images &&
                          item.images.length > 0 &&
                          permissions.includes('view') && (
                            <ViewIconButton
                              tooltipTitle="view"
                              onClick={() => {
                                setId(item._id || null);
                                setIsFileOpenPopup(true);
                                setContentType('image');
                              }}
                            />
                          )}
                      </Stack>
                    </TableCell>
                    <TableCell align="center">
                      <Stack direction="row" sx={{ justifyContent: 'center' }}>
                        {permissions.includes('edit') && (
                          <CustomUploadButton
                            lable={'Attach Doucments'}
                            id={`${index}_files`}
                            multiple
                            accept="application/pdf,application/doc,application/excel/,application/ppt"
                            onChange={(e) => {
                              uploadFile(e, item);
                            }}
                          />
                        )}
                        {item.files &&
                          item.files.length > 0 &&
                          permissions.includes('view') && (
                            <ViewIconButton
                              tooltipTitle="view"
                              onClick={() => {
                                setId(item._id || null);
                                setIsFileOpenPopup(true);
                                setContentType('file');
                              }}
                            />
                          )}
                      </Stack>
                    </TableCell>
                    <TableCell
                      style={{
                        ...(item.complaint_status === 'CLOSED' && {
                          background: '#2f9e44',
                        }),
                        ...(item.complaint_status === 'OPEN' && {
                          background: '#e03131',
                        }),
                        minWidth: '100px',
                      }}
                      align="center"
                    >
                      {item.complaint_status}
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" sx={{ justifyContent: 'center' }}>
                        {permissions.includes('edit') && (
                          <EditIconButton
                            tooltipTitle="EDIT"
                            onClick={() => {
                              setComplaintId(item?._id || '');
                              setIsModal(true);
                            }}
                          />
                        )}

                        {permissions.includes('delete') && (
                          <DeleteIconButton
                            tooltipTitle="DELETE"
                            onClick={() => {
                              deleteCustomerConcern(item?._id || '');
                            }}
                          />
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </TableCustom>
      </TableScroll>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setComplaintId(null);
          setIsModal(false);
        }}
        title="SUPPLIER COMPLAINT REGISTER"
      >
        <Create id={complaintId} setIsModal={setIsModal} />
      </ModalCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isFileOpenPopup}
        closeModal={() => {
          setId(null);
          setIsFileOpenPopup(false);
        }}
      >
        <ViewFile id={id} contentType={contentType} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
