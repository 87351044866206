import { Box, TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { TableCustom } from '../../../../../components/common';
import formatDate from '../../../../../components/common/formatDate';
const tableHeaderObj = [
  [
    { text: 'S.NO.', rowspan: 1, colspan: 1 },
    { text: 'DAILY PRODUCTION DATE', rowspan: 1, colspan: 1 },
    { text: 'OK QTY WITHOUT CHIPS', rowspan: 1, colspan: 1 },
  ],
];

const DaywiseSummary = ({ records }: { records: any }) => {
  return (
    <Box width={600}>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {records.map((item: any, index: number) => (
            <React.Fragment key={item?._id}>
              <TableRow>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">
                  {formatDate(item.dailyproduction_date)}
                </TableCell>
                <TableCell align="center">{item.ok_without_chips}</TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
        '{' '}
      </TableCustom>
    </Box>
  );
};
export default DaywiseSummary;
