import React, { useState, useEffect } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import ChildHeader from '../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../components/common/FilterBy';
import { SearchBar } from '../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../components/common';
import useDebounce from '../../hooks/useDebounce';
import { DeleteIconButton } from '../../components/common/button/CustomIconButton';
import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';
const tableHeaderObj = [
  [
    { text: 'S.NO.', rowspan: 1, colspan: 1 },
    { text: 'TOOL ITEM CODE', rowspan: 1, colspan: 1 },
    { text: 'TOOL ID NO.', rowspan: 1, colspan: 1 },
    { text: 'DESCRIPTION', rowspan: 1, colspan: 1 },
    { text: 'CATEGORY', rowspan: 1, colspan: 1 },
    { text: 'STATUS', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];

const ObseleteToolList = () => {
  const [data, setData] = useState<any[]>([]);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const search = useQuery('search') || '';
  const category = useQuery('category');
  const page = useQuery('page') || 1;

  const [categoryData, setCategoryData] = useState([]);

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?',
  );

  useEffect(() => {
    axios
      .get(
        '/api/masterListOfToolRoute/toolItemCodeList/toolItemCodeForAutoComplete',
      )
      .then((res) => {
        if (res?.data) {
          const formattedData = res.data.map((item: any) => ({
            name: item.name,
            value: item.name,
          }));
          setCategoryData(formattedData);
        }
      })
      .catch((err) => console.log(err));
  }, [render]);

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/masterListOfToolRoute/?page=${page}&category=${category}&search=${search}&perPage=${perPage}&statusFilter=Unavailable`,
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.result);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 500, [page, search, render, category]);

  const handleDelete = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/masterListOfToolRoute/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <ChildHeader text="OBSELETE TOOL LIST"></ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <Stack direction={'row'} gap={2} flex="1">
          <FilterBy
            sx={{ width: 200 }}
            name="category"
            label="Filter By Category"
            options={categoryData}
          />
        </Stack>
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: 'capitalize' }}
                  >
                    {item?.tool_item_id?.name}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: 'capitalize' }}
                  >
                    {item?.tool_no}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: 'capitalize' }}
                  >
                    {item?.tool_desc}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: 'capitalize' }}
                  >
                    {item?.category_id?.name}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: 'capitalize' }}
                  >
                    {item?.status}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          handleDelete(item?._id || '');
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default ObseleteToolList;
