import React, { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { IProcess } from '../../helpers/Interface';
import { spclChar } from '../../helpers/SpecialCharacters';

interface IProps {
  moduleData: any;
}
let isView = false;
const colSpanBodyHeader = (head: string) => {
  if (head === 'Charecteristics / Parameters') return 3;
  if (head === 'Methods') return 6;
  return 1;
};
const TBody = ({ moduleData }: IProps) => {
  const [render, setRender] = useState(false);

  const checkRevisionChange = (value: string | undefined) => {
    if (!value) return;
    let isRevisionForValue = false;
    moduleData.revisionData.forEach((revision: any) => {
      if (revision.summary?.split('𝗧𝗢')[1]?.includes(value)) {
        isRevisionForValue = true;
      }
    });
    if (isRevisionForValue) return 'yellow';
  };
  return (
    <tbody>
      <tr>
        {[
          'Part/ Process Number',
          'Process Name/ Operation Description',
          'Machine,  Device, Jigs, Tools for Mfg.',
          'Charecteristics / Parameters',
          'Spl. Char. Class',
          'Methods',
          'Resp.',
          'Reaction Plan',
          'Corrective Action',
          'Process Capability',
        ].map((head, index) => (
          <th
            colSpan={colSpanBodyHeader(head)}
            rowSpan={index === 3 || index === 5 ? 1 : 3}
            key={index + head}
          >
            {head}
          </th>
        ))}
      </tr>
      <tr>
        {[
          'No.',
          'Product',
          'Process',
          'Product/Process Specification/ Tolerance.',
          'Evalution Mesurements Techniques',
          'Error Proofing',
          'Sample',
          'Current Process Controls',
        ].map((head, index) => (
          <th
            colSpan={index === 6 ? 2 : 1}
            rowSpan={index === 6 ? 1 : 2}
            key={index + head}
          >
            {head}
          </th>
        ))}
      </tr>
      <tr>
        <th colSpan={1}>Size</th>
        <th colSpan={1}>Freq</th>
      </tr>
      {moduleData.processes &&
        moduleData.processes.map((process: IProcess, index: number) => (
          <React.Fragment key={index}>
            {process.specs.map((spec, indx) => (
              <React.Fragment key={indx}>
                <tr>
                  {indx === 0 && (
                    <>
                      <td rowSpan={process.specs.length} align="center">
                        {process.process_no}
                      </td>
                      <td rowSpan={process.specs.length} align="center">
                        {process.process_name}
                      </td>
                    </>
                  )}
                  {spec?.machine_include && (
                    <td
                      align="center"
                      style={{ border: '1px solid black' }}
                      rowSpan={(spec?.machine_rowSpan as number) || 1}
                    >
                      {spec?.machine}
                      {spec?.machine && spec?.tool_no ? '/' : ''}
                      {spec?.tool_no}
                    </td>
                  )}

                  <td align="center" style={{ border: '1px solid black' }}>
                    {indx + 1}
                  </td>

                  {/* product or process */}
                  {spec?.char_product_include && (
                    <td
                      align="center"
                      style={{
                        border: '1px solid black',
                        background: checkRevisionChange(
                          spec?.char_product?.toString()
                        ),
                      }}
                      rowSpan={(spec?.char_product_rowSpan as number) || 1}
                    >
                      {spec?.char_product}
                    </td>
                  )}
                  {spec?.char_process_include && (
                    <td
                      align="center"
                      style={{
                        border: '1px solid black',
                        background: checkRevisionChange(
                          spec?.char_process?.toString()
                        ),
                      }}
                      rowSpan={(spec?.char_process_rowSpan as number) || 1}
                    >
                      {spec?.char_process}
                    </td>
                  )}

                  <td
                    align="center"
                    style={{
                      border: '1px solid black',
                      background: checkRevisionChange(
                        spec?.special_character?.toString()
                      ),
                    }}
                  >
                    {spec.special_character && spclChar[spec.special_character]}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: '1px solid black',
                      background: checkRevisionChange(
                        spec?.control_product?.toString()
                      ),
                    }}
                  >
                    {spec.control_product}
                    {spec.control_process && spec.control_product ? '/' : ''}
                    {spec.control_process}
                    {spec.control_product_remark &&
                      `* ${spec.control_product_remark}`}
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                      background: checkRevisionChange(
                        spec?.methods_measure?.toString()
                      ),
                    }}
                    align="center"
                  >
                    {spec.methods_measure}
                  </td>

                  {spec?.error_proofing_include && (
                    <td
                      align="center"
                      style={{
                        border: '1px solid black',
                        background: checkRevisionChange(
                          spec?.error_proofing?.toString()
                        ),
                      }}
                      rowSpan={(spec?.error_proofing_rowSpan as number) || 1}
                    >
                      {spec.error_proofing}
                    </td>
                  )}

                  {/* Sample Size	 */}
                  {spec?.methods_size_include && (
                    <td
                      align="center"
                      style={{
                        border: '1px solid black',
                        background: checkRevisionChange(
                          spec?.methods_size?.toString()
                        ),
                      }}
                      rowSpan={(spec?.methods_size_rowSpan as number) || 1}
                    >
                      {spec?.methods_size}
                    </td>
                  )}

                  {spec?.methods_by_include && (
                    <td
                      align="center"
                      style={{
                        border: '1px solid black',
                        background: checkRevisionChange(
                          spec?.methods_by?.toString()
                        ),
                      }}
                      rowSpan={(spec?.methods_by_rowSpan as number) || 1}
                    >
                      {spec?.methods_by}
                    </td>
                  )}

                  {/* CONTROL METHOD	 */}
                  {spec?.methods_process_include && (
                    <td
                      align="center"
                      style={{
                        border: '1px solid black',
                        background: checkRevisionChange(
                          spec?.methods_process?.toString()
                        ),
                      }}
                      rowSpan={(spec?.methods_process_rowSpan as number) || 1}
                    >
                      {spec?.methods_process}
                    </td>
                  )}

                  {spec?.responsibility_include && (
                    <td
                      align="center"
                      style={{
                        border: '1px solid black',
                        background: checkRevisionChange(
                          spec?.responsibility?.toString()
                        ),
                      }}
                      rowSpan={(spec?.responsibility_rowSpan as number) || 1}
                    >
                      {spec?.responsibility}
                    </td>
                  )}

                  {spec?.reaction_plan_include && (
                    <td
                      align="center"
                      style={{
                        border: '1px solid black',
                        background: checkRevisionChange(
                          spec?.reaction_plan?.toString()
                        ),
                      }}
                      rowSpan={(spec?.reaction_plan_rowSpan as number) || 1}
                    >
                      {spec?.reaction_plan}
                    </td>
                  )}

                  {spec?.corrective_action_include && (
                    <td
                      align="center"
                      style={{
                        border: '1px solid black',
                        background: checkRevisionChange(
                          spec?.corrective_action?.toString()
                        ),
                      }}
                      rowSpan={(spec?.corrective_action_rowSpan as number) || 1}
                    >
                      {spec.corrective_action}
                    </td>
                  )}
                  {spec?.process_capability_include && (
                    <td
                      align="center"
                      style={{
                        border: '1px solid black',
                        background: checkRevisionChange(
                          spec?.process_capability?.toString()
                        ),
                      }}
                      rowSpan={
                        (spec?.process_capability_rowSpan as number) || 1
                      }
                    >
                      {spec.process_capability}
                    </td>
                  )}
                </tr>
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
    </tbody>
  );
};

export default TBody;
