import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InputMuiCustom from '../../components/common/inputs/InputMuiCustom';
import moment from 'moment';
import DatePickerMui from '../../components/common/DatePickerMui';
import AutocompleteMuiCustom from '../../components/common/AutocompleteMuiCustom';
import axios from 'axios';

interface IArray {
  [key: string]: any;
}

const CreateHeader = ({
  customer_name,
  partName,
  partNumber,
  msaData,
  process,spec,setSpec,autoCompleteProcess,setAutoCompleteProcess,setAutoCompleteSpec,autoCompleteSpec,setInstrumentType,instrumentType
}: any) => {
  const [render, setRender] = useState<Boolean>(false);
  const [instrumentData, setInstrumentData] = useState<any>([]);
  const [instrumentId,setInstrumentId]=useState<any>([])
  useEffect(() => {
    axios
      .get(`/api/msaRoute/masterListInstrument`)
      .then((res) => {
        let temp0 = res.data;
        const temp1:IArray={};
       
        temp0.forEach((t:any)=>{
          const key=t?.instrument_type||'';
          if(!temp1[key]){
            temp1[key]=[];
          }
          temp1[key].push(t)
         
        })
        temp0=[];
        for (const [key, value] of Object.entries(temp1)) {
          temp0.push({'instrument_type':key,'value':value})
          // console.log(key, value);
        }
        setInstrumentData(temp0);

        // console.log(temp0,'temp')

      })
      .catch((err) => console.log(err));
  }, []);
  // console.log(instrumentData,'llllllllllllll')
  // console.log(msaData,'lllllllltrtrllllll')

  return (
    <>
      <Box sx={{ flexGrow: 1, width: '98%', margin: '2rem auto' }}>
        <Grid container spacing={2}>
          
          <Grid item xs={2}>
            <InputMuiCustom
              type="string"
              name="part_name"
              variant={'outlined'}
              placeholder={'Part Name'}
              value={partName || ''}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <InputMuiCustom
              type="string"
              name="part_no"
              variant={'outlined'}
              placeholder={'Part No.'}
              value={partNumber || ''}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item xs={2}>
            <AutocompleteMuiCustom
              id="category_id.name"
              label="Instrument Category"
              option_name="instrument_type"
              arrayofObj={instrumentData}
              value={instrumentType||''}
              onChange={(e, value) => {
                if(value===null || value==='')return
                // msaData.instrument_type = value;
                setInstrumentType({'instrument_type':value?.instrument_type})
                setInstrumentId(value?.value)
                setRender((prev) => !prev);
              }}
            />
          </Grid>

          <Grid item xs={2}>
            <AutocompleteMuiCustom
              id="category_id_no"
              label='INS. CODE'
              option_name="id_no"
              arrayofObj={instrumentId}
              value={msaData?.instrument_id || ''}
              onChange={(e, value) => {
                msaData.instrument_id = value;
             
                setRender((prev) => !prev);
              }}
            />
          </Grid>
          {/* <Grid item xs={2}>
            <InputMuiCustom
              variant={'outlined'}
              type="text"
              name="id_no"
              placeholder={'INS. CODE'}
              value={msaData?.instrument_id?.id_no|| ''}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>         */}
          
          <Grid item xs={2}>
              <AutocompleteMuiCustom
                id={'process_name'}
                label="OPERATION/PROCESS NAME"
                option_name={'process_name'}
                value={autoCompleteProcess}
                arrayofObj={process}
                onChange={(e, value) => {
                  value?.process_name
                    ? (msaData.process_id = value._id)
                    : (msaData.process_id = '');
                  setAutoCompleteProcess(value);
                  value?.specs ? setSpec(value.specs) : setSpec([]);
                  setAutoCompleteSpec('');
                  setRender((prev) => !prev);

                  
                }}
              />
            </Grid>
            
            <Grid item xs={2}>
              <AutocompleteMuiCustom
                id={'charecteristics'}
                label={'Characteristics'}
                option_name={'charecteristics'}
                value={autoCompleteSpec}
                arrayofObj={spec}
                onChange={(e, value) => {
                  value?._id
                    ? (msaData.spec_id = value._id)
                    : (msaData.spec_id = '');
                  setAutoCompleteSpec(value);
                  // msaData.instrument=value?.instrument;
                  // msaData.utolerance=value?.utolerance;
                  // msaData.ltolerance=value?.ltolerance;
                  msaData.control_product=value?.control_product;
                  // if(value!==null){cal()}
                  setRender((prev) => !prev);
                  
                  // console.log(value)
                }}
              />
            </Grid>

            {/* <Grid item xs={2}>
            <InputMuiCustom
              variant={'outlined'}
              type="text"
              name="characteristics"
              placeholder={'Characteristics'}
              onChange={(e) => {
                msaData[e.target.name] = e.target.value;
                setRender((prev) => !prev);
              }}
              value={msaData.characteristics}
            />
          </Grid> */}

         
        </Grid>

  

        <Grid container spacing={2} sx={{ mt: 0.2 }}>
        <Grid item xs={3}>
              <InputMuiCustom
                variant={'outlined'}
                type="text"
                name="control_product"
                placeholder={`DIMN.`}
                value={msaData.control_product}

                onChange={(e) => {
                  msaData.control_product = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </Grid>
        {/* <Grid item xs={2}>
            <InputMuiCustom
              variant={'outlined'}
              type="text"
              name="dimn"
              placeholder={'DIMN.'}
              value={msaData?.dimn || ''}
              onChange={(e) => {
                msaData.dimn = e.target.value;
                setRender((prev) => !prev);
              }}
            
            />
          </Grid> */}
         
          <Grid item xs={2}>
            <InputMuiCustom
              variant={'outlined'}
              type="text"
              name="appraisers_A"
              placeholder={'Appraisers A'}
              onChange={(e) => {
                msaData[e.target.name] = e.target.value;
                setRender((prev) => !prev);
              }}
              value={msaData.appraisers_A}
            />
          </Grid>
          <Grid item xs={2}>
            <InputMuiCustom
              variant={'outlined'}
              type="text"
              name="appraisers_B"
              placeholder={'Appraisers B'}
              onChange={(e) => {
                msaData[e.target.name] = e.target.value;
                setRender((prev) => !prev);
              }}
              value={msaData.appraisers_B}
            />
          </Grid>
          <Grid item xs={2}>
            <InputMuiCustom
              variant={'outlined'}
              type="text"
              name="appraisers_C"
              placeholder={'Appraisers C'}
              onChange={(e) => {
                msaData[e.target.name] = e.target.value;
                setRender((prev) => !prev);
              }}
              value={msaData.appraisers_C}
            />
          </Grid>
          <Grid item xs={2}>
            <DatePickerMui
              label="Date"
              value={moment(msaData.date || null)}
              onChange={(date) => {
                msaData.date = date;
                setRender((prev) => !prev);
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CreateHeader;
function useSate(arg0: boolean): [any, any] {
  throw new Error('Function not implemented.');
}
