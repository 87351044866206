import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import useConfirm from '../../components/common/useConfirm';
import AutocompleteGridmui from '../../components/common/autocomplete/AutocompleteGridmui';
import { DivStyled } from '../../assets/styles/components/DivStyledLableInputs';
import CustomUploadButton from '../../components/common/button/CustomUploadButton';
import GridDatePicker from '../../components/common/inputs/GridDatePicker';
const SubDepartmentField = ({ moduleData, departmentId }: any) => {
  const [render, setRender] = useState(false);
  const [subDepartmentData, setSubDepartmentData] = useState([]);
  useEffect(() => {
    if (departmentId) {
      axios
        .get(`/api/subdepartment/getSubDepartmentBydepartment/${departmentId}`)
        .then((res) => {
          setSubDepartmentData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [departmentId]);

  return (
    <AutocompleteGridmui
      label={'Sub-Department Name:'}
      id={'sup_department_name'}
      option_name={'name'}
      value={moduleData.sub_department_id || ''}
      arrayofObj={subDepartmentData}
      onChange={(e, value) => {
        moduleData.sub_department_id = value;
        setRender((prev) => !prev);
      }}
      last_child={2}
    />
  );
};

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setModuleData] = useState<any>({
    employee_name: '',
  });
  const [employeeTypeData, setEmployeeTypeData] = useState([]);
  const [qualificationData, setQualificationData] = useState([]);
  const [designationData, setDesignationData] = useState([]);
  const [skillLevelData, setSkillLevelData] = useState([]);

  const [render, setRender] = useState(false);
  const [image, setImage] = useState(null);
  const [department, setDepartment] = useState([]);
  const [autoCompleteDepartment, setAutocompleteDepartment] = useState('');
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Employee' : 'Create Employee'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/employee/${id}`)
        .then((res) => {
          setModuleData(res.data);
          setAutocompleteDepartment(res.data.department_id);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleModulData(e: React.ChangeEvent<HTMLInputElement>) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    if (!moduleData.department_id) {
      return alert('Please! also pick department');
    }
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/employee/${id}`, moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/employee', moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          departmentRes,
          designationRes,
          qualificationRes,
          employeeTypeRes,
          skillLevelRes,
        ] = await Promise.all([
          axios.get(`/api/department`),
          axios.get(
            `/api/masterListDesignationRoute/designation/designationForAutoComplete`
          ),
          axios.get(
            `/api/masterListQualificationRoute/qualitficationList/forAutoComplete`
          ),
          axios.get(
            `/api/masterListEmployeeTypeRoute/employeeTypeList/forAutoComplete`
          ),
          axios.get(
            `/api/masterListSkillLevelRoute/skillLevelList/forAutoComplete`
          ),
        ]);

        setDepartment(departmentRes.data.results);
        setDesignationData(designationRes.data);
        setQualificationData(qualificationRes.data);
        setEmployeeTypeData(employeeTypeRes.data);
        setSkillLevelData(skillLevelRes.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <GridInputs
        id={'employee_name'}
        name={'employee_name'}
        html_for={'employee_name'}
        label_name={'Employee Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.employee_name}
        onChange={handleModulData}
      />
      <GridInputs
        id={'employee_code'}
        name={'employee_code'}
        html_for={'employee_code'}
        label_name={'Employee Code:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.employee_code}
        onChange={handleModulData}
      />
      <AutocompleteGridmui
        label={'Designation:'}
        id={'designation_id'}
        option_name={'designation'}
        value={moduleData.designation_id || ''}
        arrayofObj={designationData}
        onChange={(e, value) => {
          moduleData.designation_id = value;
          setRender((prev) => !prev);
        }}
        last_child={2}
      />
      <GridDatePicker
        label_name="DOJ"
        html_for={'doj'}
        value={moduleData?.doj || null}
        onChange={(date: any) => {
          moduleData.doj = date;
          setRender((prev) => !prev);
        }}
      />
      <AutocompleteGridmui
        label={'Skill Level:'}
        id={'skill_level_id'}
        option_name={'name'}
        value={moduleData.skill_level_id || ''}
        arrayofObj={skillLevelData}
        onChange={(e, value) => {
          moduleData.skill_level_id = value;
          setRender((prev) => !prev);
        }}
        last_child={2}
      />
      <AutocompleteGridmui
        label={'Employee Type:'}
        id={'employee_type'}
        option_name={'name'}
        value={moduleData.employee_type_id || ''}
        arrayofObj={employeeTypeData}
        onChange={(e, value) => {
          moduleData.employee_type_id = value;
          setRender((prev) => !prev);
        }}
        last_child={2}
      />
      <AutocompleteGridmui
        label={'Qualification:'}
        id={'qualification'}
        option_name={'name'}
        value={moduleData.qualification_id || ''}
        arrayofObj={qualificationData}
        onChange={(e, value) => {
          moduleData.qualification_id = value;
          setRender((prev) => !prev);
        }}
        last_child={2}
      />
      <AutocompleteGridmui
        label={'Department Name:'}
        id={'department_name'}
        option_name={'department_name'}
        value={moduleData.department_id || ''}
        arrayofObj={department}
        onChange={(e, value) => {
          moduleData.department_id = value;
          setRender((prev) => !prev);
        }}
        last_child={2}
      />
      <SubDepartmentField
        {...{ moduleData }}
        departmentId={moduleData?.department_id?._id}
      />

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
