import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import TBodyRight from './TBodyRight';
import formatDate from '../../../../../../components/common/formatDate';
import { Grid } from '@mui/material';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../../components/common/button/CustomIconButton';
import TBodyRightPDate from './TBodyRightPDate';
import AutocompleteMuiCustom from '../../../../../../components/common/AutocompleteMuiCustom';
import {
  StyledTableBodyTh1,
  StyledTableBodyTh2,
  StyledTableBodyTh3,
} from '../../../../../../components/ui/tableFreeze/ColumnFrezze.styled';

interface IProps {
  moduleData: any;
  allmonth: any;
  isView: boolean;
  setIsOpenPopup: any;
  setPartIdx: any;
  setRowName: any;
  handleDeleteRows: any;
  machines: any;
}
const TBody = ({
  moduleData,
  isView,
  allmonth,
  setIsOpenPopup,
  setPartIdx,
  setRowName,
  handleDeleteRows,
  machines,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: number) => (
        <>
          <tr key={`${index}_table_row`}>
            <StyledTableBodyTh1 bgColor={'#ffec99'} rowSpan={4}>
              {index + 1}
            </StyledTableBodyTh1>
            <StyledTableBodyTh2 bgColor={'#ffec99'} rowSpan={4}>
              {isView ? (
                item?.machine_id?.machine_no
              ) : (
                <AutocompleteMuiCustom
                  id="Machine"
                  label=""
                  key={'machine'}
                  option_name="machine_no"
                  arrayofObj={machines}
                  value={item?.machine_id || ''}
                  onChange={(e, value) => {
                    item.machine_id = value;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </StyledTableBodyTh2>
            <StyledTableBodyTh3 bgColor={'#ffec99'} rowSpan={4}>
              {isView ? (
                item?.a1 && item?.a1?.part_name + ' & ' + item.a1?.part_number
              ) : (
                <Grid container xs={12} direction="row" alignItems={'center'}>
                  <Grid item xs={10}>
                    <p
                      style={{
                        fontSize: '1.2rem',
                      }}
                    >
                      {typeof item?.a1 === 'object' && item?.a1 !== null
                        ? item?.a1?.part_name + ' & ' + item.a1?.part_number
                        : (item?.a1_partNumber &&
                            item?.a1_partName + ' & ' + item?.a1_partNumber) ||
                          ''}
                      {!item?.a1 && 'Pick Part No.'}
                    </p>
                  </Grid>

                  {item.a1 ? (
                    <Grid item xs={2}>
                      <RemoveIconButton
                        tooltipTitle="remove part"
                        onClick={() => {
                          item.a1 = null;
                          item.a1_partNumber = null;
                          item.a1_partName = null;
                          setRender((prev) => !prev);
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={2}>
                      <AddIconButton
                        tooltipTitle="add part"
                        onClick={() => {
                          setIsOpenPopup(true);
                          setRowName('a1');
                          setPartIdx(index);
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </StyledTableBodyTh3>
            <td>P</td>
            {/* always constant ⬇️*/}

            <TBodyRight
              item={item}
              isView={isView}
              index={index}
              allmonth={allmonth}
              setTBodyRender={setRender}
            />
            <td rowSpan={4}>
              <TableInput
                isView={isView}
                style={{ height: '80px' }}
                name="remarks"
                type="text"
                value={item.remarks}
                onChange={(e) => {
                  item.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td rowSpan={4} style={{ minWidth: '100px', textAlign: 'center' }}>
              {!isView && (
                <DeleteIconButton
                  tooltipTitle={'delete'}
                  onClick={() => handleDeleteRows(index)}
                />
              )}
            </td>
          </tr>
          <tr>
            <th>Planned Date</th>
            {/* always constant ⬇️*/}
            <TBodyRightPDate
              item={item}
              isView={isView}
              index={index}
              moduleData={moduleData}
              setTBodyRender={setRender}
            />
          </tr>
          <tr>
            <th>A</th>
            {allmonth.map((month: any, mIdx: number) => (
              <td
                width="80px"
                height="45px"
                style={{
                  backgroundColor: item[month].doneDate && '#37b24d',
                  color: 'white',
                  textTransform: 'capitalize',
                  fontSize: '1.6rem',
                }}
              >
                {item[month].isTask && 'A'}
              </td>
            ))}
          </tr>
          <tr>
            <td>Actual Date</td>
            {allmonth.map((month: any, mIdx: number) => (
              <td
                width="80px"
                height="45px"
                style={{
                  backgroundColor: item[month].doneDate && '#37b24d',
                  color: 'white',
                  textTransform: 'capitalize',
                  fontSize: '1.6rem',
                }}
              >
                {/* {item[month].isTask && 'A'} */}
                {formatDate(item[month].doneDate) || null}
              </td>
            ))}
          </tr>
          {/* ---x---- */}
        </>
      ))}
    </tbody>
  );
};

export default TBody;
