import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import { IData } from './Interface';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import useConfirm from '../../components/common/useConfirm';
import AutocompleteGridmui from '../../components/common/autocomplete/AutocompleteGridmui';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setModuleData] = useState<IData>({
    name: '',
    hod: '',
  });
  const [department, setDepartment] = useState([]);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Supplier' : 'Create Supplier'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`,
  );

  useEffect(() => {
    axios
      .get(`/api/department/department/departmentForAutoComplete`)
      .then((res) => setDepartment(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/subdepartment/${id}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);
  function handleDepartment(e: React.ChangeEvent<HTMLInputElement>) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/subdepartment/${id}`, moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/subdepartment', moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <GridInputs
        id={'name'}
        name={'name'}
        html_for={'name'}
        label_name={'Sub Department Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.name}
        onChange={handleDepartment}
      />
      <GridInputs
        id={'hod'}
        name={'hod'}
        html_for={'hod'}
        label_name={'Head Of Department:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.hod}
        onChange={handleDepartment}
      />
      <AutocompleteGridmui
        label={'Department:'}
        id={'department_id'}
        option_name={'department_name'}
        value={moduleData.department_id || ''}
        arrayofObj={department}
        onChange={(e, value) => {
          let values = { ...moduleData };
          values.department_id = value;
          setModuleData(values);
        }}
        last_child={2}
      />

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
