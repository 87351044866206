import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import React, { ReactNode } from 'react';
import { ButtonStyled, TableContainerStyled } from './styled';
import { Box } from '@mui/material';

interface IProps {
  children: ReactNode;
  scroll?: String;
}

const TableScroll = ({ children, scroll, ...rest }: IProps) => {
  const handleScroll = (scrolltype: string): void => {
    const table = document.getElementById('machinetable');
    if (table) {
      if (scrolltype === 'right') {
        table.scrollLeft += 80;
      }
      if (scrolltype === 'left') {
        table.scrollLeft -= 80;
      }
    }
  };

  return (
    <>
      {scroll === 'horizontal' ? (
        <Box style={{ overflow: 'auto', whiteSpace: 'nowrap' }}>{children}</Box>
      ) : (
        <main {...rest}>
          <ButtonStyled left={true} onClick={() => handleScroll('left')}>
            <div>
              <ArrowBackIos style={{ color: '#fff' }} />
            </div>
          </ButtonStyled>
          <ButtonStyled right={true} onClick={() => handleScroll('right')}>
            <div>
              <ArrowForwardIos style={{ color: '#fff' }} />
            </div>
          </ButtonStyled>
          <TableContainerStyled id="machinetable">
            {children}
          </TableContainerStyled>
        </main>
      )}
    </>
  );
};

export default TableScroll;
