import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
import { convToTime } from '../../../../utils/helpers';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';



const THead = ( ) => {
  const [render, setRender] = useState(false);
  return (
    <thead>

        <th>Group No</th>
        {Array.from({ length: 25 }, (_, row) => (
            <th>{row+1}</th>
        ))
}
    </thead>
  );
};

export default THead;
