import axios from 'axios';
import { useState, useEffect } from 'react';
import { apiConstant } from '../../../constant';
import lodash from 'lodash';
import { checklistData } from './checklist';

const useGetData = (id: string | undefined) => {
  const [moduleData, setModuleData] = useState<any>({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      axios
        .get(`/api/${apiConstant.checklistData}/${id}`)
        .then((res) => {
          setModuleData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    } else {
      setModuleData({
        c1: lodash.cloneDeep(checklistData),
      });
    }
  }, []);

  return { moduleData, isLoading, error };
};

export default useGetData;
