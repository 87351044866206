interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
}
const TFoot = ({ preparedBy, approvedBy }: Ifoot) => {
  return (
    <tfoot>
      <tr>
        <th colSpan={15} align="left">
          Prepared By: {preparedBy}
        </th>
        <th colSpan={15} align="left">
          Approved By: {approvedBy}
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
