import Masterlistofmachine from '../../pages/masterlistofMachine/List';
import {
  Clause10,
  ManMachineMatrix,
  ManMachineMatrixList,
} from '../../pages/clause10Page';
import CreateMachineHistory from '../../pages/masterlistofMachine/machineHistory/CreateMachineHistory';

// main route of clause 10
export const clause10 = [
  {
    path: 'clause10',
    element: <Clause10 />,
  },
  //Man Machine Matrix
  {
    path: 'manMachineMatrixList',
    element: <ManMachineMatrixList />,
  },
  {
    path: 'manMachineMatrix/:subDepartmentId',
    element: <ManMachineMatrix />,
  },
  {
    path: 'masterlistofmachine',
    element: <Masterlistofmachine />,
  },
  {
    path: 'machineHistory/:id',
    element: <CreateMachineHistory />,
  },
];
