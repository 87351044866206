import React, { useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';

import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
} from '../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { IData } from './helpers/Interface';
import StageList from './StageList';
import { usePermission } from '../../../utils/helpers/usePermission';
const tableHeaderObj = [
  [
    { text: 'S.NO.', rowspan: 1, colspan: 1 },
    { text: 'DEFECT NAME', rowspan: 1, colspan: 1 },
    { text: 'STAGE', rowspan: 1, colspan: 1 },
    /*{ text: 'Product', rowspan: 1, colspan: 1 },
                            { text: 'Type Of Industry', rowspan: 1, colspan: 1 },
                            { text: 'Remarks', rowspan: 1, colspan: 1 },*/
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [
  { value: 'createdAt', name: 'A-Z' },
  { value: 'defect_name', name: 'Defect Name' },
];

const List = () => {
  const permissions = usePermission(16);

  const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [defectId, setDefectId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;
  const [isStageModal, setIsStageModal] = useState(false);

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE DEFECT',
    'Are you sure you want to delete this?'
  );

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/defectRoutes/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteDefect = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/defectRoutes/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, isModal, filter]);

  return (
    <>
      <ChildHeader text="DEFECT LIST">
        {permissions.includes('edit') && (
          <>
            <AddButton onClick={() => setIsModal(true)} label="New Defect" />
            <AddButton onClick={() => setIsStageModal(true)} label="STAGE" />
          </>
        )}
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <FilterBy options={filterOptions} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: IData, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item?.defect_name}</TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: 'capitalize' }}
                  >
                    {item?.stage_id?.name}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      {permissions.includes('edit') && (
                        <EditIconButton
                          tooltipTitle="EDIT"
                          onClick={() => {
                            setDefectId(item?._id || '');
                            setIsModal(true);
                          }}
                        />
                      )}

                      {permissions.includes('delete') && (
                        <DeleteIconButton
                          tooltipTitle="DELETE"
                          onClick={() => {
                            deleteDefect(item?._id || '');
                          }}
                        />
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setDefectId(null);
          setIsModal(false);
        }}
        title="DEFECT"
      >
        <Create id={defectId} setIsModal={setIsModal} />
      </ModalCustom>
      <ModalCustom
        openModal={isStageModal}
        closeModal={() => {
          setIsStageModal(false);
        }}
        title="STAGE LIST"
      >
        <StageList />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
