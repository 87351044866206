import React, { useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';

import { AddButton } from '../../components/common/button/CustomButtons';
import ChildHeader from '../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../components/common/FilterBy';
import { SearchBar } from '../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../components/common';
import useDebounce from '../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
} from '../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../components/common/Modal';
import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';
import { IData } from './helpers/Interface';
import { changingRowColor } from './helpers/changingRowColor';
const tableHeaderObj = [
  [
    { text: 'S.No.', rowspan: 1, colspan: 1 },
    { text: 'Name', rowspan: 1, colspan: 1 },
    { text: 'Description', rowspan: 1, colspan: 1 },
    { text: 'UOM', rowspan: 1, colspan: 1 },
    { text: 'Minimum Qty', rowspan: 1, colspan: 1 },
    { text: 'Minimum Qty Remarks', rowspan: 1, colspan: 1 },
    { text: 'Maximum Qty', rowspan: 1, colspan: 1 },
    { text: 'Maximum Qty Remarks', rowspan: 1, colspan: 1 },
    { text: 'Reorder Level', rowspan: 1, colspan: 1 },
    { text: 'Remarks', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [
  { value: 'createdAt', name: 'Most Recent' },
  { value: 'spare_part_desc', name: 'A-Z' },
];

const List = () => {
  const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [moduleId, setModuleId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE ',
    'Are you sure you want to delete this?',
  );

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/criticalSparePartsRoute/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}`,
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteCustomer = async (id: string | null) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/criticalSparePartsRoute/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, isModal, filter]);

  return (
    <>
      <ChildHeader text="CRITICAL SPARE PARTS LIST">
        <AddButton onClick={() => setIsModal(true)} label="New SPARE PARTS" />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search Name And Description" />
        <FilterBy options={filterOptions} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: IData, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow
                  sx={{
                    background: changingRowColor(
                      item.reorder_level || 0,
                      item.min_stock_qty || 0,
                    )
                      ? '#ffc9c9'
                      : '',
                  }}
                >
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item.spare_part_name}</TableCell>
                  <TableCell align="center">{item.spare_part_desc}</TableCell>
                  <TableCell align="center">{item.opening_stock_UOM}</TableCell>
                  <TableCell align="center">{item.min_stock_qty}</TableCell>
                  <TableCell align="center">{item.min_qty_remarks}</TableCell>
                  <TableCell align="center">{item.max_stock_qty}</TableCell>
                  <TableCell align="center">{item.max_qty_remarks}</TableCell>
                  <TableCell align="center">{item.reorder_level}</TableCell>
                  <TableCell align="center">{item.remarks}</TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setModuleId(item?._id || null);
                          setIsModal(true);
                        }}
                      />

                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          deleteCustomer(item?._id || '');
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setModuleId(null);
          setIsModal(false);
        }}
        title="Critical Spare Parts"
      >
        <Create id={moduleId} setIsModal={setIsModal} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
