import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { DatePicker, DateView } from '@mui/x-date-pickers';
import queryString from 'query-string';

import { useQuery } from '../../hooks/UseQuery';
import { useEffect } from 'react';

interface IProps {
  label: string;
  views?: readonly DateView[] | undefined;
  format?: string | undefined;
}
const PersitedDatePicker = ({ label, views, format }: IProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const date = useQuery('date');
  useEffect(() => {
    if (!date) {
      const parsedQuery = queryString.parse(location.search);
      parsedQuery.date = new Date().toString();
      const query = queryString.stringify(parsedQuery);
      navigate(`${location.pathname}?${query}`);
    }
  }, []);
  const handleDate = (date: any) => {
    const parsedQuery = queryString.parse(location.search);
    parsedQuery.date = date?._d;
    const query = queryString.stringify(parsedQuery);
    navigate(`${location.pathname}?${query}`);
  };
  return (
    <DatePicker
      onChange={handleDate}
      value={moment(date)}
      views={views}
      label={label}
      format={format}
      slotProps={{
        // pass props `actions={['clear']}` to the actionBar slot
        actionBar: { actions: ['clear'] },
      }}
    />
  );
};

export default PersitedDatePicker;
