import {
  Box,
  MenuItem,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import axios from 'axios';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { usePermission } from '../../../utils/helpers/usePermission';

import {
  CustomPagination,
  PersitedDatePicker,
  TableCustom,
} from '../../../components/common';
import {
  AddButton,
  UpdateButton,
} from '../../../components/common/button/CustomButtons';
import ModalCustom from '../../../components/common/Modal';
import { SupervisorPartTable } from '../../../components/ui';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import { useAuth } from '../../../services/auth/AuthProvider';
import { listConstant, apiConstant, navigationConstant } from './constant';
import React from 'react';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import { useNavigate } from 'react-router-dom';
import CustomSelect from '../../../components/common/SelectField';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import { SelectStatus } from '../../addparts/Select';
import ViewFile from './ViewFile';

const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];
const getYearRange = (currentDate: any) => {
  if (!currentDate) return;
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};
const getDateOfTraining = (date: any, month: string) => {
  console.log(month);
  const year = new Date(date).getFullYear();
  return `${months.indexOf(month) + 1}/${year}`;
};

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Department Name', rowspan: 1, colspan: 1 },
    { text: 'Topic of Training', rowspan: 1, colspan: 1 },
    { text: 'Trainer', rowspan: 1, colspan: 1 },
    { text: 'Planned Hrs', rowspan: 1, colspan: 1 },
    { text: 'Date of Training', rowspan: 1, colspan: 1 },
    { text: 'Attach Proof', rowspan: 1, colspan: 1 },
    { text: 'Status', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const statusOptions = [
  { value: '1', label: 'PENDING', bgColor: '#FDD835', textColor: '#fff' },
  { value: '2', label: 'COMPLETED', bgColor: '#2b8a3e', textColor: 'inherit' },
  { value: '3', label: 'OVERDUE', bgColor: '#c80202', textColor: '#fff' },
];

const TableList = () => {
  const permissions = usePermission(31);
  const { state } = useAuth();
  const { user } = state;
  const date = useQuery('date');
  const [tableData, setTableData] = useState<any[]>([]);
  const [listId, setListId] = useState('');
  const [render, setRender] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [docFiles, setDocFiles] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const idRef = useRef<any>({});
  const navigate = useNavigate();
  const processIndexRef = useRef<any>('');
  const monthRef = useRef('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    axios
      .get(`/api/${apiConstant.gettableDataurl}/${date}`)
      .then((res) => {
        if (res.data) setTableData(res.data);
      })
      .catch((err) => console.log(err));
  }, [render, date, isOpenPopup]);

  const handleDelete = async (id: string) => {
    let isDeleted = window.confirm(
      'Are you sure you want to delete this document?',
    );
    if (isDeleted) {
      axios
        .delete(`/api/${apiConstant.deleteurl}/${id}`)
        .then(() => {
          alert('document deleted successfully');
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
  };
  const uploadFile = async (e: any, id: any, processIndex: any, month: any) => {
    if (!window.confirm('Are you sure you want upload this Files')) return;
    const files = e.target.files;
    if (!files) return;
    let formData = new FormData();
    for (const key of Object.keys(files)) {
      formData.append('files', files[key]);
    }
    setIsLoading(true);

    axios
      .patch(
        `/api/${apiConstant.checklistUpload}/${id}/${processIndex}/${month}`,
        formData,
      )
      .then((res) => {
        if (res.data) {
          alert('document upload successly');
          setIsLoading(false);
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const handleChangeRequestStatus = (
    e: any,
    index: any,
    month: any,
    process: any,
  ) => {
    process.training_months[month].isSubmitted = e.target.value;
    setRender((prev) => !prev);
  };

  const updateTrainingStatus = (
    id: any,
    processIndex: any,
    month: any,
    isSubmitted: any,
  ) => {
    if (!window.confirm('Are you sure you want to update status?')) {
      return;
    }
    axios
      .patch(
        `/api/annualTrainingStatus/updateTrainingStatus/${id}/${processIndex}/${month}`,
        {
          isSubmitted: isSubmitted,
        },
      )
      .then((res) => {
        if (res.data) {
          alert('status updated successfully');
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteDocument = async (id: any, processIndex: any, month: any) => {
    if (!window.confirm('Are you sure you want to delete this document?')) {
      return;
    }
    axios
      .delete(
        `/api/annualTrainingStatus/deleteTrainingStatus/${id}/${processIndex}/${month}`,
      )
      .then((res) => {
        if (res.data) {
          alert('document deleted successfully');
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Box>
      <ChildHeader text={`${listConstant.childHeader}`}>
        {/* <AddButton
          label={`${listConstant.addButtonLable}`}
          onClick={() => setIsOpenPopup(true)}
        /> */}
      </ChildHeader>
      <Box sx={{ margin: 2 }}>
        <PersitedDatePicker
          label={`${listConstant.datePickerLabel}`}
          views={['year']}
        />
      </Box>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {tableData &&
            tableData.map((item: any, index: number) =>
              item.processes.map((process: any, processIndex: number) =>
                Object.keys(process.training_months).map(
                  (month, monthIndex) =>
                    process.training_months[month].isChecked && (
                      <React.Fragment key={item._id}>
                        <TableRow>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            {item.departments.department_name}
                          </TableCell>
                          <TableCell align="center">
                            {process.training_topic}
                          </TableCell>
                          <TableCell align="center">
                            {process.faculty?.faculty_name}
                          </TableCell>
                          <TableCell align="center">
                            {process.participant}
                          </TableCell>
                          <TableCell align="center">
                            {getDateOfTraining(item.created_year, month)}
                          </TableCell>
                          <TableCell style={{ width: '240px' }}>
                            <Stack
                              direction="row"
                              sx={{ justifyContent: 'center' }}
                            >
                              {permissions.includes('edit') && (
                                <CustomUploadButton
                                  accept="image/png,image/jpeg,image/jpg,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  id={`${index}_files_`}
                                  multiple={true}
                                  onChange={(e) => {
                                    uploadFile(
                                      e,
                                      item._id,
                                      processIndex,
                                      month,
                                    );
                                  }}
                                  // lable=""
                                />
                              )}
                              {process.training_months[month].files.length >
                              0 ? (
                                <>
                                  <ViewIconButton
                                    tooltipTitle="view"
                                    onClick={() => {
                                      idRef.current = item._id;
                                      processIndexRef.current = processIndex;
                                      monthRef.current = month;
                                      setIsOpenPopup(true);
                                    }}
                                  />
                                </>
                              ) : (
                                <ViewIconButton
                                  sx={{
                                    opacity: '0',
                                    PointerEvent: 'none',
                                    visibility: 'hidden',
                                  }}
                                  tooltipTitle=""
                                />
                              )}
                            </Stack>
                          </TableCell>
                          <TableCell align="center">
                            <select
                              style={{
                                cursor: 'pointer',
                                fontSize: '1.8rem',
                                outline: 'none',
                                border: 'none',
                                padding: '5px',
                                marginLeft: '0.5rem',
                                width: '17rem',
                                textAlign: 'center',
                                textTransform: 'capitalize',
                                ...(process.training_months[month]
                                  .isSubmitted === 'Pending' && {
                                  backgroundColor: '#FDD835',
                                }),
                                ...(process.training_months[month]
                                  .isSubmitted === 'Completed' && {
                                  backgroundColor: '#2b8a3e',
                                  color: '#fff',
                                }),
                                ...(process.training_months[month]
                                  .isSubmitted === 'Overdue' && {
                                  backgroundColor: '#c80202',
                                  color: '#fff',
                                }),
                              }}
                              name="isSubmited"
                              value={
                                process.training_months[month].isSubmitted ||
                                status
                              }
                              onChange={(e) => {
                                console.log(e.target.value);
                                process.training_months[month].isSubmitted =
                                  e.target.value;
                                // setRender((prev) => !prev);
                                setStatus(e.target.value);
                              }}
                            >
                              <option value="Pending">PENDING</option>
                              <option value="Overdue">OVERDUE</option>
                              <option value="Completed">COMPLETED</option>
                            </select>
                          </TableCell>
                          <TableCell align="center">
                            {permissions.includes('edit') && (
                              <UpdateButton
                                label="UPDATE STATUS"
                                size="small"
                                onClick={() =>
                                  updateTrainingStatus(
                                    item._id,
                                    processIndex,
                                    month,
                                    process.training_months[month].isSubmitted,
                                  )
                                }
                              />
                            )}
                            {permissions.includes('delete') && (
                              <DeleteIconButton
                                tooltipTitle="delete"
                                onClick={() =>
                                  deleteDocument(item._id, processIndex, month)
                                }
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ),
                ),
              ),
            )}
        </TableBody>
      </TableCustom>
      <ModalCustom
        title={`${listConstant.modalTile}`}
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
          // setListId('');
        }}
      >
        <ViewFile
          id={idRef.current}
          processIndex={processIndexRef.current}
          month={monthRef.current}
        />
      </ModalCustom>
    </Box>
  );
};

export default TableList;
