import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../../components/ui';
import { createConstant } from '../../constant';
import { useQuery } from '../../../../../../hooks/UseQuery';
import { StyledTableHeaderRow } from '../../../../../../components/ui/tableFreeze/RowFreeze.styled';
import {
  StyledTableHeaderTh1,
  StyledTableHeaderTh2,
  StyledTableHeaderTh3,
  StyledTableHeaderTh4,
} from '../../../../../../components/ui/tableFreeze/ColumnFrezze.styled';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '200px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};
interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const yearRange = useQuery('yearRange');
  const date = new Date(moduleData?.date_range)
    ?.getFullYear()
    .toString()
    .slice(-2);
  return (
    <thead>
      <TableCompHead
        colSpan={14 + createConstant.extracolSpan}
        rowSpan={4}
        labels={['Format No.:', 'Issue Date:', 'Rev. No.', 'Rev. Date:']}
        values={['F SYS 08A', '01/04/2019', '01', '01/09/2021']}
        heading={`${createConstant.theadHeader} (YEAR : ${yearRange})`}
      />
      <StyledTableHeaderRow>
        <StyledTableHeaderTh1 bgColor={'#4F81BD'} color={'#fff'}>
          S. No.
        </StyledTableHeaderTh1>
        <StyledTableHeaderTh2 bgColor={'#4F81BD'} color={'#fff'}>
          Blind Rivet
        </StyledTableHeaderTh2>
        <StyledTableHeaderTh3 bgColor={'#4F81BD'} color={'#fff'}>
          Rivet Body
        </StyledTableHeaderTh3>
        <StyledTableHeaderTh4 bgColor={'#4F81BD'} color={'#fff'}>
          Mandrel
        </StyledTableHeaderTh4>
        <th style={{ ...style1 }}>Plan/Actual</th>

        {[
          'APR',
          'MAY',
          'JUN',
          'JUL',
          'AUG',
          'SEP',
          'OCT',
          'NOV',
          'DEC',
          'JAN',
          'FEB',
          'MAR',
        ].map((el, index) => (
          <th
            key={`${index}_headcol`}
            align="center"
            style={{ textAlign: 'center', ...style2 }}
          >
            {el}-{index > 8 ? +date + 1 : date}
          </th>
        ))}
        <th style={{ textAlign: 'center', ...style1 }}>Remarks</th>
        <th style={{ textAlign: 'center', ...style1 }}>ACTION</th>
      </StyledTableHeaderRow>
    </thead>
  );
};

export default THead;
