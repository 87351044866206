import { AiOutlineCloudUpload, AiOutlinePartition } from 'react-icons/ai';
import { AiOutlineFundView } from 'react-icons/ai';
import { BsFillPeopleFill } from 'react-icons/bs';
import { VscServerProcess } from 'react-icons/vsc';
import { FcCustomerSupport, FcInspection } from 'react-icons/fc';
import { MdApproval } from 'react-icons/md';
import { TbFaceIdError, TbReport, TbReportMoney } from 'react-icons/tb';
import { FaHouseUser } from 'react-icons/fa';
import { PiFinnTheHumanFill } from 'react-icons/pi';

const tiles = [
  {
    sNo: 1,
    link: '/common/inHouseHome',
    isLink: true,
    icon: <FaHouseUser size="5rem" color="#087f5b" />,
    p: 'IN-HOUSE',
    span: 'COMPLAINT',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/defect_list?filter=createdAt',
    isLink: true,
    clauseNo: 8,
    icon: <TbFaceIdError size="5rem" color="#a12222" />,
    p: 'List of',
    span: 'DEFECTS',
    isIcon: true,
  },
  {
    sNo: 3,
    link: `/common/customerComplaintRegister`,
    isLink: true,
    clauseNo: 8,
    icon: <FcCustomerSupport size="5rem" color="#003566" />,
    p: 'CUSTOMER COMPLAINT',
    span: 'REGISTER',
    isIcon: true,
  },
  {
    sNo: 3,
    link: `/common/supplierComplaintRegister`,
    isLink: true,
    clauseNo: 8,
    icon: <PiFinnTheHumanFill size="5rem" color="#003566" />,
    p: 'SUPPLIER COMPLAINT',
    span: 'REGISTER',
    isIcon: true,
  },
  // {
  //   sNo: 2,
  //   link: '',
  //   isLink: true,
  //   icon: <PiFinnTheHumanFill size="5rem" color="red" />,
  //   p: 'SUPPLIER',
  //   span: 'COMPLAINT',
  //   isIcon: true,
  // },
  // {
  //   sNo: 3,
  //   link: '',
  //   isLink: true,
  //   icon: <FcCustomerSupport size="5rem" color="#087f5b" />,
  //   p: 'CUSTOMER',
  //   span: 'COMPLAINT',
  //   isIcon: true,
  // },
];

export default tiles;
