import { useState } from 'react';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';
import formatDate from '../../../../components/common/formatDate';

interface IProps {
  item: any;
  isView: boolean;
  index: number;
  moduleData: any;
  setTBodyRender: any;
}
const TBodyRightPDate = ({
  item,
  isView,
  index,
  moduleData,
  setTBodyRender,
}: IProps) => {
  const [render, setRender] = useState(false);

  const handleDateClick = async (date: any, month: any, idx: any) => {
    item[month].created_date = date;
    setTBodyRender((prev: any) => !prev);
  };

  return (
    <>
      {[
        ['april', 4, new Date(moduleData.date_range).getFullYear()],
        ['may', 5, new Date(moduleData.date_range).getFullYear()],
        ['june', 6, new Date(moduleData.date_range).getFullYear()],
        ['july', 7, new Date(moduleData.date_range).getFullYear()],
        ['august', 8, new Date(moduleData.date_range).getFullYear()],
        ['september', 9, new Date(moduleData.date_range).getFullYear()],
        ['october', 10, new Date(moduleData.date_range).getFullYear()],
        ['november', 11, new Date(moduleData.date_range).getFullYear()],
        ['december', 12, new Date(moduleData.date_range).getFullYear()],
        ['january', 1, new Date(moduleData.date_range).getFullYear() + 1],
        ['february', 2, new Date(moduleData.date_range).getFullYear() + 1],
        ['march', 3, new Date(moduleData.date_range).getFullYear() + 1],
      ].map((month: any) => (
        <td
          style={{
            padding: '0px',
            margin: '0px',
            backgroundColor: `${item[month[0]]?.p ? '#ffd43b' : '#f1f3f5'}`,
          }}
        >
          {isView ? (
            formatDate(item[month[0]]?.created_date)
          ) : (
            <DatePickerMui
              label=""
              sx={{
                width: '150px',
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
              minDate={moment(new Date(`${month[1]}/01/${month[2]}`))}
              maxDate={moment(
                new Date(
                  `${month[1]}/${new Date(+month[2], +month[1], 0).getDate()}/${
                    month[2]
                  }`
                )
              )}
              value={moment(item[month[0]]?.created_date || null)}
              onChange={(date: any) => {
                handleDateClick(date, month[0], index);
              }}
            />
          )}
        </td>
      ))}
    </>
  );
};

export default TBodyRightPDate;
