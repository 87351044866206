import React, { useState } from 'react';
import { Box, Checkbox, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import StateCustomTable from '../../../components/common/StateCustomTable';
import {
  CustomPagination,
  StatePagination,
  TableCustom,
} from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';

const tableHeaderObj = [
  [
    { text: 'S.NO.', rowspan: 1, colspan: 1 },
    { text: 'TOOL DESC.', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [{ value: 'createdAt', name: 'Most Recent' }];

const ToolItemList = ({
  toolIds,
  handleToolUpdate,
}: {
  handleToolUpdate?: any;
  toolIds?: string[];
}) => {
  const [page, setPage] = useState(1);
  const [partData, setPartData] = useState<any[]>([]);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<string>('createdAt');

  const fetchPartData = () => {
    axios
      .get(
        `/api/masterListOfToolRoute/toolItemCode/?page=${page}&sortBy=${filter}&search=${search}&perPage=${10}`,
      )
      .then((res) => {
        setPerPage(res.data?.perPage);
        setPartData(res.data?.result);
        setTotalPages(res.data?.pages);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchPartData, 300, [page, filter, search]);
  return (
    <Box width={600}>
      <StateCustomTable
        filterOptions={filterOptions}
        {...{ search, setSearch, filter, setFilter }}
        sx={{ width: '100%', maxWidth: '100%' }}
        label="Search"
      >
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {partData &&
              partData.map((item: any, index: number) => (
                <React.Fragment key={item?._id}>
                  <TableRow>
                    <TableCell align="center">
                      {perPage * (+page - 1) + index + 1}
                    </TableCell>
                    <TableCell align="center">{item?.tool_desc}</TableCell>
                    <TableCell align="center">
                      {toolIds && handleToolUpdate && (
                        <Checkbox
                          checked={toolIds.includes(item._id || '')}
                          onChange={() => handleToolUpdate(item._id)}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
          '{' '}
        </TableCustom>

        <StatePagination page={page} totalPage={totalPages} setPage={setPage} />
      </StateCustomTable>
    </Box>
  );
};

export default ToolItemList;
