import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import useConfirm from '../../components/common/useConfirm';
import AutocompleteGridmui from '../../components/common/autocomplete/AutocompleteGridmui';
import SelectGridMui from '../../components/common/selectscustom/SelectGridMui';
import { MenuItem } from '@mui/material';
import DateGridMui from '../../components/common/dateCustom/DateGridMui';
import moment from 'moment';

const Create = ({
  id,
  setIsModal,
  setListId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setListId: any;
}) => {
  const [data, setData] = useState<any>({ status: 'ACTIVE' });
  const [toolItemData, setToolItemData] = useState([]);
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Document' : 'Create Document'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`,
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/masterListOfToolRoute/${id}`)
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  useEffect(() => {
    axios
      .get(
        '/api/masterListOfToolRoute/toolItemCodeList/toolItemCodeForAutoComplete',
      )
      .then((res) => {
        if (res.data) {
          setToolItemData(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  function handleData(e: React.ChangeEvent<HTMLInputElement>) {
    setData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/masterListOfToolRoute/${id}`, data)
        .then((res) => {
          setListId(null);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/masterListOfToolRoute', data)
        .then((res) => {
          setIsModal(false);
          setListId(null);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      <AutocompleteGridmui
        label={'Tool Item Code:'}
        id="tool_item_id"
        option_name={'name'}
        value={data?.tool_item_id || ''}
        arrayofObj={toolItemData}
        onChange={(e, value) => {
          data.tool_item_id = value;
          setRender((prev) => !prev);
        }}
      />
      <GridInputs
        id={'tool_no'}
        name={'tool_no'}
        html_for={'tool_no'}
        label_name={'TOOL NO.:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.tool_no || ''}
        onChange={handleData}
      />

      <GridInputs
        id={'tool_desc'}
        name={'tool_desc'}
        html_for={'tool_desc'}
        label_name={'TOOL DESCRIPTION:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.tool_desc || ''}
        onChange={handleData}
      />

      <DateGridMui
        lable="Admission Date:"
        onChange={(date: any) => {
          data.admission_date = date;
        }}
        value={data?.admission_date ? moment(data?.admission_date) : null}
      />

      <SelectGridMui
        name="status"
        value={data?.status || ''}
        lable={'Status:'}
        onChange={(e: any) => handleData(e)}
      >
        <MenuItem value="Available">Available</MenuItem>
        <MenuItem value="Unavailable">Unavailable</MenuItem>
      </SelectGridMui>
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
