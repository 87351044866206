import { ReactNode } from 'react';
import { Table } from './styled';
import TableScroll from './TableScroll';
import Box from '@mui/material/Box';
interface IProps {
  children?: ReactNode;
  scroll?: String;
}
const RenderTable = ({ children, scroll, ...rest }: IProps) => {
  return (
    <TableScroll scroll={scroll} {...rest}>
      <Table id={'tableDownloadId'} cellSpacing={0}>
        {children}
      </Table>
    </TableScroll>
  );
};

export default RenderTable;
