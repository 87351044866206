import { HiDocumentReport } from 'react-icons/hi';
import { TbReportAnalytics } from 'react-icons/tb';
import { TbReport } from 'react-icons/tb';
// import { RiDeleteBin4Fill } from 'react-icons/ri';
import { TbFileReport } from 'react-icons/tb';

const tiles = [
  {
    sNo: 1,
    link: '/common/rotorSection',
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#a12222" />,
    p: 'ROTOR',
    span: 'Section',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/pressShop',
    isLink: true,
    icon: <TbReportAnalytics size="5rem" color="#a12222" />,
    p: 'Press',
    span: 'Shop',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/pdc',
    isLink: true,
    icon: <TbReport size="5rem" color="#a12222" />,
    p: 'PDC',
    span: 'Section',
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/common/cnc',
    isLink: true,
    icon: <TbFileReport size="5rem" color="#a12222" />,
    p: 'CNC',
    span: 'Section',
    isIcon: true,
  },
];

export default tiles;
