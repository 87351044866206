import { Box, Checkbox, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { TableCustom } from '../../../../../components/common';
const tableHeaderObj = [
  [
    { text: 'S.NO.', rowspan: 1, colspan: 1 },
    { text: 'NAME', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];

const ToolView = ({
  toolIds,
  toolCategoryIds,
}: {
  toolIds: any;
  toolCategoryIds: any;
}) => {
  const [tools, setTools] = useState([]);
  const [render, setRender] = useState(false);
  useEffect(() => {
    if (toolCategoryIds.length) {
      const id = toolCategoryIds.map((category: any) => category._id).join('-');
      axios
        .get(`/api/masterListOfToolRoute/getToolsByIdItemCodeId/${id}`)
        .then((res) => {
          if (res.data) {
            setTools(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [toolCategoryIds]);
  const handleChange = (id: string) => {
    const toolIndex = toolIds.indexOf(id); // checking spare part id exists or not , if not exist then push if not then there is only one option that is to remove id
    if (toolIndex === -1) {
      toolIds.push(id);
    } else {
      toolIds.splice(toolIndex, 1);
    }
    setRender((prev) => !prev);
  };
  return (
    <Box width={600}>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {tools.map((item: any, index: number) => (
            <React.Fragment key={item?._id}>
              <TableRow>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">{item?.tool_no}</TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={toolIds?.includes(item._id || '')}
                    onChange={() => handleChange(item._id)}
                  />
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
        '{' '}
      </TableCustom>
    </Box>
  );
};
export default ToolView;
