import { useState } from 'react';
import { TableCompHead } from '../../../components/ui';
interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={20}
        rowSpan={4}
        labels={[
          'FORMAT NO: ',
          'ISSUE DATE: ',
          'REVISION NO: ',
          'REVISION DATE: ',
        ]}
        values={['F QAD 23', '01/04/2017', '04', '16/10/2023']}
        heading="CUSTOMER complaint REPORT"
      />
      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'S.NO.',
          'DATE',
          'TIME',
          'STAGE',
          'MACHINE NO.',
          'PRODUCTION ORDER NO.',
          'ITEM CODE',
          'SUSPECTED QTY',
          'NATURE OF NC',
          'RED BIN ANALYSIS & ACTION PLAN',
          'DISPOSITION TYPE',
          'QA SIGN',
          'PRD SIGN',
          'REMARKS',
        ].map((head, index) => (
          <th
            style={{ textTransform: 'uppercase' }}
            rowSpan={index === 9 || index === 10 ? 1 : 2}
            colSpan={
              (index === 9 && 3) ||
              (index === 10 && 4) ||
              (index === 13 && 2) ||
              1
            }
          >
            {head}
          </th>
        ))}
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'CONTAINMENT ACTION',
          'ROOT CAUSE',
          'Action Taken',
          'OK QTY',
          'SCRAP QTY',
          'REWORKED QTY',
          'ACCEPTED UD QTY',
        ].map((head, index) => (
          <th style={{ textTransform: 'uppercase' }}>{head}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
