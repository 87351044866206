import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { createConstant } from '../../constant';
import TBodyRight from './TBodyRight';
import formatDate from '../../../../components/common/formatDate';
import { Grid } from '@mui/material';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../../components/common/button/CustomIconButton';

interface IProps {
  moduleData: any;
  allmonth: any;
  isView: boolean;
  setIsOpenPopup: any;
  setPartIdx: any;
  setRowName: any;
}
const TBody = ({
  moduleData,
  isView,
  allmonth,
  setIsOpenPopup,
  setPartIdx,
  setRowName,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: number) => (
        <>
          <tr key={`${index}_table_row`}>
            <td rowSpan={2}>{index + 1}</td>
            <td rowSpan={2}>
              {isView ? (
                item?.a1 && item?.a1?.part_name
              ) : (
                //+ ' & ' + item.a1?.part_number
                <Grid container xs={12} direction="row" alignItems={'center'}>
                  <Grid item xs={10}>
                    <p
                      style={{
                        fontSize: '1.2rem',
                      }}
                    >
                      {typeof item?.a1 === 'object' && item?.a1 !== null
                        ? item?.a1?.part_name //+ ' & ' + item.a1?.part_number
                        : (item?.a1_partNumber && item?.a1_partName) || //+ ' & ' + item?.a1_partNumber)
                          ''}
                      {!item?.a1 && 'Pick Part Name'}
                    </p>
                  </Grid>

                  {item.a1 ? (
                    <Grid item xs={2}>
                      <RemoveIconButton
                        tooltipTitle="remove part"
                        onClick={() => {
                          item.a1 = null;
                          item.a1_partNumber = null;
                          item.a1_partName = null;
                          setRender((prev) => !prev);
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={2}>
                      <AddIconButton
                        tooltipTitle="add part"
                        onClick={() => {
                          setIsOpenPopup(true);
                          setRowName('a1');
                          setPartIdx(index);
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </td>
            {/* <td rowSpan={2}>
              {isView ? (
                item?.a2 && item?.a2?.part_name + ' & ' + item.a2?.part_number
              ) : (
                <Grid container xs={12} direction="row" alignItems={'center'}>
                  <Grid item xs={10}>
                    <p
                      style={{
                        fontSize: '1.2rem',
                      }}
                    >
                      {typeof item?.a2 === 'object' && item?.a2 !== null
                        ? item?.a2?.part_name + ' & ' + item.a2?.part_number
                        : (item?.a2_partNumber &&
                            item?.a2_partName + ' & ' + item?.a2_partNumber) ||
                          ''}
                      {!item?.a2 && 'Pick Part No.'}
                    </p>
                  </Grid>

                  {item.a2 ? (
                    <Grid item xs={2}>
                      <RemoveIconButton
                        tooltipTitle="remove part"
                        onClick={() => {
                          item.a2 = null;
                          item.a2_partNumber = null;
                          item.a2_partName = null;
                          setRender((prev) => !prev);
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={2}>
                      <AddIconButton
                        tooltipTitle="add part"
                        onClick={() => {
                          setIsOpenPopup(true);
                          setRowName('a2');
                          setPartIdx(index);
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </td> */}
            {/* <td rowSpan={2}>
              {isView ? (
                item?.a3 && item?.a3?.part_name + ' & ' + item.a3?.part_number
              ) : (
                <Grid container xs={12} direction="row" alignItems={'center'}>
                  <Grid item xs={10}>
                    <p
                      style={{
                        fontSize: '1.2rem',
                      }}
                    >
                      {typeof item?.a3 === 'object' && item?.a3 !== null
                        ? item?.a3?.part_name + ' & ' + item.a3?.part_number
                        : (item?.a3_partNumber &&
                            item?.a3_partName + ' & ' + item?.a3_partNumber) ||
                          ''}
                      {!item?.a3 && 'Pick Part No.'}
                    </p>
                  </Grid>

                  {item.a3 ? (
                    <Grid item xs={2}>
                      <RemoveIconButton
                        tooltipTitle="remove part"
                        onClick={() => {
                          item.a3 = null;
                          item.a3_partNumber = null;
                          item.a3_partName = null;
                          setRender((prev) => !prev);
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={2}>
                      <AddIconButton
                        tooltipTitle="add part"
                        onClick={() => {
                          setIsOpenPopup(true);
                          setRowName('a3');
                          setPartIdx(index);
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </td> */}
            <td rowSpan={2}>
              {item?.a1_partNumber
                ? item?.a1_partNumber
                : item?.a1?.part_number}
            </td>
            <td rowSpan={2}>
              <TableInput
                isView={isView}
                style={{ height: '80px' }}
                name="critical_dimension"
                type="text"
                value={item.critical_dimension}
                onChange={(e) => {
                  item.critical_dimension = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td>P</td>
            {/* always constant ⬇️*/}
            <TBodyRight
              item={item}
              isView={isView}
              index={index}
              allmonth={allmonth}
            />
            <td rowSpan={2} colSpan={2}>
              <TableInput
                isView={isView}
                style={{ height: '80px' }}
                name="remarks"
                type="text"
                value={item.remarks}
                onChange={(e) => {
                  item.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
          <tr>
            <td>A</td>
            {allmonth.map((month: any, mIdx: number) => (
              <td
                width="80px"
                height="45px"
                style={{
                  backgroundColor: item[month]?.doneDate && '#37b24d',
                  color: 'white',
                  textTransform: 'capitalize',
                  // fontSize: '2.5rem',
                }}
              >
                {/* {item[month]?.isTask && 'A'} */}
                {formatDate(item[month].doneDate) || null}
              </td>
            ))}
          </tr>
          {/* ---x---- */}
        </>
      ))}
    </tbody>
  );
};

export default TBody;
