import { useEffect, useRef, useState } from 'react';
import { Grid, MenuItem } from '@mui/material';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import moment from 'moment';
import { TimePicker } from '@mui/x-date-pickers';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import { calMinute, convToTime } from '../../../../../utils/helpers';
import Operator from '../Operator';
import ToolView from './ToolView';
import ModalCustom from '../../../../../components/common/Modal';
import CustomSelect from '../../../../../components/common/SelectField';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { isNaN } from 'lodash';
import PartStrokeRate from '../PartStrokeRate';
import { useQuery } from '../../../../../hooks/UseQuery';
import axios from 'axios';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setPartIdx: any;
}
const calculateTotalRunTime = (process: any): number | null => {
  if (!(process.shift_id?.start_time && process.shift_id?.end_time))
    return null;
  const differenceInMinutes = calMinute(
    process.shift_id?.start_time,
    process.shift_id?.end_time,
  );

  if (!differenceInMinutes) return null;
  // Calculate total run time
  const totalRunTime =
    differenceInMinutes -
    ((+process.down_time || 0) + (+process.tpm_time || 0));
  return totalRunTime;
};
const fmtString = (arr: any = []) => {
  return arr.map((tool: any, index: number) => {
    if (index === arr.length - 1) return tool.tool_desc;
    return `${tool.tool_desc},`;
  });
};
const producedQtyToFloor = (process: any) => {
  const producedQty =
    (+process.ok_without_chips || 0) /
    (+process.weight_pc || +process.part_id?.weight_pc || 0);
  if (isNaN(producedQty)) return null;
  return Math.floor(producedQty);
};

function calculateTotalPlannedDowntime(details: any) {
  if (!details) return;
  return details
    .filter((item: any) => item.task !== 'Working' && item.task)
    .reduce((total: any, item: any) => {
      const differenceInMinutes = calMinute(item.start_time, item.end_time);
      return total + differenceInMinutes || 0;
    }, 0);
}

const TBody = ({
  moduleData,
  isView,
  handelDeleteRows,
  setIsOpenPopup,
  setPartIdx,
}: IProps) => {
  const [render, setRender] = useState(false);
  const [isToolOpenPopup, setIsToolOpenPopup] = useState(false);
  const [shiftData, setShiftData] = useState([]);
  const toolIds = useRef([]);
  const toolCategoryIds = useRef([]);
  const date = useQuery('date');

  // Parse the date to get the month and year
  const parsedDate = moment(date);
  const month = parsedDate.month(); // months are 0-based in moment.js
  const year = parsedDate.year();

  const startOfMonth = moment({ year, month, day: 1 });
  const endOfMonth = moment(startOfMonth).endOf('month');

  useEffect(() => {
    axios
      .get(`/api/shiftSchedulingRoute/autoComplete/data`)
      .then((res) => {
        if (res.data) {
          setShiftData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <tr key={index + 'module data'}>
              <td width={'100px'} style={{ fontWeight: 'bold' }}>
                {index + 1}
              </td>
              <td style={{ minWidth: '170px' }}>
                {isView ? (
                  formatDate(process.dailyProduction_date)
                ) : (
                  <DatePickerMui
                    label=""
                    value={moment(process.dailyProduction_date || null)}
                    minDate={startOfMonth}
                    maxDate={endOfMonth}
                    onChange={(date) => {
                      process.dailyProduction_date = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>
              <td style={{ minWidth: '150px' }}>
                <AutocompleteMuiCustom
                  id="shift"
                  label=""
                  option_name="shift"
                  arrayofObj={shiftData || []}
                  value={process?.shift_id || ''}
                  onChange={(e, value) => {
                    process.shift_id = value;
                    setRender((prev: any) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                {convToTime(process.shift_id?.start_time)}
              </td>
              <td style={{ minWidth: '100px' }}>
                {convToTime(process.shift_id?.end_time)}
              </td>
              <td style={{ minWidth: '150px' }}>
                {calculateTotalPlannedDowntime(process.shift_id?.details)}
              </td>
              <td style={{ minWidth: '150px' }}>
                <TableInput
                  isView={isView}
                  name="unschedule_down_time"
                  type="number"
                  value={process.unschedule_down_time}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '150px' }}>
                <TableInput
                  isView={isView}
                  name="prod_order_no"
                  type="text"
                  value={process.prod_order_no}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ width: '250px', minWidth: '250px' }}>
                {isView ? (
                  process?.part_id && process?.part_id?.item_no
                ) : (
                  <Grid container xs={12} direction="row" alignItems={'center'}>
                    <Grid item xs={10}>
                      <p
                        style={{
                          fontSize: '1.6rem',
                        }}
                      >
                        {typeof process?.part_id === 'object' &&
                        process?.part_id !== null
                          ? process?.part_id?.item_no
                          : process?.partNumber || ''}
                        {!process?.part_id && 'Pick Part Number'}
                      </p>
                    </Grid>

                    {process.part_id ? (
                      <Grid item xs={2}>
                        <RemoveIconButton
                          tooltipTitle="remove part"
                          onClick={() => {
                            process.part_id = null;
                            process.partNumber = null;
                            process.partName = null;
                            process.tool_item_ids = [];
                            process.tool_ids = [];
                            process.weight_pc = null;
                            setRender((prev) => !prev);
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={2}>
                        <AddIconButton
                          tooltipTitle="add part"
                          onClick={() => {
                            setIsOpenPopup(true);
                            setPartIdx(index);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </td>
              <td style={{ minWidth: '200px' }}>
                {fmtString(
                  process?.tool_item_ids || process?.part_id?.tool_item_ids,
                )}
                {/* <AddIconButton */}
                {/*   tooltipTitle="add tool" */}
                {/*   onClick={() => { */}
                {/*     if (!process.tool_ids) { */}
                {/*       process.tool_ids = []; */}
                {/*     } */}
                {/*     toolIds.current = process.tool_ids; */}
                {/*     toolCategoryIds.current = */}
                {/*       process.tool_item_ids || */}
                {/*       process.part_id?.tool_item_ids || */}
                {/*       []; */}
                {/*     setIsToolOpenPopup(true); */}
                {/*   }} */}
                {/* /> */}
              </td>
              <td style={{ minWidth: '200px' }}>
                {moduleData.machine_id && (
                  <Operator
                    field="operator"
                    machineId={moduleData.machine_id?._id}
                    subDepartmentId={moduleData.machine_id?.sub_department_id}
                    dataObj={{
                      date: moduleData.dailyProduction_date,
                      part_id: moduleData.part_id,
                      operation_name: process.operation_name,
                    }}
                    {...{ isView, process }}
                  />
                )}
              </td>

              <td>
                {moduleData.machine_id && (
                  <PartStrokeRate
                    machineId={moduleData.machine_id?._id}
                    partId={process.part_id?._id || process.part_id}
                    {...{ isView, process, setRender }}
                  />
                )}
              </td>
              <td style={{ minWidth: '100px' }}></td>
              <td>{calculateTotalRunTime(process)}</td>
              <td style={{ minWidth: '100px' }}>
                {producedQtyToFloor(process)}
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="ok_without_chips"
                  type="number"
                  value={process.ok_without_chips}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="chips_qty"
                  type="number"
                  value={process.chips_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="sus_total_qty"
                  type="number"
                  value={process.sus_total_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="sus_ok_qty"
                  type="number"
                  value={process.sus_ok_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="sus_chips_qty"
                  type="number"
                  value={process.sus_chips_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="sus_rejected_qty"
                  type="number"
                  value={process.sus_rejected_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="scrap_wire"
                  type="number"
                  value={process.scrap_wire}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="scrap_rivet"
                  type="number"
                  value={process.scrap_rivet}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '300px' }}></td>
              <td style={{ minWidth: '200px' }} colSpan={isView ? 2 : 1}>
                <TableInput
                  isView={isView}
                  name="remarks"
                  type="text"
                  value={process.remarks}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                {!isView && (
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                )}
              </td>
            </tr>
          ))}
      </tbody>
      <ModalCustom
        title="TOOL LIST"
        openModal={isToolOpenPopup}
        closeModal={() => {
          setIsToolOpenPopup(false);
        }}
      >
        <ToolView
          toolIds={toolIds.current}
          toolCategoryIds={toolCategoryIds.current}
        />
      </ModalCustom>
    </>
  );
};

export default TBody;
