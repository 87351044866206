import { useState } from 'react';
import { TableInput } from '../../../../../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const TFoot = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tfoot>
      <tr>
        <th align="left" colSpan={3}>
          Prepared By:
        </th>
        <th align="left" colSpan={4}>
          Approved By:
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={3}>
          Sign:
          <TableInput
            isView={isView}
            name="sign1"
            type="text"
            value={moduleData.sign1}
            onChange={(e) => {
              moduleData.sign1 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th align="left" colSpan={4}>
          Sign:
          <TableInput
            isView={isView}
            name="sign2"
            type="text"
            value={moduleData.sign2}
            onChange={(e) => {
              moduleData.sign2 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={3}>
          Name:
          <TableInput
            isView={isView}
            name="name1"
            type="text"
            value={moduleData.name1}
            onChange={(e) => {
              moduleData.name1 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th align="left" colSpan={4}>
          Name:
          <TableInput
            isView={isView}
            name="name2"
            type="text"
            value={moduleData.name2}
            onChange={(e) => {
              moduleData.name2 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={3}>
          Designation:
          <TableInput
            isView={isView}
            name="designation1"
            type="text"
            value={moduleData.designation1}
            onChange={(e) => {
              moduleData.designation1 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th align="left" colSpan={4}>
          Designation:
          <TableInput
            isView={isView}
            name="designation2"
            type="text"
            value={moduleData.designation2}
            onChange={(e) => {
              moduleData.designation2 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
