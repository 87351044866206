import React, { useRef, useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import ChildHeader from '../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../components/common/FilterBy';
import { SearchBar } from '../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../components/common';
import useDebounce from '../../hooks/useDebounce';
import { ViewIconButton } from '../../components/common/button/CustomIconButton';
import { useQuery } from '../../hooks/UseQuery';
import formatDate from '../../components/common/formatDate';
import { useNavigate } from 'react-router-dom';

const tableHeaderObj = [
  [
    { text: 'S.NO.', rowspan: 1, colspan: 1 },
    { text: 'ITEM NUMBER', rowspan: 1, colspan: 1 },
    { text: 'ITEM DESCRIPTION', rowspan: 1, colspan: 1 },
    { text: 'CREATED AT', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];
const filterOptions = [{ value: 'createdAt', name: 'A-Z' }];

const ItemMasterList = () => {
  const [data, setData] = useState<any[]>([]);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
  const search = useQuery('search') || '';
  const sort = useQuery('sort');
  const page = useQuery('page') || 1;

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/itemMasterRoute?page=${page}&sortBy=${sort}&search=${search}&perPage=${10}`,
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 500, [page, search, sort]);

  return (
    <>
      <ChildHeader text="ITEM MASTER"></ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <Stack direction={'row'} gap={2} flex="1">
          <FilterBy
            sx={{ width: 200 }}
            name="sort"
            label="Sort"
            options={filterOptions}
          />
        </Stack>
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item.item_no}</TableCell>
                  <TableCell align="center">{item.item_description}</TableCell>
                  <TableCell align="center">
                    {formatDate(item?.createdAt || '')}
                  </TableCell>
                  <TableCell align="center">
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <ViewIconButton
                        tooltipTitle="view machine"
                        onClick={() => {
                          navigate(`/common/partStrokeRateList/${item._id}`);
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
    </>
  );
};

export default ItemMasterList;
