import { DivStyled } from '../../../assets/styles/components/DivStyledLableInputs';
import { TimePicker } from '@mui/x-date-pickers';

interface Iprop {
  value: string | null | object;
  lable: string;
  onChange: any;
}
const TimeGridMui = (prop: Iprop) => {
  const { value, lable, onChange } = prop;

  return (
    <DivStyled>
      <label>{lable}</label>
      <div
        style={{
          borderLeft: '2px solid #ced4da',
          paddingLeft: '0.5rem',
          width: '100%',
        }}
      >
        <TimePicker onChange={onChange} value={value} />
      </div>
    </DivStyled>
  );
};

export default TimeGridMui;
