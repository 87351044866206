import { AiOutlineCloudUpload } from 'react-icons/ai';
import {
  MdComputer,
  MdFeaturedPlayList,
  MdSupervisorAccount,
} from 'react-icons/md';
import { TbNotebook } from 'react-icons/tb';

const tiles = [
  // {
  //   sNo: 1,
  //   link: '/common/clauseUploadList?documentType=masterProcedureClause8&heading=MASTER PROCEDURE',
  //   isLink: true,
  //   clauseNo: 8,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: 'MASTER',
  //   span: 'PROCEDURE',
  //   isIcon: true,
  // },
  // {
  //   sNo: 2,
  //   link: '/common/clauseUploadList?documentType=checkingRecordOfPartsClause8&heading=CHECKING RECORD OF PARTS',
  //   isLink: true,
  //   clauseNo: 8,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: 'CHECKING RECORD',
  //   span: 'OF PARTS',
  //   isIcon: true,
  // },
  // {
  //   sNo: 3,
  //   link: '/common/clauseUploadList?documentType=customerComplaintDefectClause8&heading=CUSTOMER COMPLAINT & DEFECT ANALYSIS SHEET',
  //   isLink: true,
  //   clauseNo: 8,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: 'CUSTOMER COMPLAINT',
  //   span: '& DEFECT ANALYSIS SHEET',
  //   isIcon: true,
  // },
  // {
  //   sNo: 4,
  //   link: '/common/clauseUploadList?documentType=evidenceOfOnsiteClause8&heading=EVIDENCE OF ONSITE VERIFICATION',
  //   isLink: true,
  //   clauseNo: 8,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: 'EVIDENCE OF ONSITE',
  //   span: 'VERIFICATION',
  //   isIcon: true,
  // },
  // {
  //   sNo: 5,
  //   link: '/common/clauseUploadList?documentType=horiontalDeploymentClause8&heading=HORIZONTAL DEPLOYMENT OF COUNTERMEASURES',
  //   isLink: true,
  //   clauseNo: 8,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: 'HORIZONTAL DEPLOYMENT OF',
  //   span: 'COUNTERMEASURES',
  //   isIcon: true,
  // },
  // {
  //   sNo: 6,
  //   link: '/common/clauseUploadList?documentType=definitionOfAbnormalClause8&heading=DEFINITION OF ABNORMAL SITUATIONS',
  //   isLink: true,
  //   clauseNo: 8,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: 'DEFINITION OF ABNORMAL',
  //   span: 'SITUATIONS',
  //   isIcon: true,
  // },
  // {
  //   sNo: 7,
  //   link: '/common/clauseUploadList?documentType=displayOfAbnormalClause8&heading=DISPLAY OF ABNORMAL SITUATIONS IN SHOP FLOOR',
  //   isLink: true,
  //   clauseNo: 8,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: 'DISPLAY OF ABNORMAL SITUATIONS',
  //   span: 'IN SHOP FLOOR',
  //   isIcon: true,
  // },
  // {
  //   sNo: 8,
  //   link: '/common/clauseUploadList?documentType=awarenessOfAbnormalClause8&heading=AWARENESS OF ABNORMAL SITUATIONS',
  //   isLink: true,
  //   clauseNo: 8,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: 'AWARENESS OF ABNORMAL',
  //   span: 'SITUATIONS',
  //   isIcon: true,
  // },

  {
    sNo: 9,
    link: 'https://uc.secure.aqua.mushinlabs.com',
    target: '_blank',
    isLink: true,
    clauseNo: 3,
    icon: <MdComputer size="5rem" color="#a12222" />,
    p: 'CUSTOMER COMPLAINT',
    span: 'TRACKER',
    isIcon: true,
  },
  {
    sNo: 10,
    link: '/common/abnormalityLogBookList',
    isLink: true,
    icon: <TbNotebook size="5rem" color="#a12222" />,
    p: 'ABNORMALITY',
    span: 'LOG BOOK',
    isIcon: true,
  },
  // {
  //   sNo: 10,
  //   link: 'https://uc.secure.aqua.mushinlabs.com',
  //   isLink: true,
  //   clauseNo: 3,
  //   icon: <MdSupervisorAccount size="5rem" color="#a12222" />,
  //   p: 'ABNORMALITY REQUEST',
  //   span: 'FORM',
  //   isIcon: true,
  // },
  // {
  //   sNo: 11,
  //   link: 'https://uc.secure.aqua.mushinlabs.com',
  //   isLink: true,
  //   clauseNo: 3,
  //   icon: <MdFeaturedPlayList size="5rem" color="#135089" />,
  //   p: 'ABNORMALITY  MONITERNING',
  //   span: 'REPORTS',
  //   isIcon: true,
  // },
];

export default tiles;
