import React, { useState } from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import useDebounce from '../../../../../hooks/useDebounce';
import { TableCustom, StatePagination } from '../../../../../components/common';
import formatDate from '../../../../../components/common/formatDate';
import { CustomIconButton } from '../../../../../components/common/button/CustomIconButton';
import { useNavigate } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import StateCustomTable from '../../../../../components/common/StateCustomTable';

interface IPart {
  _id?: string;
  item_no: string | null;
  item_description: string | null;
  createdAt?: Date;
  partName: any;
  partNumber: any;
  machine_ids?: any[];
  tool_item_ids?: any[];
  weight_pc: string | null;
  strokes: string | null;
}
const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Item Number', rowspan: 1, colspan: 1 },
    { text: 'Item Description', rowspan: 1, colspan: 1 },
    { text: 'Created At', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [{ value: 'item_no', name: 'A-Z' }];

const PartTable = (props: any) => {
  const { moduleData } = props;
  const [page, setPage] = useState(1);
  const [partData, setPartData] = useState<IPart[]>([]);
  const [perPage, setPerPage] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<string>('part_number');
  const navigate = useNavigate();
  const [render, setRender] = useState(false);
  const fetchPartData = () => {
    axios
      .get(
        `/api/itemMasterRoute?page=${page}&sortBy=${filter}&search=${search}&perPage=${10}`,
      )
      .then((res) => {
        setPerPage(res.data?.perPage);
        setPartData(res.data?.results);
        setTotalPages(res.data?.pages);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchPartData, 300, [page, filter, search]);

  return (
    <StateCustomTable
      filterOptions={filterOptions}
      {...{ search, setSearch, filter, setFilter }}
      label="Search Part"
    >
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {partData &&
            partData.map((item: IPart, index: number) => (
              <React.Fragment key={item?._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{item.item_no}</TableCell>
                  <TableCell align="center">{item.item_description}</TableCell>
                  <TableCell align="center">
                    {formatDate(item?.createdAt || '')}
                  </TableCell>
                  <TableCell align="center">
                    {moduleData.part_id === item._id ? (
                      <CustomIconButton
                        sx={{ p: 0 }}
                        tooltipTitle={'remove'}
                        onClick={() => {
                          moduleData.part_id = null;
                          moduleData.partName = null;
                          moduleData.partNumber = null;
                          moduleData.tool_item_ids = [];
                          moduleData.weight_pc = null;
                          moduleData.strokes = null;
                          setRender((prev) => !prev);
                        }}
                      >
                        <RemoveIcon style={{ color: '#c92a2a' }} />
                      </CustomIconButton>
                    ) : (
                      <CustomIconButton
                        sx={{ p: 0 }}
                        tooltipTitle={'add'}
                        onClick={() => {
                          moduleData.part_id = item?._id;
                          moduleData.partName = item?.item_description;
                          moduleData.partNumber = item?.item_no;
                          moduleData.tool_item_ids = item.tool_item_ids;
                          moduleData.weight_pc = item.weight_pc;
                          moduleData.strokes = item.strokes;
                          setRender((prev) => !prev);
                        }}
                      >
                        <Add style={{ color: '#343a40' }} />
                      </CustomIconButton>
                    )}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <StatePagination page={page} totalPage={totalPages} setPage={setPage} />
    </StateCustomTable>
  );
};

export default PartTable;
